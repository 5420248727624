import he from 'he';
import {t} from 'ttag';
import PropTypes from "prop-types";
import React, {useState} from "react";
import {Box, CircularProgress, Paper, Typography} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close'
import Autocomplete from "@mui/material/Autocomplete";
import OpenWindowIcon from "../../../Icons/OpenWindowIcon";
import {TextInput} from "../../../index";
import {filterAirports, sortAirports} from "./airportsTools";
import useStyles from "./AirportSelectorStyles";

const AirportSelector = ({
                             options,
                             label,
                             placeholder,
                             variant = 'azo',
                             leftRadius = false,
                             rightRadius = false,
                             onChange,
                             disabled = false,
                             value,
                             autoFocus = false,
                             onFocus,
                             onBlur,
                             inputRef,
                             noOptionsText,
                             optionField = 'name',
                             compressed = false,
                             hideIcon = false,
                             loading,
                             flat = false,
                             error = false,
                             noBorder = false,
                             ...props
                         }) => {

    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const handleOnFocus = e => {
        if (onFocus) onFocus(e);
    };

    const handleBlur = e => {
        if (onBlur) onBlur(e);
    }

    const filterOptions = (options, {inputValue}) => {
        return sortAirports(options, inputValue).filter(airport => filterAirports(airport, inputValue));
    };

    return (
        <Box className={'azo-airport-selector'}>
            <Autocomplete
                aria-label={label}
                aria-labelledby={label ? `${label}-label` : undefined}
                aria-invalid={error ? "true" : "false"}
                PaperComponent={params => (
                    <Box className={'azo-airport-list-popup'}>
                        <Paper square elevation={1} {...params}/>
                    </Box>
                )}
                popupIcon={!hideIcon ? (loading ? (<CircularProgress size={20}/>) : (open ?
                    <CloseIcon color={'secondary'}/> : <OpenWindowIcon color={'secondary'}/>)) : null}
                options={sortAirports(options)}
                getOptionLabel={option => he.decode(option[optionField])}
                renderInput={params => (
                    <TextInput
                        {...params}
                        variant={variant}
                        inputRef={inputRef}
                        autoFocus={autoFocus}
                        placeholder={placeholder}
                        label={!flat ? label : ''}
                        shrink={!compressed}
                        flat={flat}
                        error={error}
                        ariaDisabled={disabled}
                        sx={{border: noBorder ? 'none !important' : 'inherit'}}
                        inputProps={{
                            ...params.inputProps,
                            'aria-controls': params.id
                        }}
                    />
                )}
                renderOption={(props, option) => {
                    return (
                        <Box {...props}
                             key={props.key}
                             sx={{
                            display: 'flex',
                            flex: 1,
                            justifyContent: 'space-between',
                            fontFamily: 'OpenSansMedium',
                        }}

                             component="li">
                            <Box sx={{flex: 1, display: 'flex', justifyContent: 'space-between',}}>
                                <Typography color={'primary'}>{option.name}</Typography> <Typography variant='subtitle1'
                                                                                                     className={classes.listItemCode}
                                                                                                     component='h6'>{option.code}</Typography>
                            </Box>
                        </Box>
                    )
                }}
                disableClearable
                selectOnFocus
                autoHighlight
                onFocus={handleOnFocus}
                onBlur={handleBlur}
                onChange={(e, v) => onChange(v)}
                disabled={disabled}
                value={value || null}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                noOptionsText={noOptionsText ? noOptionsText : t`No airports found...`}
                openOnFocus
                filterOptions={filterOptions}
                classes={variant === 'azo' ? {
                    paper: classes.paper,
                    inputRoot: classes.inputRoot,
                    input: [classes.input, flat ? classes.inputFlatted : ''].join(' '),
                    popupIndicator: classes.popupIndicator,
                    option: classes.option,
                } : {}}
                loading={loading}
                {...props}
            />
        </Box>
    );
};

AirportSelector.airportShape = PropTypes.shape({
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    cityName: PropTypes.string,
    countryCode: PropTypes.string,
    countryName: PropTypes.string,
    regionName: PropTypes.string,
    azores: PropTypes.bool,
    destinations: PropTypes.arrayOf(PropTypes.string),
});

AirportSelector.propTypes = {
    options: PropTypes.arrayOf(AirportSelector.airportShape).isRequired,
    variant: PropTypes.oneOf(['standard', 'outlined', 'filled', 'azo']),
    label: PropTypes.string,
    placeholder: PropTypes.string,
    leftRadius: PropTypes.bool,
    rightRadius: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([
        AirportSelector.airportShape,
        PropTypes.oneOf([null])
    ]),
    autoFocus: PropTypes.bool,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    inputRef: PropTypes.any,
    noOptionsText: PropTypes.string,
    optionField: PropTypes.string,
    compressed: PropTypes.bool,
    hideIcon: PropTypes.bool,
    flat: PropTypes.bool,
    error: PropTypes.bool,
    noBorder: PropTypes.bool,
};

export default AirportSelector;