import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from "react-redux";
import {setSwState} from "../../../../store/reducers/search-widget";
import {TabBarCollapsible} from "../../../index";
import {t} from 'ttag';
import {SW_TABS} from "../../../../store/types";

const TabBarCollapsibleConnected = ({children = null}) => {

    const selectedTab = useSelector(state => state.sw.selectedTab);
    const dispatch = useDispatch();

    return (
        <TabBarCollapsible
            tabs={[
                {label: t`BOOK`, value: SW_TABS.BOOK, children: children, hiddenWhenSelected: true},
                {label: t`CHECK-IN`, value: SW_TABS.CHECK_IN, children: children, hiddenWhenSelected: false},
                {label: t`FLIGHT STATUS`, value: SW_TABS.FLIGHT_STATUS, children: children, hiddenWhenSelected: false},
                {label: t`MY TRIP`, value: SW_TABS.MY_TRIP, children: children, hiddenWhenSelected: false},
            ]}
            selected={selectedTab}
            onSelect={tabValue => dispatch(setSwState({selectedTab: selectedTab !== tabValue ? tabValue : 'BOOK'}))}
            unmountOnExit
        />
    );
};

TabBarCollapsibleConnected.propTypes = {
    children: PropTypes.any,
}
export default TabBarCollapsibleConnected;