import {makeStyles} from '@mui/styles';

const useStyles = makeStyles(theme => ({
    title: {
        textAlign: 'left',
    },
    label: {
        display: 'flex',
        justifyContent: 'space-between',
    },
}));

export default useStyles;
