import React from 'react';
import PropTypes from 'prop-types';
import moment from "moment";
import styles from './Years.module.scss';
import Year from "./Year/Year";
import {Box} from "@mui/material";

function Years({
                   pivot = moment(),
                   start = null,
                   end = null,
                   children = null,
                   disabled = false,
                   pastDisabled = false,
                   availableRange = null,
                   selectYearHandler = () => null,
                   inlineStyle = {}
               }) {

    const classes = disabled ? [styles.container, styles.disabled].join(' ') : styles.container;

    const today = moment();
    const year = pivot.format('YYYY');
    const decadeBeginning = year - (year % 10) - 1;
    const decadeEnd = decadeBeginning + 11;
    const firstYear = moment(`${decadeBeginning}-01-01`);
    const lastYear = moment(`${decadeEnd}-12-31`);

    const unit = 'year';
    const years = [];
    for (let y = firstYear.clone(); y.isSameOrBefore(lastYear); y.add(1, unit)) {
        const current = y.clone();
        const isOutOfAvailableRange = availableRange
            ? current.isBefore(availableRange.start, unit) || current.isAfter(availableRange.end, unit)
            : false;
        const isSelected = current.isSameOrAfter(start, unit) && current.isSameOrBefore(end, unit);
        const isDisabled = disabled || isOutOfAvailableRange || (current.isBefore(today, unit) && pastDisabled);
        const isShy = false;//current.isSame(firstYear, unit) || current.isSame(lastYear, unit);
        years.push(
            <Year key={current.format('YYYY')}
                  clickHandler={() => selectYearHandler(current.clone())}
                  shy={isShy}
                  disabled={isDisabled}
                  selected={isSelected}>
                {current.format('YYYY')}
            </Year>
        );
    }

    return (
        <Box className={classes} style={inlineStyle}>
            <Box className={styles.yearsContainer}>
                {years}
                {children}
            </Box>
        </Box>
    );
}

Years.propTypes = {
    inlineStyle: PropTypes.object,
    selectYearHandler: PropTypes.func,
    children: PropTypes.any,
    disabled: PropTypes.bool,
    pastDisabled: PropTypes.bool,
    pivot: PropTypes.instanceOf(moment),
    start: PropTypes.instanceOf(moment),
    end: PropTypes.instanceOf(moment),
    availableRange: PropTypes.shape({
        start: PropTypes.instanceOf(moment),
        end: PropTypes.instanceOf(moment)
    }),
};
export default Years;
