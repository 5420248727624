import {makeStyles} from "@mui/styles";
import {alpha} from "@mui/material";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'white',
        borderStyle: 'solid',
        // opacity: ({disabled}) => disabled ? '.4' : '1',
        borderColor: ({error, disabled}) => alpha(error ? theme.palette.error.main : theme.palette.secondary.main, disabled ? .4 : 1),
        borderWidth: ({infiniteTop, infiniteRight, infiniteBottom, infiniteLeft, borderWidth}) => {
            if (borderWidth !== null) return borderWidth;
            let top = '1px', right = '1px', bottom = '1px', left = '1px';
            if (infiniteTop) top = '0';
            if (infiniteRight) right = '0';
            if (infiniteBottom) bottom = '0';
            if (infiniteLeft) left = '0';
            return `${top} ${right} ${bottom} ${left}`;
        },
        borderRadius: ({infiniteTop, infiniteRight, infiniteBottom, infiniteLeft, borderRadius}) => {
            if (borderRadius !== null) return borderRadius;
            let tl = '5px', tr = '5px', br = '5px', bl = '5px';
            if (infiniteTop) {
                tl = '0';
                tr = '0';
            }
            if (infiniteRight) {
                tr = '0';
                br = '0';
            }
            if (infiniteBottom) {
                br = '0';
                bl = '0';
            }
            if (infiniteLeft) {
                tl = '0';
                bl = '0';
            }
            return `${tl} ${tr} ${br} ${bl}`;
        },
    },
}));

export default useStyles;