import {useSelector} from 'react-redux';
import {SW_FLIGHT_TYPES, SW_PASSENGER_TYPES} from "../../../../../store/types";

const useIsStopoverEligibleConnected = () => {


    const origin = useSelector(state => state.sw.book.origin);
    const destination = useSelector(state => state.sw.book.destination);
    const passengerType = useSelector(state => state.sw.book.passengerType);
    const promoCode = useSelector(state => state.sw.book.promoCode);
    const flightType = useSelector(state => state.sw.book.flightType);
    const withMiles = useSelector(state => state.sw.book.withMiles);
    const eligibleCityPairs = useSelector(state => state.sw.book.stopover.eligibleCityPairs);
    const isRoundTrip = flightType === SW_FLIGHT_TYPES.RETURN;
    const isOneWay = flightType === SW_FLIGHT_TYPES.ONE_WAY;
    const eligibleCityPairDefinitions = eligibleCityPairs.find(e => e?.origin === origin?.code && e?.destination === destination?.code) || null;

    return Boolean(
        (isRoundTrip || isOneWay)
        && passengerType === SW_PASSENGER_TYPES.ADULT
        && !promoCode
        && !withMiles
        && origin
        && destination
        && eligibleCityPairDefinitions
    );

};

export default useIsStopoverEligibleConnected;