import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {DatePickerInput} from "../../../../index";
import {
    retrieveStopoverAvailability,
    setSwBookState,
    setSwState
} from "../../../../../store/reducers/search-widget";
import {SW_FLIGHT_TYPES} from "../../../../../store/types";
import {ARRIVAL_REF, COMPONENTS} from "../../common/book-form-input-refs";
import moment from 'moment';
import {t} from 'ttag';

const ArrivalDatePickerConnected = () => {

    const focusedComponent = useSelector(state => state.sw.focusedComponent);
    const departureStr = useSelector(state => state.sw.book.departure);
    const arrivalStr = useSelector(state => state.sw.book.arrival);
    const flightType = useSelector(state => state.sw.book.flightType);
    const disabled = flightType === SW_FLIGHT_TYPES.ONE_WAY;
    const departure = departureStr && moment(departureStr);
    const arrival = arrivalStr && moment(arrivalStr);
    const dispatch = useDispatch();

    return (
        <DatePickerInput
            label={t`RETURN DATE`}
            placeholder={t`Return Date`}
            date={!disabled ? arrival : null}
            containerRef={ARRIVAL_REF}
            open={focusedComponent === COMPONENTS.ARRIVAL_REF}
            onFocus={() => dispatch(setSwState({focusedComponent: COMPONENTS.ARRIVAL_REF}))}
            onBlur={() => focusedComponent === COMPONENTS.ARRIVAL_REF && dispatch(setSwState({focusedComponent: COMPONENTS.NONE}))}
            onChange={({date}) => {
                dispatch(setSwBookState({arrival: date.format('YYYY-MM-DD')}));
                dispatch(setSwState({focusedComponent: COMPONENTS.NONE}));
                dispatch(retrieveStopoverAvailability());
            }}
            DatePickerProps={{
                pivot: (arrival ? arrival : (departure ? departure.clone().add(1, 'd') : undefined)),
                filterDaysToDisableHandler: d => Boolean(departure) && d.isBefore(departure.clone()),
            }}
            DropInputProps={{TextInputProps: {shrink: true}}}
            disabled={disabled}
            placement={'bottom-end'}
        />
    );
};

export default ArrivalDatePickerConnected;