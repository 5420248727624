import React from "react";
import {SvgIcon} from "@mui/material";

const CalendarIcon = props => (
    <SvgIcon width="44" height="44" viewBox="0 0 44 44" {...props}>
        <path
            d="M1309.322,677.613v30.25a4.126,4.126,0,0,1-4.125,4.125h-30.25a4.126,4.126,0,0,1-4.125-4.125v-30.25a4.126,4.126,0,0,1,4.125-4.125h4.125V669.02a1.034,1.034,0,0,1,1.032-1.032h.688a1.034,1.034,0,0,1,1.03,1.032v4.468h16.5V669.02a1.034,1.034,0,0,1,1.032-1.032h.688a1.034,1.034,0,0,1,1.03,1.032v4.468h4.125A4.126,4.126,0,0,1,1309.322,677.613Zm-35.75,0v4.125h33v-4.125a1.379,1.379,0,0,0-1.375-1.375h-30.25A1.38,1.38,0,0,0,1273.572,677.613Zm33,30.25V684.488h-33v23.375a1.38,1.38,0,0,0,1.375,1.375h30.25A1.379,1.379,0,0,0,1306.572,707.863ZM1280.1,695.488a1.034,1.034,0,0,1-1.032-1.03V691.02a1.034,1.034,0,0,1,1.032-1.032h3.438a1.034,1.034,0,0,1,1.03,1.032v3.438a1.034,1.034,0,0,1-1.03,1.03Zm0,8.25a1.034,1.034,0,0,1-1.032-1.03V699.27a1.034,1.034,0,0,1,1.032-1.032h3.438a1.034,1.034,0,0,1,1.03,1.032v3.438a1.034,1.034,0,0,1-1.03,1.03Zm8.25-8.25a1.034,1.034,0,0,1-1.032-1.03V691.02a1.034,1.034,0,0,1,1.032-1.032h3.438a1.034,1.034,0,0,1,1.03,1.032v3.438a1.034,1.034,0,0,1-1.03,1.03Zm0,8.25a1.034,1.034,0,0,1-1.032-1.03V699.27a1.034,1.034,0,0,1,1.032-1.032h3.438a1.034,1.034,0,0,1,1.03,1.032v3.438a1.034,1.034,0,0,1-1.03,1.03Zm8.25-8.25a1.034,1.034,0,0,1-1.032-1.03V691.02a1.034,1.034,0,0,1,1.032-1.032h3.438a1.034,1.034,0,0,1,1.03,1.032v3.438a1.034,1.034,0,0,1-1.03,1.03Zm0,8.25a1.034,1.034,0,0,1-1.032-1.03V699.27a1.034,1.034,0,0,1,1.032-1.032h3.438a1.034,1.034,0,0,1,1.03,1.032v3.438a1.034,1.034,0,0,1-1.03,1.03Z"
            transform="translate(-1270.822 -667.988)"
        />
    </SvgIcon>
);

export default CalendarIcon;