import React from 'react';
import PropTypes from 'prop-types';
import {Box, useMediaQuery, useTheme, Typography} from '@mui/material';
import {RadioButton} from "../../index";
import {t} from 'ttag';
import {SW_PASSENGER_TYPES} from "../../../store/types";

const PassengerTypeSelector = ({type = null, onChange = () => null, disabledTypes = []}) => {

    const {breakpoints} = useTheme();
    const isLowerThanMedium = useMediaQuery('(max-width: 790px)');
    const isBiggerThanLarge = useMediaQuery(breakpoints.up('lg'));

    return (
        <Box display={'flex'} flexDirection={isLowerThanMedium ? 'column' : 'row'}>
            <RadioButton
                label={<Typography variant={isBiggerThanLarge ? 'caption' : undefined}>{t`Regular`}</Typography>}
                ariaLabel={t`Regular`}
                checked={type === SW_PASSENGER_TYPES.ADULT}
                onChange={() => onChange(SW_PASSENGER_TYPES.ADULT)}
                disabled={disabledTypes.includes(SW_PASSENGER_TYPES.ADULT)}
            />
            <RadioButton
                label={<Typography variant={isBiggerThanLarge ? 'caption' : undefined}>{t`Resident in Azores`}</Typography>}
                ariaLabel={t`Resident in Azores`}
                checked={type === SW_PASSENGER_TYPES.RESIDENT}
                onChange={() => onChange(SW_PASSENGER_TYPES.RESIDENT)}
                disabled={disabledTypes.includes(SW_PASSENGER_TYPES.RESIDENT)}
            />
            <RadioButton
                label={<Typography variant={isBiggerThanLarge ? 'caption' : undefined}>{t`Student (12-26 years old)`}</Typography>}
                ariaLabel={t`Student (12-26 years old)`}
                checked={type === SW_PASSENGER_TYPES.STUDENT}
                onChange={() => onChange(SW_PASSENGER_TYPES.STUDENT)}
                disabled={disabledTypes.includes(SW_PASSENGER_TYPES.STUDENT)}
            />
        </Box>
    );
};

PassengerTypeSelector.PASSENGER_TYPES = SW_PASSENGER_TYPES;

PassengerTypeSelector.propTypes = {
    type: PropTypes.oneOf(Object.values(PassengerTypeSelector.PASSENGER_TYPES)),
    disabledTypes: PropTypes.arrayOf(PropTypes.oneOf(Object.values(PassengerTypeSelector.PASSENGER_TYPES))),
    onChange: PropTypes.func,
};

export default PassengerTypeSelector;