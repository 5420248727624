import React from 'react';
import PropTypes from 'prop-types';
import {MenuItem, Select, FormControl, InputLabel} from "@mui/material";
import styles from './LanguageDropdown.module.scss';
import {t} from 'ttag';

const LanguageDropdown = ({
                              label = 'Language',
                              value = null,
                              onChange = () => console.warn('function not injected'),
                              error = false,
                              isOnDark = false,
                              variant = 'standard',
                              required = false,
                          }) => {

    return (
        <FormControl fullWidth error={error} variant={variant}>
            <InputLabel>{label}</InputLabel>
            <Select
                className={isOnDark ? styles.selectLanguageOnDark : ''}
                value={value}
                onChange={(e) => onChange(e.target.value)}>
                <MenuItem value={'en'}>{t`EN`}</MenuItem>
                <MenuItem value={'pt'}>{t`PT`}</MenuItem>
                <MenuItem value={'fr'}>{t`FR`}</MenuItem>
            </Select>
        </FormControl>
    );
};

LanguageDropdown.propTypes = {
    error: PropTypes.bool,
    label: PropTypes.string,
    value: PropTypes.string,
    onSelect: PropTypes.func,
    required: PropTypes.bool,
    variant: PropTypes.string,
    isOnDark: PropTypes.bool,
};

export default LanguageDropdown;
