import React from 'react';
import PropTypes from 'prop-types';
import {Box} from "@mui/material";

const DropInputContent = ({children = null, ...props}) => {

    return (
        <Box bgcolor={'common.white'}
             border={1}
             // borderTop={0}
             borderColor={'secondary.main'}
             borderRadius={'0 0 5px 5px'}
             {...props}>
            {children}
        </Box>
    );
};

DropInputContent.propTypes = {
    children: PropTypes.any,
};
export default DropInputContent;