import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(theme => ({
    button: {
        borderRadius: '50px',
        width: '100%',
        maxHeight: '45px'
    },
    buttonLabel: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    title: {
        '@media (max-width: 425px)': {
            fontSize: '.9rem',
        }
    },
    subtitle: {
        display: 'flex',
        alignItems: 'center',
    }
}));

export default useStyles;