import React from 'react';
import PropTypes from 'prop-types';
import {Checkbox, FormControl, FormControlLabel, FormHelperText} from '@mui/material';
import useStyles from './CheckBoxStyles';

const CheckBox = ({
                      label = '',
                      ariaLabel = '',
                      labelPlacement = 'end',
                      formControlLabelValue = '',
                      checkboxValue = '',
                      checked = false,
                      disabled = false,
                      onChange = e => console.log(JSON.stringify(e.target.checked)),
                      formControlLabelProps = {},
                      error = false,
                      helperText = '',
                      ...props
                  }) => {

    const classes = useStyles();

    return (
        <FormControl disabled={disabled} error={error}>
            <FormControlLabel
                disabled={disabled}
                onChange={onChange}
                style={{marginRight: 0}}
                control={
                    <Checkbox
                        // role={'checkbox'}
                        aria-label={ariaLabel || ariaLabel.label}
                        aria-labelledby={ariaLabel || ariaLabel.label}
                        value={checkboxValue}
                        checked={checked}
                        classes={classes}
                        color={'secondary'}
                        inputProps={{
                            'aria-label': ariaLabel || ariaLabel.label,
                            'aria-labelledby': ariaLabel || ariaLabel.label,
                            'role': 'checkbox',
                        }}
                        {...props}
                    />
                }
                value={formControlLabelValue}
                label={label}
                labelPlacement={labelPlacement}
                {...formControlLabelProps}
            />
            {helperText && (
                <FormHelperText>
                    {helperText}
                </FormHelperText>
            )}
        </FormControl>
    );
};

CheckBox.propTypes = {
    label: PropTypes.node,
    ariaLabel: PropTypes.string,
    labelPlacement: PropTypes.oneOf(['bottom', 'top', 'start', 'end']),
    formControlLabelValue: PropTypes.any,
    checkboxValue: PropTypes.any,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    className: PropTypes.string,
    formControlLabelProps: PropTypes.object,
    error: PropTypes.bool,
    helperText: PropTypes.node,
    checkedIcon: PropTypes.node,
    classes: PropTypes.object,
    color: PropTypes.oneOf(['default', 'primary', 'secondary']),
    disableRipple: PropTypes.bool,
    icon: PropTypes.node,
    id: PropTypes.string,
    indeterminate: PropTypes.bool,
    indeterminateIcon: PropTypes.node,
    inputProps: PropTypes.object,
    inputRef: PropTypes.any,
    required: PropTypes.bool,
    size: PropTypes.oneOf(['medium', 'small']),
    value: PropTypes.any,
};


export default CheckBox;