import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => {
    const borderStyle = error => `1px solid ${error ? theme.palette.error.main : theme.palette.secondary.main}`;

    return {
        root: error => ({
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            border: borderStyle(error),
            borderRadius: '5px',
            backgroundColor: '#fff',
        }),
        item: {
            flexBasis: '50%',
        },
        leftMiddleItem: error => ({
            position: 'relative'
        }),
        exchangeIcon: {
            zIndex: '6',
            position: 'absolute',
            right: -1 * theme.spacing(3),
            textAlign: 'center',
            top: 14,
            backgroundColor: theme.palette.common.white,
        },
        stacked: {
            marginTop: theme.spacing(2),
        },
    };
});

export default useStyles;