import React from 'react';
import PropTypes from 'prop-types';
import {Box} from '@mui/material';
import useStyles from './GenericInputStyles';

const GenericInput = ({
                          children = null,
                          infiniteOptions = {},
                          borderRadius = null,
                          borderWidth = null,
                          disabled = false,
                          error=false
                      }) => {

    const classes = useStyles({...infiniteOptions, borderRadius, borderWidth, disabled, error});

    return (
        <Box className={classes.root} disabled={disabled}>
            {children}
        </Box>
    );
};

GenericInput.propTypes = {
    children: PropTypes.any,
    infiniteOptions: PropTypes.shape({
        infiniteTop: PropTypes.bool,
        infiniteRight: PropTypes.bool,
        infiniteBottom: PropTypes.bool,
        infiniteLeft: PropTypes.bool,
    }),
    borderRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    borderWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    disabled: PropTypes.bool,
    error: PropTypes.bool,
};


export default GenericInput;