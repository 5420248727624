import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import usePromoCodeInputValidation from "./usePromoCodeInputValidation";
import {t} from 'ttag';
import {TextInput} from "../../index";

const PromoCodeInput = ({
                            promoCode='',
                            dataValidator = {},
                            onChange = () => null,
                            needValidation = false,
                            disabled = false,
                            shrink = false,
                            maxChars = 10,
                            ...props
                        }) => {

    const [data, setData] = useState(promoCode);
    const {validator} = usePromoCodeInputValidation(dataValidator);

    useEffect(() => {
        setData(promoCode);
    }, [setData, promoCode]);

    const handleDataChange = value => {
        const newData = (value);
        setData(newData);
        onChange(newData);
    };

    return (
        <TextInput
            label={t`PROMO CODE`}
            placeholder={t`Insert Promotional Code`}
            value={data}
            onChange={({target: {value}}) => handleDataChange(value.replace(' ', '').substring(0, maxChars).toUpperCase())}
            error={needValidation && !!validator.promoCode(data, validator)}
            helperText={needValidation && validator.promoCode(data, validator)?.join(' ')}
            disabled={disabled}
            variant={'azo'}
            shrink={shrink}
            fullWidth
            {...props}
        />
    );
};

PromoCodeInput.propTypes = {
    promoCode: PropTypes.string,
    dataValidator: PropTypes.object,
    onChange: PropTypes.func,
    needValidation: PropTypes.bool,
    disabled: PropTypes.bool,
    shrink: PropTypes.bool,
    maxChars: PropTypes.number,
};

export default PromoCodeInput;