import React from 'react';
import PropTypes from 'prop-types';
import {MergedButtons} from "../../index";
import {Button, useMediaQuery, useTheme} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import withStyles from "./AddRemoveFlightButtonsStyles";

const AddRemoveFlightButtons = ({
                                    addBtnClickHandler = () => null,
                                    removeBtnClickHandler = () => null,
                                    addBtnChildren= 'Add',
                                    removeBtnChildren = 'Remove',
                                    addBtnIcon= <AddIcon/>,
                                    removeBtnIcon = <RemoveIcon/>,
                                    removeBtnDisabled= false,
                                    addBtnDisabled= false,
                                    disabled = false,
                                }) => {

    const classes = withStyles();
    const {breakpoints} = useTheme();
    const isTablet = useMediaQuery(breakpoints.down('md'));

    return (
        <MergedButtons compact={!isTablet}>
            <Button
                variant={'contained'}
                color={'secondary'}
                aria-label={'remove multi city leg'}
                startIcon={removeBtnIcon}
                disabled={removeBtnDisabled || disabled}
                onClick={removeBtnClickHandler}
            >
                {removeBtnChildren}
            </Button>
            <Button
                variant={'contained'}
                color={'secondary'}
                aria-label={'add multi city leg'}
                startIcon={addBtnIcon}
                disabled={addBtnDisabled || disabled}
                onClick={addBtnClickHandler}
                className={classes.addBtn}
            >
                {addBtnChildren}
            </Button>
        </MergedButtons>
    );
};

AddRemoveFlightButtons.propTypes = {
    addBtnClickHandler: PropTypes.func,
    addBtnChildren: PropTypes.any,
    addBtnIcon: PropTypes.node,
    addBtnDisabled: PropTypes.bool,
    removeBtnClickHandler: PropTypes.func,
    removeBtnChildren: PropTypes.any,
    removeBtnIcon: PropTypes.node,
    removeBtnDisabled: PropTypes.bool,
    disabled: PropTypes.bool,
};

export default AddRemoveFlightButtons;