import {createAction, createAsyncThunk, createReducer} from '@reduxjs/toolkit';
import SETTINGS, {DEBUG_KEY, LOCALE_KEY, MODE_KEY} from "../../config/settings";
import store from "store";
import httpClient from 'axios';
import {
    loadFlightRoutesAirports,
    loadFlightStatusAirports,
    loadFlightStatusRealTimeFlights,
    loadStopoverSlideshowImages,
    retrieveStopoverAvailability,
    retrieveStopoverEligibleCityPairs,
    setSwBookWithMiles,
    setSwState
} from "./search-widget";
import {determineCloserAirport, getCoordsPromised} from "../../config/tools";

export const setAppState = createAction('app/setAppState');
export const setAppDevToolsState = createAction('app/setAppDevToolsState');
export const init = createAsyncThunk(
    'app/init',
    async (args, {dispatch, getState}) => {
        httpClient.get(SETTINGS.app.BOOKING_CONFIG_URL)
          .then(({data: bookingConfig}) => {
            dispatch(setSwState({bookingConfig}));
          })
          .catch(e => console.error('There was an error loading TVLR configs', e))
        dispatch(loadFlightStatusAirports()).then(() => {
            const {sw: {flightStatusAirports}} = getState();
            getCoordsPromised()
                .then(({coords: {latitude, longitude}}) => {
                    const closerAirport = determineCloserAirport(latitude, longitude, flightStatusAirports);
                    dispatch(loadFlightStatusRealTimeFlights(closerAirport));
                })
                .catch(() => {
                    dispatch(loadFlightStatusRealTimeFlights());
                });
        });
        dispatch(loadFlightRoutesAirports());
        dispatch(loadStopoverSlideshowImages());
        dispatch(retrieveStopoverEligibleCityPairs()).then(() => {
            dispatch(retrieveStopoverAvailability());
        });
    }
);
export const setDevTools = createAsyncThunk(
    'app/setDevTools',
    async (devTools, {dispatch}) => {
        if (typeof devTools.debug === 'boolean') store.set(DEBUG_KEY, devTools.debug);
        if (devTools.mode) store.set(MODE_KEY, devTools.mode)
        dispatch(setAppDevToolsState(devTools));
    }
);
export const setAppLanguage = createAsyncThunk(
    'app/changeLanguage',
    async (language, {dispatch}) => {
        store.set(LOCALE_KEY, language);
        SETTINGS.i18n.useLocale(language);
        dispatch(setAppState({currentLanguage: language}));
    }
);
export const userAuthenticate = createAsyncThunk(
    'app/userAuthenticate',
    async ({username, password}, {dispatch, getState}) => {
        dispatch(setAppState({isAuthenticating: true, authenticationError: null}));

        httpClient.post(`${SETTINGS.app.API_ENDPOINT}/user/login?_format=json`, {
            name: username,
            pass: password
        })
            .then(() => {
                dispatch(setAppState({
                    userLoggedIn: true,
                    wasAuthenticatedOnApp: true,
                    needAuthentication: false,
                }));
                dispatch(setSwBookWithMiles({withMiles: true}));
            })
            .catch(({message}) => {
                dispatch(setAppState({authenticationError: message}));
            })
            .finally(() => {
                dispatch(setAppState({isAuthenticating: false}));
            });
    }
);

export const reducer = createReducer(
    {
        loading: false,
        notifications: [],
        currentPath: SETTINGS.INITIAL_PATH,
        currentLanguage: SETTINGS.i18n.LOCALE_DEFAULT,
        isFrontPage: Boolean(window.isFrontPage),
        userLoggedIn: Boolean(window.userLoggedIn),
        wasAuthenticatedOnApp: false,
        needAuthentication: false,
        isAuthenticating: false,
        authenticationError: null,
        devTools: {
            open: false,
            mode: SETTINGS.app.INITIAL_MODE,
            debug: SETTINGS.app.DEBUG,
        },
    },
    {
        [setAppState]: (state, action) => ({...state, ...action.payload}),
        [setAppDevToolsState]: (state, action) => ({...state, devTools: {...state.devTools, ...action.payload}}),
    }
);

export default reducer;
