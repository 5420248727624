import React from 'react';
import Alert from "@mui/material/Alert";
import {useDispatch, useSelector} from "react-redux";
import {setSwBookStopoverState} from "../../../../../store/reducers/search-widget";
import {Grid2, useMediaQuery, useTheme, Snackbar} from "@mui/material";

const BookStopoverAlertConnected = () => {

    const {breakpoints} = useTheme();
    const isMobile = useMediaQuery(breakpoints.down('sm'));
    const alertMessage = useSelector(state => state.sw.book.stopover.alertMessage);
    const dispatch = useDispatch();

    if (!alertMessage) return null;

    if (isMobile) {
        return (
            <Snackbar autoHideDuration={6000} anchorOrigin={{vertical: 'top', horizontal: 'right'}} onClose={() => dispatch(setSwBookStopoverState({alertMessage: null}))} open>
                <Alert onClose={() => dispatch(setSwBookStopoverState({alertMessage: null}))} {...alertMessage}/>
            </Snackbar>
        );
    }

    return (
        <Grid2 size={{xs:12}}>
            <Alert onClose={() => dispatch(setSwBookStopoverState({alertMessage: null}))} {...alertMessage}/>
        </Grid2>
    );
};

export default BookStopoverAlertConnected;