import {useLocale as setLocale, addLocale, setDefaultLang} from 'ttag';
import store from 'store';
import detectBrowserLanguage from "detect-browser-language";
import enLocale from 'date-fns/locale/en-US';
import ptLocale from 'date-fns/locale/pt-BR';
import frLocale from 'date-fns/locale/fr';
import 'moment/locale/pt';
import 'moment/locale/fr';
import moment from "moment";

export const LOCALE_KEY = 'LANG';

export const LOCALE_DETECTED = detectBrowserLanguage().substring(0, 2).toLowerCase();

export const LOCALES = {
    EN: 'en',
    PT: 'pt',
    FR: 'fr',
}

export const LOCALES_MAP = {
    en: 'en_US',
    pt: 'pt_PT',
    fr: 'fr_FR',
    'en_US': 'en',
    'pt_PT': 'pt',
    'fr_FR': 'fr',
};

export const LOCALES_TRANSLATIONS = {
    en: s => s,
    pt: s => s,
    fr: s => s,
};

export const LOCALES_DATE_FNS_MAP = {
    en: enLocale,
    pt: ptLocale,
    fr: frLocale,
};

export const LOCALE_CANDIDATE = String(window.LANG || store.get(LOCALE_KEY) || LOCALE_DETECTED).substring(0, 2).toLowerCase();

export const LOCALE_DEFAULT = Object.values(LOCALES).includes(LOCALE_CANDIDATE) ? LOCALE_CANDIDATE : LOCALES.EN;

export let t = (str, locale = LOCALE_DEFAULT) => {
    if (LOCALES_TRANSLATIONS.hasOwnProperty(locale.toLowerCase())) {
        return LOCALES_TRANSLATIONS[locale](str);
    }

    return str;
};

export const loadLocales = (locales = []) => {
    const convertToTranslatableFn = translationRaw => {
        const {translations: {"": tObj = {}} = {}} = translationRaw || {};
        return s => tObj.hasOwnProperty(s) && tObj[s].hasOwnProperty('msgstr') && tObj[s].msgstr.hasOwnProperty(0) && tObj[s].msgstr[0]
            ? tObj[s].msgstr[0]
            : s;
    };

    locales.forEach(locale => {
        const translation = require(`../../../i18n/${locale}.po.json`);
        LOCALES_TRANSLATIONS[locale] = convertToTranslatableFn(translation);
        addLocale(locale, translation);
    });
};

export const useLocale = locale => {
    setLocale(locale);
    moment.locale(locale);
};

export const i18nInit = () => {
    loadLocales(Object.values(LOCALES));
    setDefaultLang(LOCALE_DEFAULT);
};

(() => {
    i18nInit();
    useLocale(LOCALE_DEFAULT);
})();
