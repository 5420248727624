import React from 'react';
import PropTypes from 'prop-types';
import {Box, Typography, useMediaQuery, useTheme} from "@mui/material";
import {HelperIconInfo} from "../../../index";
import useStyles from "./StopoverPageTitleStyles";
import {t} from 'ttag';


const StopoverPageTitle = ({title = null, HelperIconInfoProps = {}}) => {

    const {breakpoints} = useTheme();
    const isMobile = useMediaQuery(breakpoints.down('sm'));
    const classes = useStyles(isMobile);

    return (
        <Box display={'flex'} flexDirection={'row'}>
            <Typography className={classes.title} variant={'h6'} color={'primary'} display={'inline'}>
                {title}
            </Typography>
            {!isMobile && (
                <HelperIconInfo
                    placement={'right'}
                    title={t`Add a stopover up to 7 days in the Azores to your Atlantic journey and discover the unique beauty of one of the most sustainable destinations in the world.`}
                    subtitle={t`On the way out, on the way back, on both. Your choice.`}
                    iconButtonProps={{
                        style: {opacity: '.5'}
                    }}
                    {...HelperIconInfoProps}
                />
            )}
        </Box>
    );
};

StopoverPageTitle.propTypes = {
    title: PropTypes.node,
    HelperIconInfoProps: PropTypes.shape({
        title: PropTypes.any,
        subtitle: PropTypes.any,
        heading: PropTypes.any,
        children: PropTypes.any,
        placement: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
        color: PropTypes.string,
        backgroundColor: PropTypes.string,
        icon: PropTypes.node,
        iconButtonProps: PropTypes.object,
        popperMessageProps: PropTypes.object,
        popperProps: PropTypes.object,
        arrowProps: PropTypes.object,
    }),
};


export default StopoverPageTitle;