import Loading from './Loading';
import React from "react";
import {useSelector} from "react-redux";

const LoadingConnected = () => {

    const loading = useSelector(state => state.app.loading);

    return <Loading loading={loading}/>;
};

export default LoadingConnected;