import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Box, Grid2} from '@mui/material';
import {t} from "ttag";
import {AirportSelector, TabBarUnderlined, FlightsTable,} from "../../../index";
import {SW_FLIGHT_STATUS_REAL_TIME_STATUS_TABS} from "../../../../store/types";
import CircularProgress from "@mui/material/CircularProgress";

const RealTimeFlightStatus = ({
                                  airports = [],
                                  selectedAirport = null,
                                  selectedTab = SW_FLIGHT_STATUS_REAL_TIME_STATUS_TABS.ARRIVALS,
                                  onAirportSelect = () => null,
                                  onTabSelect = () => null,
                                  flights = [],
                                  loadRealTimeFlightsFn = () => null,
                                  loading = false,
                              }) => {
    const [showAllFlights, setShowAllFlights] = useState(false);

    useEffect(() => {
        if (loadRealTimeFlightsFn) loadRealTimeFlightsFn();
        // eslint-disable-next-line
    }, [selectedAirport]);

    return (
        <Box className={'sw-flight-status-real-time-data-container'} id={'sw-flight-status-real-time-data-container'}
             borderRadius={'6px'} bgcolor={'#fff'}>
            <Grid2 spacing={1} container>
                <Grid2 size={{xs: 12}} onDoubleClick={() => setShowAllFlights(prevState => !prevState)}>
                    <Box color={'primary.main'} fontSize={'h6.fontSize'} fontFamily={'OpenSansMedium'}>
                        {t`Real Time Flights From:`}
                    </Box>
                </Grid2>
                <Grid2 size={{xs: 12}}>
                    <AirportSelector
                        label={t`Select airport`}
                        placeholder={t`Select or type an airport name...`}
                        options={airports}
                        onChange={onAirportSelect}
                        value={selectedAirport}
                        loading={loading}
                        hideIcon
                        flat
                    />
                </Grid2>
                {!loading && (
                    <Grid2 size={{xs: 12}}>
                        <TabBarUnderlined
                            tabs={[
                                {
                                    label: t`ARRIVALS`,
                                    value: SW_FLIGHT_STATUS_REAL_TIME_STATUS_TABS.ARRIVALS,
                                },
                                {
                                    label: t`DEPARTURES`,
                                    value: SW_FLIGHT_STATUS_REAL_TIME_STATUS_TABS.DEPARTURES,
                                },
                            ]}
                            selected={selectedTab}
                            onSelect={(e, v) => onTabSelect(v)}
                        />
                    </Grid2>
                )}
                {!loading && (
                    <Grid2 size={{xs: 12}}>
                        <FlightsTable
                            flights={flights}
                            columnTitles={{
                                datetime: t`Hour`,
                                flightNumber: t`Flight`,
                                airport: selectedTab === SW_FLIGHT_STATUS_REAL_TIME_STATUS_TABS.ARRIVALS ? t`Origin` : t`Destination`,
                                status: t`Status`,
                            }}
                            t={s => {
                                const statuses = {
                                    'On schedule': t`On schedule`,
                                    'Ahead of schedule': t`Ahead of schedule`,
                                    'Departed': t`Departed`,
                                    'Arrived': t`Arrived`,
                                    'Delayed': t`Delayed`,
                                    'Diverted': t`Diverted`,
                                    'Cancelled': t`Cancelled`,
                                };
                                return statuses.hasOwnProperty(s) ? statuses[s] : s;
                            }}
                            limitFlights={showAllFlights ? false : 5}
                        />
                    </Grid2>
                )}
                {loading && (
                    <Grid2 size={{xs: 12}}>
                        <Box display={'flex'} borderRadius={'6px'} border={'1px solid #f0f0f0'}
                             justifyContent={'center'} alignItems={'center'} minHeight={'280px'}>
                            <CircularProgress color={'secondary'}/>
                        </Box>
                    </Grid2>
                )}
            </Grid2>
        </Box>
    );
};

RealTimeFlightStatus.propTypes = {
    airports: PropTypes.array,
    selectedTab: PropTypes.oneOf(Object.values(SW_FLIGHT_STATUS_REAL_TIME_STATUS_TABS)),
    selectedAirport: PropTypes.object,
    onAirportSelect: PropTypes.func,
    onTabSelect: PropTypes.func,
    flights: PropTypes.array,
    loadRealTimeFlightsFn: PropTypes.func,
    loading: PropTypes.bool,
};
export default RealTimeFlightStatus;