import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        borderRadius: '5px',
        padding: '1rem',
        backgroundColor: 'white',
    },
    title: {
        fontFamily: 'OpenSansBold',
        color: theme.palette.primary.main,
        textAlign: isMobile => isMobile ? 'center' : '',
    },
    subtitle: {
        color: theme.palette.primary.main,
        textAlign: isMobile => isMobile ? 'center' : '',
    },
    btnGrid: isMobile => (isMobile ? {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    } : {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    }),
}));

export default useStyles;