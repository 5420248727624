import {makeStyles} from "@mui/styles";

const withStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
    },
    tabsFlexContainer: {
        borderBottom: `2px solid ${theme.palette.text.disabled}`,
    },
    root: {
        backgroundColor: theme.palette.primary.contrastText,
        minHeight: 'auto',
        minWidth: '100%',
    },
    tabWrapper: {
        flexDirection: 'row',
    },
    tabRoot: {
        fontFamily: 'OpenSansRegular',
        minHeight: 'auto',
        fontSize: '.83rem',
        minWidth: 'auto',
        color: theme.palette.text.primary,
        '&:hover': {
            fontWeight: 'bold',
            color: theme.palette.secondary.dark,
        }
    },
    tabRootSelected: {
        color: theme.palette.primary.main,
    },
    menuIcon: {
        position: 'absolute',
        right: '16px',
        cursor: 'pointer',
    },
    menuPaper: {
        backgroundColor: theme.palette.secondary.main,
    },
    menuItem: {
        color: 'white',
    },
}));

export default withStyles;