import {t} from 'ttag';

export const toNull = c => !c ? null : c;

const useWebCheckInFormValidation = (validatorOverride = {}) => {

    const validator = {
        pnr: ({pnr: s}) => toNull(!(s && /^([a-zA-Z0-9]{6})$/g.test(s))) && [t`Invalid booking code`],
        lastName: ({lastName: s}) => toNull(!(s && /^.+$/g.test(s))) && [t`Invalid last name`],
        conditions: ({conditions}) => toNull(conditions !== true) && [t`You need to accept conditions`],
        //ticketNumber: ({ticketNumber: s, isAGroup}) => toNull(!(!isAGroup || (isAGroup && s && /^([0-9]{13})$/g.test(s)))) && [t`Invalid ticket number`],
        ...validatorOverride
    };

    const isFormDataValid = (formData = {}, formDataValidator = {}) => {
        const dataValidator = {...validator, ...formDataValidator};
        return Object.entries(dataValidator).every(([validatorName, validator]) => {
            return typeof validator === 'function' ? !validator(formData, dataValidator) : !validator;
        });
    };

    return {validator, isFormDataValid};
};

export default useWebCheckInFormValidation;