import PropTypes from 'prop-types';
import {Backdrop, CircularProgress} from "@mui/material";
import React from "react";
import styles from './Loading.module.scss';

const Loading = ({
                     loading = true,
                     addEndListener = () => null,
                     backdropClickHandler = () => null,
                     overrideClasses = [],
                     transitionDuration = 100,
                     timeout = 100,
                     icon = <CircularProgress color="inherit"/>
                 }) => {

    if (!loading) return null;

    const containerClasses = [styles.container].concat(overrideClasses);

    return (
        <Backdrop
            addEndListener={addEndListener}
            onClick={backdropClickHandler}
            transitionDuration={transitionDuration}
            timeout={timeout}
            open={loading}
            className={containerClasses.join(' ')}>
            {icon}
        </Backdrop>
    );
};

Loading.propTypes = {
    loading: PropTypes.bool,
    addEndListener: PropTypes.func,
    backdropClickHandler: PropTypes.func,
    overrideClasses: PropTypes.array,
    transitionDuration: PropTypes.number,
    timeout: PropTypes.number,
    icon: PropTypes.any,
};


export default Loading;
