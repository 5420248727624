import React, {useState} from 'react';
import PropTypes from 'prop-types';
import styles from './ErrorFallback.module.scss';
import {Alert, AlertTitle} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import RefreshIcon from '@mui/icons-material/Refresh';

const ErrorFallback = ({
                           error: {
                               message = 'An error occurred.'
                           },
                           title = 'Error',
                           componentStack = '-',
                           resetErrorBoundary = () => null,
                           withComponentStack = false
                       }) => {

    const [reloadCount, setReloadCount] = useState(0);

    const localResetErrorBoundary = () => {
        if (reloadCount > 0) {
            window.location.reload(true);
        }

        setReloadCount(reloadCount + 1);
        resetErrorBoundary();
    }

    return (
        <div className={styles.container}>
            <div role='alert' className={styles.subContainer}>
                <Alert
                    severity='error'
                    className={styles.alert}
                    action={<IconButton onClick={localResetErrorBoundary}
                                        aria-label='reload'><RefreshIcon/></IconButton>}
                >
                    <AlertTitle>{title}</AlertTitle>
                    {message}
                </Alert>
                {withComponentStack && <pre>{componentStack}</pre>}
            </div>
        </div>
    );
};

ErrorFallback.propTypes = {
    componentStack: PropTypes.any,
    resetErrorBoundary: PropTypes.func,
    withComponentStack: PropTypes.bool,
    title: PropTypes.string,
    error: PropTypes.shape({
        message: PropTypes.string,
    }),
};


export default ErrorFallback;