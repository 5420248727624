import {configureStore} from '@reduxjs/toolkit';
import app from "./reducers/app";
import sw from "./reducers/search-widget";


const store = configureStore({
    reducer: {
        app,
        sw,
    }
});

export default store;
