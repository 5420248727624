import {makeStyles} from "@mui/styles";

const withStyles = makeStyles(theme => ({
    colorPrimary: {
        color: theme.palette.primary.main
    },
    colorSecondary: {
        color: theme.palette.secondary.main
    }
}));

export default withStyles;