import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setSwBookWithMiles} from "../../../../../store/reducers/search-widget";
import {CheckBox, HelperIconInfo} from "../../../../index";
import {t} from 'ttag';
import {SW_FLIGHT_TYPES, SW_PASSENGER_TYPES} from "../../../../../store/types";
import Box from "@mui/material/Box";
import {Typography, useMediaQuery, useTheme} from "@mui/material";

const BookWithMilesCheckboxConnected = () => {

    const withMiles = useSelector(state => state.sw.book.withMiles);
    const bookingConfig = useSelector(state => state.sw.bookingConfig);
    const passengerType = useSelector(state => state.sw.book.passengerType);
    const flightType = useSelector(state => state.sw.book.flightType);
    const promoCode = useSelector(state => state.sw.book.promoCode);
    const origin = useSelector(state => state.sw.book.origin);
    const destination = useSelector(state => state.sw.book.destination);
    const withCashNMiles = useSelector(state => state.sw.book.withCashNMiles);
    const dispatch = useDispatch();
    const {applicableDestinationsAward = {}} = bookingConfig?.flightSearch?.initialData || {};

    const {breakpoints} = useTheme();
    const isBiggerThanLarge = useMediaQuery(breakpoints.up('lg'));

    const canBookWithMiles = !promoCode && !withCashNMiles
        && [SW_FLIGHT_TYPES.ONE_WAY, SW_FLIGHT_TYPES.RETURN].includes(flightType)
        && passengerType === SW_PASSENGER_TYPES.ADULT
        && ((!origin && !destination) || (applicableDestinationsAward[origin?.code]?.includes(destination?.code)));

    return (
        <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
            <CheckBox
                label={<Typography variant={isBiggerThanLarge ? 'caption' : undefined}>{t`Book with miles`}</Typography>}
                checked={Boolean(withMiles)}
                onChange={e => dispatch(setSwBookWithMiles({withMiles: e.target.checked}))}
                disabled={Boolean(!canBookWithMiles)}
            />
            <HelperIconInfo
                title={t`Book with SATA IMAGINE miles`}
                subtitle={t`Only available on flights operated by SATA | Azores Airlines.`}
                placement={'left'}
            />
        </Box>

    );
};

export default BookWithMilesCheckboxConnected;