export const TEMPORARY_PNR_BLOCK = [
  "222RER",
  "223YB3",
  "226MQA",
  "234YZF",
  "23QKEC",
  "25FEVL",
  "25X4P9",
  "26CI2M",
  "26ZNW3",
  "27ICRU",
  "2CW6VO",
  "2EMGYF",
  "2EPPM6",
  "2FGXT6",
  "2GLR5K",
  "2I6VDK",
  "2L6DPX",
  "2LUNYW",
  "2Q5ERO",
  "2WL3H6",
  "2X8FDM",
  "2Y73WE",
  "2YJOZV",
  "2ZA9UA",
  "2ZYILG",
  "32LI2A",
  "32MTTX",
  "358HJB",
  "35PMVE",
  "36LB7M",
  "36REBR",
  "37TR3O",
  "37YLKG",
  "382V6P",
  "38MFLA",
  "3BSIMG",
  "3C2328",
  "3D5U2X",
  "3D6W59",
  "3EGDG4",
  "3GHZ8L",
  "3GI26H",
  "3JRY3D",
  "3L7DMK",
  "3MWSZH",
  "3MYBUG",
  "3N7MX8",
  "3NJDB8",
  "3NJOEJ",
  "3NQIM6",
  "3NZSX4",
  "3PAEDK",
  "3QA72D",
  "3QGCFB",
  "3R8N8B",
  "3RESYD",
  "3T6SC9",
  "3UC5IM",
  "3V59WE",
  "3XU4DO",
  "3YF2I9",
  "3YRRCN",
  "3YS27Y",
  "3YWF9C",
  "42HSH3",
  "42O8SH",
  "42QQX5",
  "42ZOON",
  "43CA9F",
  "44HBGL",
  "44KZR9",
  "45HXWM",
  "46935D",
  "469VP5",
  "479D8E",
  "47KF3M",
  "4A9HE4",
  "4BNWGS",
  "4CKSDM",
  "4CLVRD",
  "4EWJ6R",
  "4FCFBE",
  "4FHBOU",
  "4HXMI2",
  "4I4MVI",
  "4KGBWA",
  "4LZUUU",
  "4MRCZH",
  "4NKXRL",
  "4NXGS7",
  "4PRX7R",
  "4PS26I",
  "4Q8M5D",
  "4QNFW9",
  "4R73BH",
  "4RFHWX",
  "4T45RD",
  "4T5OBO",
  "4TVUEY",
  "4TXBQ8",
  "4TXLIG",
  "4V64GN",
  "4ZOFUM",
  "6TVBU6",
  "J2OD47",
  "J35GKP",
  "J526LV",
  "J5JC29",
  "J6E2O2",
  "J78JY5",
  "J7DLFX",
  "J8OJXN",
  "J9MWWI",
  "J9O98W",
  "J9YK7N",
  "JA5U9D",
  "JBSNEV",
  "JBUOPB",
  "JC5R4N",
  "JC8UYN",
  "JCH5XC",
  "JCJ2PS",
  "JCLEVG",
  "JD6988",
  "JD89WI",
  "JDHIXZ",
  "JE9GCC",
  "JFD4AQ",
  "JFDNQS",
  "JFLU6X",
  "JFPQYL",
  "JFRWDK",
  "JH7VMM",
  "JHDZO3",
  "JHPUVH",
  "JI5CEC",
  "JID86D",
  "JIPD9C",
  "JIWTSO",
  "JIXOTH",
  "JKFTKB",
  "JKK2QD",
  "JKPGCB",
  "JL46VZ",
  "JL7IQ9",
  "JLCHTU",
  "JLGTXS",
  "JM3P9L",
  "JMXYGX",
  "JN6Y4U",
  "JNE8UG",
  "JNTETP",
  "JOXO9J",
  "JOYH7X",
  "JPIH59",
  "JPYA8Y",
  "JQ9J6I",
  "JQMZV9",
  "JRDU6U",
  "JRPVXD",
  "JRTGCZ",
  "JS8ZHE",
  "JSKXJS",
  "JSQQ4C",
  "JTVPY3",
  "JU66F3",
  "JU9P5C",
  "JUJGPQ",
  "JUSNOP",
  "JV3P4O",
  "JVLR9L",
  "JVZPFT",
  "JW4X4L",
  "JX8KZW",
  "JXWHXT",
  "JYIBI5",
  "JYSZRJ",
  "JYZ3C4",
  "JZS25Z",
  "K23UX2",
  "K25FC8",
  "K3529D",
  "K3J28A",
  "K3SDK7",
  "K3UASA",
  "K5OOUS",
  "K6PVUS",
  "K6WB8V",
  "K726HO",
  "K7L8U2",
  "K7YWFR",
  "K9S7FZ",
  "KAKGBC",
  "KAMWSM",
  "KAWKMU",
  "KD79Z9",
  "KDNJIM",
  "KEYO4M",
  "KGOPZT",
  "KHECPZ",
  "KHZXWQ",
  "KIIMN7",
  "KLABDG",
  "KLF98Z",
  "KLMFMQ",
  "KLOF9G",
  "KM98PF",
  "KMI5AK",
  "KMWPLA",
  "KNLRUX",
  "KO7HVR",
  "KODHR4",
  "KOGVJS",
  "KP6MY5",
  "KPTKA6",
  "KPV6G3",
  "KQ5P8L",
  "KQ6FLN",
  "KQGMEA",
  "KQMR8P",
  "KQWFEF",
  "KQYGZH",
  "KR3WSA",
  "KRFX7O",
  "KRIMWZ",
  "KRJSQ6",
  "KS7QJU",
  "KSCQUB",
  "KSNBV7",
  "KT6CW2",
  "KT9CVA",
  "KTL3DX",
  "KUHYFP",
  "KURKVE",
  "KV8QLH",
  "KVWF3V",
  "KWU5ZF",
  "KX74XA",
  "KXMU78",
  "L25U4V",
  "L26DMO",
  "L2GBP5",
  "L2KIBD",
  "L2NRSD",
  "L3MXC5",
  "L3SH6Z",
  "L4JA9Y",
  "L4VOET",
  "L4WUNX",
  "L6AN6E",
  "L7BXQU",
  "L7DNCZ",
  "L95RDT",
  "L9Q575",
  "LAU6XH",
  "LBYJAI",
  "LCIZXL",
  "LD35Q4",
  "LDG8FT",
  "LDM5BD",
  "LDVEZE",
  "LE2XYB",
  "LFFPKC",
  "LHC6U6",
  "LHIJYI",
  "LI9WT4",
  "LICL22",
  "LIODC6",
  "LIWGM9",
  "LJ2CLY",
  "LJABUX",
  "LJOC8Y",
  "LK4UH4",
  "LKFAUL",
  "LL6COQ",
  "LLWMTC",
  "LLZEY7",
  "LMTMDD",
  "LMWH9D",
  "LOOBEW",
  "LPVCGI",
  "LQ2CE8",
  "LQKWEK",
  "LQRSA2",
  "LS9IKE",
  "LSPAX7",
  "LT9IVF",
  "LTCK2U",
  "LW3JF6",
  "LW6IDN",
  "LWY6KD",
  "LXEP9B",
  "LXIAFY",
  "LXIGYF",
  "LXYKOI",
  "LYN238",
  "LZTUN4",
  "M244G4",
  "M29JI3",
  "M2D6HN",
  "M2DQBF",
  "M2U9MY",
  "M3OKGE",
  "M43JGU",
  "M536D6",
  "M9995H",
  "M999H5",
  "M9L5VY",
  "MAJZAN",
  "MAZXX3",
  "MBEKS3",
  "MCDXLN",
  "MDH8ZW",
  "MDIFG6",
  "MDYA5A",
  "MERS4Q",
  "MEU3MX",
  "MFV54Z",
  "MFW2UC",
  "MGCVPE",
  "MGJ6JC",
  "MH3BIY",
  "MHT3HY",
  "MKNZLH",
  "MKQCX5",
  "MKZFC4",
  "MM79G3",
  "MN28L9",
  "MND3JW",
  "MNPWMV",
  "MNRNPF",
  "MO6UGQ",
  "MQ9HYW",
  "MQBZQZ",
  "MRAK3O",
  "MSNIBA",
  "MUC4S8",
  "MVW9XE",
  "MW8R8P",
  "MWSWEL",
  "MXBTHZ",
  "MY5535",
  "MYTEDC",
  "MYUOQU",
  "N27FBO",
  "N4JF6B",
  "N4KCDF",
  "N6PHSY",
  "N6YYXQ",
  "N75ZA7",
  "N78CSG",
  "N867DK",
  "N8QBAM",
  "N9MMOC",
  "N9TRY8",
  "N9XJIA",
  "NATO9P",
  "NB7ZM9",
  "ND83LL",
  "ND8Z9W",
  "NDSFMY",
  "NDWDN8",
  "NDYV9N",
  "NE5MHP",
  "NEHN3R",
  "NFR4YB",
  "NGB4PC",
  "NGURSL",
  "NH3PPI",
  "NKWI2H",
  "NMHMNG",
  "NMKEZB",
  "NNG5NT",
  "NNMOX5",
  "NPQ84I",
  "NQ2TS5",
  "NQ5V8Z",
  "NQ9EYT",
  "NQEY4E",
  "NR2U8P",
  "NR8BRJ",
  "NRUHMC",
  "NS8MJH",
  "NVBZ4M",
  "NVDPES",
  "NW7RR4",
  "NW8G2E",
  "NWTGZT",
  "NYFESH",
  "O566ML",
  "O5GWOG",
  "O5J6RF",
  "O5LLFM",
  "O5ZLC2",
  "O6M5S7",
  "O8H26Q",
  "O9AW5N",
  "OA2Z5S",
  "OCDJX3",
  "OD6939",
  "ODJNO4",
  "ODX79Y",
  "OE6T6X",
  "OEC2MF",
  "OELRTY",
  "OFAP6L",
  "OHVWPB",
  "OHZY6Z",
  "OIAX4H",
  "OIDPKB",
  "OITMSO",
  "OK3M7E",
  "OKEB6O",
  "OKZLQA",
  "ON4ISQ",
  "ON825I",
  "ONYQB3",
  "OO6CQQ",
  "OP6FIX",
  "OPPHRW",
  "OPQ4LA",
  "OQ58WF",
  "ORYG4R",
  "OT23UM",
  "OU79DD",
  "OU7Z52",
  "OUDO2W",
  "OURF5T",
  "OV7O59",
  "OVQVJJ",
  "OW2T9Y",
  "OWB22U",
  "OXG364",
  "OYEPXP",
  "OZDWJ2",
  "P22JTX",
  "P2ELVT",
  "P3UIXG",
  "P58S5J",
  "P5MITV",
  "P5QXWZ",
  "P5WDR6",
  "P6AC7D",
  "P6FPAE",
  "P6P6WS",
  "P6TFLT",
  "P7DOLD",
  "P7UTTR",
  "P88LW6",
  "P983T3",
  "PATMHV",
  "PBWCRT",
  "PBZJKD",
  "PCVFSH",
  "PD4I3Y",
  "PDQCX5",
  "PE3TAG",
  "PFKNNK",
  "PFYBFQ",
  "PGS27M",
  "PIJPJN",
  "PJ7XQG",
  "PJL6WJ",
  "PK2IQV",
  "PKMYB9",
  "PKXTEP",
  "PL7EKI",
  "PLFW5W",
  "PLR59P",
  "PMUE2E",
  "PNDTUP",
  "PODJZ9",
  "PP73WO",
  "PPSGGI",
  "PR296O",
  "PRMC2K",
  "PS23VP",
  "PSN923",
  "PTNKKD",
  "PTU8S9",
  "PUADNB",
  "PUQ5TV",
  "PUQVHY",
  "PUW5W7",
  "PW3A6H",
  "PW8BKN",
  "PW8SHP",
  "PXBV3R",
  "PXC3Y9",
  "PXE8BG",
  "PXJ6VV",
  "PXLRGT",
  "PYJUV5",
  "Q29MS2",
  "Q2DSZB",
  "Q2R252",
  "Q3VQU3",
  "Q483DG",
  "Q4VSS4",
  "Q4Z427",
  "Q4ZEVB",
  "Q9IB2G",
  "QAWMZK",
  "QB92R6",
  "QBT47K",
  "QD3GV3",
  "QDBXR3",
  "QE5JYN",
  "QFF8DW",
  "QFG56F",
  "QG4VQC",
  "QG9OK8",
  "QHDTZC",
  "QHMJOK",
  "QHWMI6",
  "QI43FE",
  "QIS4MG",
  "QIVQ3C",
  "QJ2DGM",
  "QKV4A5",
  "QKXZ47",
  "QL4KMB",
  "QLKXCQ",
  "QLX8TR",
  "QMJSW9",
  "QMZLXB",
  "QOAN65",
  "QPAWGF",
  "QPWYX2",
  "QQGL2E",
  "QRJWRL",
  "QSGSVY",
  "QVYTP3",
  "QW5J4R",
  "QWN5B8",
  "QWSMB2",
  "QWXLJG",
  "QX58IH",
  "QY4J4W",
  "QY83PV",
  "QYJAND",
  "QZEU29",
  "QZFR7C",
  "QZQ8JA",
  "QZYA5F",
  "R27M3V",
  "R2LQTP",
  "R32X2D",
  "R3KJ9T",
  "R3TRFK",
  "R485DX",
  "R4UFNV",
  "R552EK",
  "R56HM2",
  "R5XTB3",
  "R697XC",
  "R699N9",
  "R6KSTY",
  "R7CMLI",
  "R7ERR5",
  "R7GA7Q",
  "R8G4O2",
  "R9D89H",
  "RADNS9",
  "RBD2ZL",
  "RC9Q46",
  "RCCIVB",
  "RCJOSW",
  "RCKLH5",
  "RCVAAJ",
  "RE6RYL",
  "REUEQH",
  "RFWYOC",
  "RFZPT6",
  "RGY4N3",
  "RHMDXZ",
  "RHSA9W",
  "RJMYK8",
  "RKIQLU",
  "RKVVGV",
  "RL2P58",
  "RL8BLC",
  "RLF48B",
  "RM7FZ9",
  "RMO3SN",
  "RO2B4D",
  "ROQIXF",
  "ROZXEL",
  "RQWFHJ",
  "RR95N3",
  "RS7OXJ",
  "RTIB36",
  "RTXYQX",
  "RU75ZX",
  "RU968Z",
  "RYHUDL",
  "S28OHT",
  "S2C8W4",
  "S2T4Z2",
  "S2TKBE",
  "S397IZ",
  "S3BQ4D",
  "S6W4PQ",
  "S7MSKO",
  "S9HY6Q",
  "SB3CRQ",
  "SCA5A5",
  "SCXO8J",
  "SD4RUM",
  "SERK8J",
  "SFFF7U",
  "SFVCUN",
  "SG3N56",
  "SGJICO",
  "SHL4GD",
  "SHMIJF",
  "SHNXR6",
  "SJL57U",
  "SJXWLZ",
  "SK68AO",
  "SKIAHX",
  "SKJDHS",
  "SKTI8A",
  "SN6ZW4",
  "SNMP2L",
  "SOXDD5",
  "SPJMT5",
  "SQ4P28",
  "SQ8KNC",
  "SQ8LG6",
  "SR4P7E",
  "SS4MPX",
  "SSGP4N",
  "SSNBQC",
  "SUQ9TB",
  "SVUAD6",
  "SVUF8O",
  "SWNQN8",
  "SWPLXN",
  "SXAJYZ",
  "SY77GW",
  "SYI84T",
  "SYYPKN",
  "SYZO6M",
  "SZ2HQG",
  "SZ5MI2",
  "SZK8GQ",
  "SZNTN6",
  "SZOBR5",
  "T2IXGB",
  "T2US72",
  "T53Q46",
  "T5UWKP",
  "T6DQBN",
  "T75HKF",
  "T75PRH",
  "T7YIZJ",
  "T8DABK",
  "T8FF3A",
  "T9MHCL",
  "TA77OA",
  "TAB3KV",
  "TCHYS9",
  "TCJD4Q",
  "TDI2UX",
  "TGAEIX",
  "TGKBCU",
  "THBSOA",
  "TI6V6C",
  "TI9I3Y",
  "TIHA7C",
  "TIZ2W8",
  "TJACBK",
  "TJBNJA",
  "TJG9SD",
  "TJQBFH",
  "TM8M9L",
  "TMLFFN",
  "TOSHG2",
  "TOZWVT",
  "TPGGPT",
  "TPNGDR",
  "TQSNZT",
  "TSWRRK",
  "TT6QLB",
  "TTYSIF",
  "TUZFJ4",
  "TV5F67",
  "TXRJSM",
  "TYSCMR",
  "TZHJHQ",
  "TZQTIO",
  "U33X4W",
  "U3NCIC",
  "U48SPK",
  "U5STI7",
  "U5VBZ9",
  "U5WMFF",
  "U66868",
  "U74FNE",
  "U76YZJ",
  "U8FBJP",
  "UA4R78",
  "UAKE8R",
  "UAMOII",
  "UAZ3OJ",
  "UAZU59",
  "UB7IR5",
  "UBV2KW",
  "UCIU5V",
  "UG5YAB",
  "UGF3KW",
  "UIH6R5",
  "UISBPZ",
  "UKTIRK",
  "UL2E59",
  "UL966O",
  "UMNGKF",
  "UNVN62",
  "UP5FPL",
  "UREAFE",
  "URR4F3",
  "URY726",
  "USFIUZ",
  "USNFCM",
  "USNHLK",
  "UTFXD4",
  "UTIC5F",
  "UU4DL3",
  "UV6RRW",
  "UW5DJK",
  "UXTKD8",
  "UYN4Z7",
  "UZ34YJ",
  "UZA9CN",
  "V2G9LR",
  "V3NIFW",
  "V6IS3U",
  "V7D475",
  "V7MPHU",
  "V7PWQW",
  "V9WSBR",
  "VACLDM",
  "VBGH87",
  "VCQ6L6",
  "VCXLZX",
  "VD36MF",
  "VFYKU2",
  "VFZ7WQ",
  "VG2STM",
  "VG557M",
  "VG7V39",
  "VHB4U6",
  "VHE2IT",
  "VHNRB7",
  "VI49WC",
  "VIKWIV",
  "VJ7YTW",
  "VJCZX2",
  "VJRSYR",
  "VKRYDB",
  "VKUYMR",
  "VLHDHD",
  "VLMHRV",
  "VMP7OG",
  "VNDB9M",
  "VOAGKV",
  "VOTDDB",
  "VOYTEN",
  "VR6HOP",
  "VRRAW8",
  "VRTVHI",
  "VSEJYX",
  "VTAGSB",
  "VTSNFG",
  "VU5KLS",
  "VUG63L",
  "VUZ4MA",
  "VUZ5VW",
  "VUZAK9",
  "VV248L",
  "VV29CN",
  "VVS7LZ",
  "VW7729",
  "VXF9IX",
  "VYUVC2",
  "VYVDZQ",
  "VZSJWN",
  "W3DXEQ",
  "W44A4D",
  "W45A5T",
  "W58YLZ",
  "W5EW4Q",
  "W6SRWW",
  "W7HOQK",
  "W87KST",
  "W94TYK",
  "W9RGP8",
  "W9S35K",
  "WACTEO",
  "WAO4P6",
  "WAW2QR",
  "WAZITG",
  "WBTXED",
  "WCAWHL",
  "WCDSRJ",
  "WDBKM4",
  "WDDTPX",
  "WE25H5",
  "WEZJ46",
  "WFTITA",
  "WGYCQF",
  "WH94H6",
  "WICKER",
  "WIU5XJ",
  "WIY37N",
  "WJKCBU",
  "WKA2IW",
  "WLNXCP",
  "WM9UZ5",
  "WMMQY4",
  "WN7XZQ",
  "WNF8U8",
  "WNFZ9R",
  "WNS32G",
  "WNTAJN",
  "WNYJDK",
  "WNYU39",
  "WOZ6KP",
  "WQBO6S",
  "WQEHI3",
  "WQI69X",
  "WQPUAU",
  "WQTW5Z",
  "WR5OH9",
  "WRH3OC",
  "WRXEO9",
  "WSDC4M",
  "WSV5WV",
  "WTTZ65",
  "WU9STK",
  "WUINBY",
  "WUOJUI",
  "WV7B8F",
  "WVB9CV",
  "WVEVCL",
  "WVF4A4",
  "WWL8YX",
  "WXDEG5",
  "WY55HP",
  "WZ6TCR"
];