import React from 'react';
import {useSelector} from "react-redux";
import {BookContentConnected, WebCheckInConnected, FlightStatusContentConnected, MyTripConnected} from "../../../index";
import {SW_TABS} from "../../../../store/types";

const TabBarContentConnected = () => {

    const selectedTab = useSelector(state => state.sw.selectedTab);

    switch (selectedTab) {
        case SW_TABS.BOOK:
            return <BookContentConnected/>;
        case SW_TABS.CHECK_IN:
            return <WebCheckInConnected/>;
        case SW_TABS.FLIGHT_STATUS:
            return <FlightStatusContentConnected/>;
        case SW_TABS.MY_TRIP:
            return <MyTripConnected/>
        default:
            return null;
    }
};

export default TabBarContentConnected;