import React from 'react';
import PropTypes from 'prop-types';
import {Tab, Tabs} from '@mui/material';
import useStyles from "./TabBarUnderlinedStyles";

const TabBar = ({tabs = [], tabProps = {}, selected, onSelect = null, allTabsAlwaysVisible = false, ...props}) => {

    const classes = useStyles();

    return (
        <Tabs value={selected}
              onChange={onSelect}
              aria-label='sw sub tab bar'
              variant={'scrollable'}
              scrollButtons={'off'}
              classes={{
                  root: ['azo-sw-tabs-underlined-container', classes.root].join(' '),
              }}
              {...props}
        >
            {tabs.map((tab, idx) => (
                <Tab
                    key={idx}
                    icon={tab.icon}
                    label={tab.label}
                    value={tab.value}
                    style={{minWidth: 100 / tabs.length + '%', ...(allTabsAlwaysVisible ? {maxWidth: 100 / tabs.length + '%'} : {})}}
                    classes={{
                        wrapper: classes.tabWrapper,
                        root: [classes.tabRoot, selected === tab.value ? classes.tabRootSelected : ''].join(' '),
                        labelIcon: classes.labelIcon
                    }}
                    {...tabProps}
                />
            ))}
        </Tabs>

    );
};

TabBar.propTypes = {
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.any,
            value: PropTypes.string
        })
    ),
    tabProps: PropTypes.object,
    selected: PropTypes.string,
    onSelect: PropTypes.func,
    'aria-label': PropTypes.string,
    'aria-labelledby': PropTypes.string,
    centered: PropTypes.bool,
    children: PropTypes.node,
    classes: PropTypes.object,
    className: PropTypes.string,
    component: PropTypes.elementType,
    indicatorColor: PropTypes.oneOf(['primary', 'secondary']),
    onChange: PropTypes.func,
    orientation: PropTypes.oneOf(['horizontal', 'vertical']),
    ScrollButtonComponent: PropTypes.elementType,
    scrollButtons: PropTypes.oneOf(['auto', 'desktop', 'off', 'on']),
    selectionFollowsFocus: PropTypes.bool,
    TabIndicatorProps: PropTypes.object,
    TabScrollButtonProps: PropTypes.object,
    textColor: PropTypes.oneOf(['inherit', 'primary', 'secondary']),
    value: PropTypes.any,
    variant: PropTypes.oneOf(['fullWidth', 'scrollable', 'standard']),
    allTabsAlwaysVisible: PropTypes.bool,
};



export default TabBar;