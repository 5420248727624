import {createRef} from 'react';

export const COMPONENTS = {
    NONE: null,
    BOOK_FORM_REF: 'BOOK_FORM_REF',
    DEPARTURE_REF: 'DEPARTURE_REF',
    ARRIVAL_REF: 'ARRIVAL_REF',
    PASSENGERS_COUNT_REF: 'PASSENGERS_COUNT_REF',
    PROMO_CODE_REF: 'PROMO_CODE_REF',
    STOPOVER_OUTBOUND_LEG_ISLAND_SELECTOR_REF: 'STOPOVER_OUTBOUND_LEG_ISLAND_SELECTOR_REF',
    STOPOVER_OUTBOUND_LEG_NUMBER_OF_NIGHTS_SELECTOR_REF: 'STOPOVER_OUTBOUND_LEG_NUMBER_OF_NIGHTS_SELECTOR_REF',
    STOPOVER_INBOUND_LEG_ISLAND_SELECTOR_REF: 'STOPOVER_INBOUND_LEG_ISLAND_SELECTOR_REF',
    STOPOVER_INBOUND_LEG_NUMBER_OF_NIGHTS_SELECTOR_REF: 'STOPOVER_INBOUND_LEG_NUMBER_OF_NIGHTS_SELECTOR_REF',
    MULTI_LEG_0_ROUTE_SELECTOR_REF: 'MULTI_LEG_0_ROUTE_SELECTOR_REF',
    MULTI_LEG_0_DEPARTURE_REF: 'MULTI_LEG_0_DEPARTURE_REF',
    MULTI_LEG_1_ROUTE_SELECTOR_REF: 'MULTI_LEG_1_ROUTE_SELECTOR_REF',
    MULTI_LEG_1_DEPARTURE_REF: 'MULTI_LEG_1_DEPARTURE_REF',
    MULTI_LEG_2_ROUTE_SELECTOR_REF: 'MULTI_LEG_2_ROUTE_SELECTOR_REF',
    MULTI_LEG_2_DEPARTURE_REF: 'MULTI_LEG_2_DEPARTURE_REF',
    MULTI_LEG_3_ROUTE_SELECTOR_REF: 'MULTI_LEG_3_ROUTE_SELECTOR_REF',
    MULTI_LEG_3_DEPARTURE_REF: 'MULTI_LEG_3_DEPARTURE_REF',
    MULTI_LEG_4_ROUTE_SELECTOR_REF: 'MULTI_LEG_4_ROUTE_SELECTOR_REF',
    MULTI_LEG_4_DEPARTURE_REF: 'MULTI_LEG_4_DEPARTURE_REF',
    MULTI_LEG_5_ROUTE_SELECTOR_REF: 'MULTI_LEG_5_ROUTE_SELECTOR_REF',
    MULTI_LEG_5_DEPARTURE_REF: 'MULTI_LEG_5_DEPARTURE_REF',
    MULTI_LEG_6_ROUTE_SELECTOR_REF: 'MULTI_LEG_6_ROUTE_SELECTOR_REF',
    MULTI_LEG_6_DEPARTURE_REF: 'MULTI_LEG_6_DEPARTURE_REF',
    MULTI_LEG_7_ROUTE_SELECTOR_REF: 'MULTI_LEG_7_ROUTE_SELECTOR_REF',
    MULTI_LEG_7_DEPARTURE_REF: 'MULTI_LEG_7_DEPARTURE_REF',
};

export const BOOK_FORM_REF = createRef();
export const BOOK_STOPOVER_FORM_REF = createRef();
export const BOOK_RAIL_FLY_FORM_REF = createRef();
export const BOOK_STOPOVER_WITH_EXPERIENCES_FORM_REF = createRef();
export const DEPARTURE_REF = createRef();
export const ARRIVAL_REF = createRef();
export const PASSENGERS_COUNT_REF = createRef();
export const PROMO_CODE_REF = createRef();

export const STOPOVER_REFS = {
    STOPOVER_OUTBOUND_LEG_ISLAND_SELECTOR_REF: createRef(),
    STOPOVER_OUTBOUND_LEG_NUMBER_OF_NIGHTS_SELECTOR_REF: createRef(),
    STOPOVER_INBOUND_LEG_ISLAND_SELECTOR_REF: createRef(),
    STOPOVER_INBOUND_LEG_NUMBER_OF_NIGHTS_SELECTOR_REF: createRef(),
};
export const MULTI_REFS = {
    MULTI_LEG_0_ROUTE_SELECTOR_REF: createRef(),
    MULTI_LEG_0_DEPARTURE_REF: createRef(),
    MULTI_LEG_1_ROUTE_SELECTOR_REF: createRef(),
    MULTI_LEG_1_DEPARTURE_REF: createRef(),
    MULTI_LEG_2_ROUTE_SELECTOR_REF: createRef(),
    MULTI_LEG_2_DEPARTURE_REF: createRef(),
    MULTI_LEG_3_ROUTE_SELECTOR_REF: createRef(),
    MULTI_LEG_3_DEPARTURE_REF: createRef(),
    MULTI_LEG_4_ROUTE_SELECTOR_REF: createRef(),
    MULTI_LEG_4_DEPARTURE_REF: createRef(),
    MULTI_LEG_5_ROUTE_SELECTOR_REF: createRef(),
    MULTI_LEG_5_DEPARTURE_REF: createRef(),
    MULTI_LEG_6_ROUTE_SELECTOR_REF: createRef(),
    MULTI_LEG_6_DEPARTURE_REF: createRef(),
    MULTI_LEG_7_ROUTE_SELECTOR_REF: createRef(),
    MULTI_LEG_7_DEPARTURE_REF: createRef()
};