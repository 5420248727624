import React from 'react';
import PropTypes from 'prop-types';
import {DropInput, DropInputContent, NumberPicker} from "../../index";
import PassengerTypeTypography from './PassengerTypeTypography';
import {Box} from '@mui/material';
import {t} from 'ttag';

export const TYPES = {
    ADULTS: 'ADULTS',
    CHILDREN: 'CHILDREN',
    INFANTS: 'INFANTS'
}

const PassengersCountSelector = ({
                                     formData = null,
                                     onFocus = () => null,
                                     onBlur = () => null,
                                     onChange = (formData, event) => null,
                                     disabled,
                                     readOnly,
                                     fullWidth,
                                     containerRef,
                                     disabledTypes = [],
                                     innerHelperText = null,
                                     open = false,
                                     MAX = 8,
                                 }) => {

    const {adults = 1, children = 0, infants = 0} = formData;
    const passengersCount = adults + children + infants;

    return (
        <DropInput
            label={t`Passengers`}
            value={passengersCount === 1 ? t`${passengersCount} passenger` : t`${passengersCount} passengers`}
            placeholder={t`Passengers`}
            fullWidth={fullWidth}
            containerRef={containerRef}
            onFocus={onFocus}
            onBlur={onBlur}
            open={open}
            readOnly>
            <DropInputContent className={'azo-passengers-count-selector-modal-content'}>
                <Box display={'flex'} flexDirection={'column'}>
                    <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} paddingLeft={2}>
                        <PassengerTypeTypography title={t`Adults`} subtitle={t`12y or more`}
                                                 disabled={disabled || disabledTypes.includes(TYPES.ADULTS)}/>
                        <NumberPicker
                            value={adults}
                            onChange={(n, e) => onChange({adults: n}, e)}
                            min={infants || 1}
                            max={MAX - children}
                            disabled={disabled || disabledTypes.includes(TYPES.ADULTS)}
                            readOnly={readOnly}
                        />
                    </Box>
                    <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} paddingLeft={2}>
                        <PassengerTypeTypography title={t`Children`} subtitle={t`From 2y to 11y`}
                                                 disabled={disabled || disabledTypes.includes(TYPES.CHILDREN)}/>
                        <NumberPicker
                            value={children}
                            onChange={(n, e) => onChange({children: n}, e)}
                            min={0}
                            max={MAX - adults}
                            disabled={disabled || disabledTypes.includes(TYPES.CHILDREN)}
                            readOnly={readOnly}
                        />
                    </Box>
                    <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} paddingLeft={2}>
                        <PassengerTypeTypography title={t`Infants`} subtitle={t`Under 2y`}
                                                 disabled={disabled || disabledTypes.includes(TYPES.INFANTS)}/>
                        <NumberPicker
                            value={infants}
                            onChange={(n, e) => onChange({infants: n}, e)}
                            min={0}
                            max={adults}
                            disabled={disabled || disabledTypes.includes(TYPES.INFANTS)}
                            readOnly={readOnly}
                        />
                    </Box>
                    {innerHelperText}
                </Box>
            </DropInputContent>
        </DropInput>
    );
};

PassengersCountSelector.TYPES = TYPES;

PassengersCountSelector.propTypes = {
    open: PropTypes.bool,
    formData: PropTypes.shape({
        adults: PropTypes.number,
        children: PropTypes.number,
        infants: PropTypes.number,
    }),
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    MAX: PropTypes.number,
    fullWidth: PropTypes.bool,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    containerRef: PropTypes.any,
    innerHelperText: PropTypes.node,
    disabledTypes: PropTypes.arrayOf(
        PropTypes.oneOf(Object.values(PassengersCountSelector.TYPES))
    ),
};

export default PassengersCountSelector;