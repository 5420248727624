import React, {useState} from 'react';
import PropTypes from 'prop-types';
import useStyles from "./StopoverLegStyles";
import StopoverLegIslandSelect from "./resources/StopoverLegIslandSelect/StopoverLegIslandSelect";
import StopoverLegSwitch from "./resources/StopoverLegSwitch/StopoverLegSwitch";
import StopoverLegNightsSelect from "./resources/StopoverLegNightsSelect/StopoverLegNightsSelect";
import {useMediaQuery, useTheme} from "@mui/material";

const StopoverLeg = ({
                         IslandSelectProps = {},
                         NightsSelectProps = {},
                         SwitchProps = {},
                         disabled = false,
                         ariaLabel
                     }) => {

    const [islandOpen, setIslandOpen] = useState(false);
    const [nightsOpen, setNightsOpen] = useState(false);
    const {breakpoints} = useTheme();
    const isMobile = useMediaQuery(breakpoints.down('sm'));
    const classes = useStyles(isMobile);

    return (
        <div className={classes.container}>
            <div className={classes.islandAndSwitchContainer}>
                <StopoverLegIslandSelect
                    label={`On return`}
                    placeholder={`Select an Island`}
                    open={islandOpen}
                    onFocus={() => setIslandOpen(true)}
                    onBlur={() => setIslandOpen(false)}
                    disabled={disabled}
                    fullWidth
                    DropInputProps={{
                        TextInputProps: {
                            shrink: true,
                            infiniteOptions: {infiniteRight: true, infiniteBottom: isMobile},
                            borderRadius: isMobile ? '5px 0 0 0' : '5px 0 0 5px',
                            ariaDisabled: true,
                        }
                    }}
                    {...IslandSelectProps}
                />
                <StopoverLegSwitch
                    disabled={disabled}
                    ariaLabel={ariaLabel}
                    GenericInputProps={{
                        infiniteOptions: {infiniteLeft: true, infiniteBottom: isMobile},
                        borderRadius: isMobile ? '0 5px 0 0' : '0'
                    }}
                    {...SwitchProps}
                />
            </div>
            <div className={classes.numberOfNightsPickerContainer}>
                <StopoverLegNightsSelect
                    label={`Duration`}
                    placeholder={`Nº nights`}
                    open={nightsOpen}
                    onFocus={() => setNightsOpen(true)}
                    onBlur={() => setNightsOpen(false)}
                    disabled={disabled}
                    fullWidth
                    DropInputProps={{
                        TextInputProps: {
                            shrink: true,
                            infiniteOptions: {infiniteLeft: !isMobile},
                            borderRadius: isMobile ? '0 0 5px 5px' : '0 5px 5px 0',
                            ariaDisabled: true,
                        }
                    }}
                    {...NightsSelectProps}
                />
            </div>
        </div>
    );
};

StopoverLeg.propTypes = {
    IslandSelectProps: PropTypes.shape({
        label: PropTypes.string,
        placeholder: PropTypes.string,
        value: PropTypes.string,
        onSelect: PropTypes.func,
        onFocus: PropTypes.func,
        onBlur: PropTypes.func,
        open: PropTypes.bool,
        disabled: PropTypes.bool,
        fullWidth: PropTypes.bool,
        loading: PropTypes.bool,
        containerRef: PropTypes.any,
        disabledIslands: PropTypes.arrayOf(PropTypes.oneOf(['PDL', 'TER'])),
        DropInputProps: PropTypes.object,
    }),
    NightsSelectProps: PropTypes.shape({
        label: PropTypes.string,
        placeholder: PropTypes.string,
        value: PropTypes.string,
        numberOfNights: PropTypes.number,
        availableNights: PropTypes.arrayOf(PropTypes.shape({
            numberOfNights: PropTypes.number,
            date: PropTypes.string,
        })),
        onSelect: PropTypes.func,
        onFocus: PropTypes.func,
        onBlur: PropTypes.func,
        open: PropTypes.bool,
        disabled: PropTypes.bool,
        fullWidth: PropTypes.bool,
        loading: PropTypes.bool,
        containerRef: PropTypes.any,
        DropInputProps: PropTypes.object,
    }),
    SwitchProps: PropTypes.shape({
        onChange: PropTypes.func,
        checked: PropTypes.bool,
        disabled: PropTypes.bool,
        disabledAppearance: PropTypes.bool,
        GenericInputProps: PropTypes.object,
        ariaLabel: PropTypes.string,
    }),
    disabled: PropTypes.bool,
};

export default StopoverLeg;