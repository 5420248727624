import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {PromoCodeInput} from "../../../../index";
import {setSwBookState, setSwState} from "../../../../../store/reducers/search-widget";
import {SW_PASSENGER_TYPES} from "../../../../../store/types";
import {COMPONENTS} from "../../common/book-form-input-refs";

const PromoCodeInputConnected = () => {

    const focusedComponent = useSelector(state => state.sw.book.focusedComponent);
    const promoCode = useSelector(state => state.sw.book.promoCode);
    const withMiles = useSelector(state => state.sw.book.withMiles);
    const withCashNMiles = useSelector(state => state.sw.book.withCashNMiles);
    const passengerType = useSelector(state => state.sw.book.passengerType);
    const dispatch = useDispatch();

    return (
        <PromoCodeInput
            promoCode={promoCode}
            onFocus={() => dispatch(setSwState({focusedComponent: COMPONENTS.PROMO_CODE_REF}))}
            onBlur={() => focusedComponent === COMPONENTS.PROMO_CODE_REF && dispatch(setSwState({focusedComponent: COMPONENTS.NONE}))}
            onChange={promoCode => dispatch(setSwBookState({promoCode}))}
            disabled={Boolean(withMiles || withCashNMiles || [SW_PASSENGER_TYPES.RESIDENT, SW_PASSENGER_TYPES.STUDENT].includes(passengerType))}
            shrink
        />
    );
};

export default PromoCodeInputConnected;
