import React from 'react';
import PropTypes from 'prop-types';
import {GenericInput} from "../../../../../index";
import {Switch} from "@mui/material";

const StopoverLegSwitch = ({
                               onChange = () => null,
                               checked = false,
                               disabled = false,
                               disabledAppearance = false,
                               GenericInputProps = {},
                               ariaLabel = '',
                               ...props
                           }) => {

    return (
        <GenericInput disabled={disabled || disabledAppearance} {...GenericInputProps}>
            <Switch
                onChange={onChange}
                checked={checked}
                disabled={disabled}
                inputProps={{
                    "aria-label": ariaLabel
                }}
                {...props}
            />
        </GenericInput>
    );
};

StopoverLegSwitch.propTypes = {
    onChange: PropTypes.func,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    disabledAppearance: PropTypes.bool,
    GenericInputProps: PropTypes.object,
    ariaLabel: PropTypes.string,
};

export default StopoverLegSwitch;