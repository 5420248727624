import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {StopoverButton, useIsStopoverAvailableConnected} from "../../../../index";
import {t} from 'ttag';
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import {BOOK_STOPOVER_FORM_REF} from "../../common/book-form-input-refs";
import {SW_FLIGHT_TYPES} from "../../../../../store/types";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const BookStopoverSubmitButtonConnected = () => {

    const [submitting, setSubmitting] = useState(false);
    const isStopoverAvailable = useIsStopoverAvailableConnected();
    const {loading, outbound, inbound} = useSelector(state => state.sw.book.stopover);
    const currentLanguage = useSelector(state => state.app.currentLanguage);
    const isOutboundDefined = Boolean(outbound?.enabled && outbound?.selectedAirport && outbound?.numberOfNights && outbound?.departureDate);
    const isInboundDefined = Boolean(inbound?.enabled && inbound?.selectedAirport && inbound?.numberOfNights && inbound?.departureDate);

    const {
        origin = '',
        destination = '',
        departure = '',
        arrival = '',
        adults = '',
        children = '',
        infants = '',
        flightType = '',
    } = useSelector(state => state.sw.book);

    const isRoundTrip = flightType === SW_FLIGHT_TYPES.RETURN;
    const isOneWay = flightType === SW_FLIGHT_TYPES.ONE_WAY;

    const canSubmit = (() => {
        return Boolean(
            isStopoverAvailable
            && ((isOneWay && isOutboundDefined) || (isRoundTrip && (isOutboundDefined || isInboundDefined)))
        );
    })();

    const form = (() => {
        if (!canSubmit) return null;
        const isSpecialCaseToAddADayOutboundLeg = ['BOS','JFK','YYZ','YUL','OAK'].includes(origin?.code)
        const isSpecialCaseToAddADayInboundLeg = ['BOS','JFK','YYZ','YUL','OAK'].includes(destination?.code)
        const firstLeg = `${origin?.code || ''}.${destination?.code || ''}.${departure || ''}`;
        const lastLeg = `${destination?.code || ''}.${origin?.code || ''}.${arrival || ''}`;
        const outboundLeg = `STOP_${outbound?.selectedAirport || ''}_${isSpecialCaseToAddADayOutboundLeg ? (outbound?.numberOfNights + 1) : outbound?.numberOfNights}`;
        const inboundLeg = `STOP_${inbound?.selectedAirport || ''}_${isSpecialCaseToAddADayInboundLeg ? (inbound?.numberOfNights + 1) : inbound?.numberOfNights}`;
        return (
            <form method={'GET'}
                  action={`${process.env.REACT_APP_SUBMIT_URL_TP2}`}
                // target={'_blank'}
                  ref={BOOK_STOPOVER_FORM_REF}>
                <input type={'hidden'} name={'step'} value={'flights'}/>
                <input type={'hidden'} name={'pointOfSale'} value={'PT'}/>
                <input type={'hidden'} name={'adults'} value={adults}/>
                {children > 0 && <input type={'hidden'} name={'children'} value={children}/>}
                {infants > 0 && <input type={'hidden'} name={'infants'} value={infants}/>}
                <input type={'hidden'} name={'lang'} value={currentLanguage.substring(0, 2)}/>
                {isOneWay && isOutboundDefined &&
                    <input type={'hidden'} name={'search'} value={`${firstLeg}.${outboundLeg}`}/>
                }
                {isRoundTrip && isOutboundDefined && !isInboundDefined &&
                    <input type={'hidden'} name={'search'} value={`${firstLeg}.${outboundLeg}*${lastLeg}`}/>
                }
                {isRoundTrip && !isOutboundDefined && isInboundDefined &&
                    <input type={'hidden'} name={'search'} value={`${firstLeg}*${lastLeg}.${inboundLeg}`}/>
                }
                {isRoundTrip && isOutboundDefined && isInboundDefined &&
                    <input type={'hidden'} name={'search'} value={`${firstLeg}.${outboundLeg}*${lastLeg}.${inboundLeg}`}/>
                }
            </form>
        );
    })();

    const submitHandler = () => {
        try {
            setSubmitting(true);
            BOOK_STOPOVER_FORM_REF.current.submit();
        } catch (e) {
            setSubmitting(false);
        }
    }

    return (
        <Box display={'flex'} justifyContent={'center'}>
            {canSubmit && form}
            <StopoverButton
                title={t`Stopover`}
                subtitle={t`with Flight`}
                disabled={Boolean(loading || submitting || !canSubmit)}
                onClick={submitHandler}
                icon={submitting ? <CircularProgress/> : <ArrowForwardIosIcon/>}
            />
        </Box>
    );
};

export default BookStopoverSubmitButtonConnected;
