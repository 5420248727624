import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {t} from 'ttag';
import Box from "@mui/material/Box";
import {BOOK_RAIL_FLY_FORM_REF} from "../../common/book-form-input-refs";
import {SW_FLIGHT_TYPES, SW_RAIL_FLY_LEG_TYPES} from "../../../../../store/types";
import RailFlyButton from "../../../../Forms/Rail&Fly/Rail&FlyButton/Rail&FlyButton";

const BookRailFlySubmitButtonConnected = () => {

    const [submitting, setSubmitting] = useState(false);
    const {loading, legType} = useSelector(state => state.sw.book.railFly);
    const currentLanguage = useSelector(state => state.app.currentLanguage);

    const {
        origin = '',
        destination = '',
        departure = '',
        arrival = '',
        adults = '',
        children = '',
        infants = '',
        flightType = '',
    } = useSelector(state => state.sw.book);

    const isRoundTrip = flightType === SW_FLIGHT_TYPES.RETURN;
    const isOneWay = flightType === SW_FLIGHT_TYPES.ONE_WAY;

    const canSubmit = (() => {
        return Boolean((isOneWay && departure) || (isRoundTrip && departure && arrival ));
    })();

    const changeFraToQyg = (code) => {
        if (code === 'FRA') return 'QYG';
        return code;
    }

    const form = (() => {
        if (!canSubmit) return null;
        const firstLegWithoutRail = `${origin?.code || ''}.${destination?.code || ''}.${departure || ''}`;
        const firstLeg = `${changeFraToQyg(origin?.code) || ''}.${changeFraToQyg(destination?.code) || ''}.${departure || ''}`;
        const lastLegWithoutRail = `${destination?.code || ''}.${origin?.code || ''}.${arrival || ''}`;
        const lastLeg = `${changeFraToQyg(destination?.code) || ''}.${changeFraToQyg(origin?.code) || ''}.${arrival || ''}`;
        return (
            <form method={'GET'}
                  action={`${process.env.REACT_APP_SUBMIT_URL_TP2}`}
                  ref={BOOK_RAIL_FLY_FORM_REF}>
                <input type={'hidden'} name={'step'} value={'flights'}/>
                <input type={'hidden'} name={'pointOfSale'} value={'PT'}/>
                <input type={'hidden'} name={'adults'} value={adults}/>
                {children > 0 && <input type={'hidden'} name={'children'} value={children}/>}
                {infants > 0 && <input type={'hidden'} name={'infants'} value={infants}/>}
                <input type={'hidden'} name={'lang'} value={currentLanguage.substring(0, 2)}/>
                {isOneWay &&
                    <input type={'hidden'} name={'search'} value={`${firstLeg}`}/>
                }
                {isRoundTrip && legType === SW_RAIL_FLY_LEG_TYPES.ONE_WAY &&
                    <input type={'hidden'} name={'search'} value={`${firstLeg}*${lastLegWithoutRail}`}/>
                }
                {isRoundTrip && legType === SW_RAIL_FLY_LEG_TYPES.ROUND_TRIP &&
                    <input type={'hidden'} name={'search'} value={`${firstLeg}*${lastLeg}`}/>
                }
                {isRoundTrip && legType === SW_RAIL_FLY_LEG_TYPES.RETURN &&
                    <input type={'hidden'} name={'search'}
                           value={`${firstLegWithoutRail}*${lastLeg}`}/>
                }
            </form>
        );
    })();

    const submitHandler = () => {
        try {
            setSubmitting(true);
            BOOK_RAIL_FLY_FORM_REF.current.submit();
        } catch (e) {
            setSubmitting(false);
        }
    }

    return (
        <Box display={'flex'} justifyContent={'center'}>
            {canSubmit && form}
            <RailFlyButton
                sx={{justifyContent: 'center'}}
                title={t`Search with Rail`}
                disabled={Boolean(loading || submitting || !canSubmit)}
                onClick={submitHandler}
            />
        </Box>
    );
};

export default BookRailFlySubmitButtonConnected;
