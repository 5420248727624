import {makeStyles} from "@mui/styles";

const withStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.primary.contrastText,
        minHeight: 'auto',
    },
    tabWrapper: {
        flexDirection: 'row',
    },
    tabRoot: {
        borderBottom: `2px solid ${theme.palette.text.disabled}`,
        fontFamily: 'OpenSansRegular',
        minHeight: 'auto',
        fontSize: '.83rem',
        color: theme.palette.text.primary,
    },
    tabRootSelected: {
        color: theme.palette.primary.main,
    },
}));

export default withStyles;