import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(theme => ({
    img1: {
        borderRadius: '5px',
        height: ({isMobile, isTablet}) => isMobile ? '215px' : (isTablet ? '325px' : '305px'),
    },
    img2: {
        borderRadius: '5px',
        height: ({isMobile, isTablet}) => isMobile ? '170px' : (isTablet ? '280px' : '270px'),
        marginTop: '1px',
    }
}));

export default useStyles;