import React, {cloneElement, isValidElement, Children} from 'react';
import PropTypes from 'prop-types';
import useStyles from './MergedButtonsStyles';


const MergedButtons = ({children, compact = false}) => {

    const classes = useStyles(compact);

    if (children.length <= 1) return children;

    return (
        <div className={classes.container}>
            {Children.map(children, (child, idx) => {
                if (!isValidElement(child)) return child;

                const className = (() => {
                    if (idx === 0) return classes.firstBtn;
                    if (idx > 0 && idx < children.length - 1) return classes.middleBtn;
                    if (idx === children.length - 1) return classes.lastBtn;
                    return '';
                })();

                return cloneElement(child, {
                    classes: {
                        root: className,
                        startIcon: classes.btnStartIcon
                    },
                    ...(compact ? {children: null} : {style: {width: (100 / children.length) + '%'}})
                });
            })}
        </div>
    );
};

MergedButtons.propTypes = {
    children: PropTypes.arrayOf(PropTypes.node).isRequired,
    compact: PropTypes.bool,
};

export default MergedButtons;