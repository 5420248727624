import React from 'react';
import PropTypes from 'prop-types';
import {SelectInput} from "../../../../index";
import {MenuItem} from "@mui/material";
import {t} from 'ttag';


const DateSelect = ({
                        children,
                        variant = 'azo',
                        hideIcon = false,
                        shrink = false,
                        ...props
                    }) => {

    return (
        <SelectInput variant={variant} {...props}>
            <MenuItem value={'YD'}>{t`Yesterday`}</MenuItem>
            <MenuItem value={'TD'}>{t`Today`}</MenuItem>
            <MenuItem value={'TM'}>{t`Tomorrow`}</MenuItem>
            <MenuItem value={'AT'}>{t`After tomorrow`}</MenuItem>
            {children}
        </SelectInput>
    );
};

DateSelect.propTypes = {
    placeholder: PropTypes.string,
    autoWidth: PropTypes.bool,
    children: PropTypes.node,
    classes: PropTypes.object,
    defaultValue: PropTypes.any,
    displayEmpty: PropTypes.bool,
    id: PropTypes.string,
    input: PropTypes.element,
    inputProps: PropTypes.object,
    label: PropTypes.node,
    labelId: PropTypes.string,
    labelWidth: PropTypes.number,
    MenuProps: PropTypes.object,
    multiple: PropTypes.bool,
    native: PropTypes.bool,
    onChange: PropTypes.func,
    onClose: PropTypes.func,
    onOpen: PropTypes.func,
    open: PropTypes.bool,
    renderValue: PropTypes.func,
    SelectDisplayProps: PropTypes.object,
    value: PropTypes.any,
    variant: PropTypes.oneOf(['filled', 'outlined', 'standard', 'azo']),
    hideIcon: PropTypes.bool,
    shrink: PropTypes.bool,
};

export default DateSelect;