import React from 'react';
import PropTypes from 'prop-types';
import styles from './Navigator.module.scss';
import {Box} from "@mui/material";

const Navigator = ({children = null, clickHandler = () => null, disabled = false}) => {

    const classes = disabled ? [styles.nav, styles.disabled].join(' ') : styles.nav;
    clickHandler = disabled ? () => null : clickHandler;

    return (
        <Box className={classes} onClick={clickHandler}>
            {children}
        </Box>
    );
};

Navigator.propTypes = {
    children: PropTypes.any,
    disabled: PropTypes.bool,
    clickHandler: PropTypes.func,
};


export default Navigator;
