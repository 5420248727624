import {alpha} from "@mui/material";
import {makeStyles} from "@mui/styles";

const withStyles = makeStyles((theme) => ({
    root: {
        background: theme.palette.primary.contrastText,
        border: `1px solid ${theme.palette.secondary.main}`,
        color: theme.palette.primary.main,
        fontSize: '17px',
        fontWeight: 'normal',
        borderRadius: theme.spacing(.7),
        minWidth: '100px',
        "& legend": {
            width: 0
        },
        "& fieldset": {
            border: 'none'
        },
    },
    outlined: {
        color: theme.palette.primary.main,
        backgroundColor: 'transparent!important',
        padding: '30px 14px 12px 14px',
    },
    selectMenuItems: {
        "& li": {
            backgroundColor: 'transparent',
            color: theme.palette.primary.main,
        },
        "& li:hover": {
            backgroundColor: alpha(theme.palette.secondary.main, .1),
        },
        '& li[aria-selected="true"]': {
            backgroundColor: alpha(theme.palette.secondary.main, .15),
            fontWeight: 'bolder',
        },
        '& li[aria-selected="true"]:hover': {
            backgroundColor: alpha(theme.palette.secondary.main, .15),
            fontWeight: 'bolder',
        },
    },
    labelRoot: {
        color: theme.palette.primary.main,
        textTransform: 'uppercase',
        fontSize: '.8rem',
        fontWeight: 'bold',
        letterSpacing: '0.1rem',
        top: theme.spacing(.5),
    },
    labelShrink: {
        top: theme.spacing(2.2),
    },
    helpText: {
        fontSize: theme.typography.caption.fontSize,
        marginTop: 0,
    },
    labelDisabled: {
        opacity: '.4',
        cursor: 'not-allowed'
    },
}));

export default withStyles;