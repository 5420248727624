import React from 'react';
import PropTypes from 'prop-types';
import {FormControl, FormHelperText, InputLabel, Select} from "@mui/material";
import withStyles from './SelectInputStyles';

const SelectInput = ({
                         label,
                         variant,
                         children,
                         disabled,
                         fullWidth = false,
                         hideIcon = false,
                         shrink = false,
                         placeholder,
                         helperText='',
                         ...props
                     }) => {

    if (variant !== 'azo') {
        return (
            <FormControl  aria-label={label} aria-labelledby={label} disabled={disabled} variant={variant} fullWidth={fullWidth}>
                <InputLabel disabled={disabled} shrink={Boolean(shrink) || undefined}>{label}</InputLabel>
                <Select
                    variant={variant}
                    label={label}
                    disabled={disabled}
                    fullWidth={fullWidth}
                    aria-label={label}
                    aria-labelledby={label}
                    placeholder={placeholder}
                    {...props}
                >
                    {children}
                </Select>
            </FormControl>
        );
    }

    const classes = withStyles({disabled});

    return (
        <>
            <FormControl disabled={disabled} variant={'outlined'} fullWidth={fullWidth} classes={{root: classes.root}}>
                <InputLabel
                    classes={{
                        root: classes.labelRoot,
                        shrink: classes.labelShrink,
                        disabled: classes.labelDisabled,
                    }}
                    disabled={disabled}
                    shrink={Boolean(shrink) || undefined}>
                    {label}
                </InputLabel>
                <Select
                    variant={'outlined'}
                    label={label}
                    fullWidth={fullWidth}
                    disabled={disabled}
                    IconComponent={hideIcon ? () => null : undefined}
                    classes={{
                        outlined: classes.outlined,
                    }}
                    MenuProps={{
                        MenuListProps: {
                            classes: {root: classes.selectMenuItems}
                        }
                    }}
                    inputProps={{
                        'aria-label': label,
                        'aria-labelledby': label,
                        placeholder: placeholder,
                    }}
                    {...props}
                >
                    {children}
                </Select>
            </FormControl>
            {helperText && (
                <FormHelperText>
                    {helperText}
                </FormHelperText>
            )}
        </>
    );
};

SelectInput.propTypes = {
    placeholder: PropTypes.string,
    autoWidth: PropTypes.bool,
    children: PropTypes.node,
    classes: PropTypes.object,
    defaultValue: PropTypes.any,
    displayEmpty: PropTypes.bool,
    id: PropTypes.string,
    input: PropTypes.element,
    inputProps: PropTypes.object,
    label: PropTypes.node,
    labelId: PropTypes.string,
    labelWidth: PropTypes.number,
    MenuProps: PropTypes.object,
    multiple: PropTypes.bool,
    native: PropTypes.bool,
    onChange: PropTypes.func,
    onClose: PropTypes.func,
    onOpen: PropTypes.func,
    open: PropTypes.bool,
    renderValue: PropTypes.func,
    SelectDisplayProps: PropTypes.object,
    value: PropTypes.any,
    variant: PropTypes.oneOf(['filled', 'outlined', 'standard', 'azo']),
    hideIcon: PropTypes.bool,
    shrink: PropTypes.bool,
    helperText: PropTypes.node,
};

export default SelectInput;