import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {CircularProgress, IconButton, InputAdornment, Popper} from '@mui/material';
import OpenWindowIcon from "../../../Icons/OpenWindowIcon";
import CloseIcon from '@mui/icons-material/Close';
import {BlurAwareContainer, TextInput} from "../../../index";
import styles from './DropInput.module.scss';

const DropInput = ({
                       open = false,
                       label = 'Label',
                       value = '',
                       placeholder = '',
                       readOnly = false,
                       children = '',
                       TextInputProps = {},
                       PopperProps = {},
                       fullWidth = true,
                       adornmentIcon = <OpenWindowIcon/>,
                       containerRef = null,
                       clearHandler = null,
                       helperText = null,
                       disabled = false,
                       onFocus = () => null,
                       onBlur = () => null,
                       error = false,
                       loading = false,
                       sx = {},
                       placement = undefined
                   }) => {
    const localRef = useRef();
    const ref = containerRef || localRef;
    const [childrenInteracting, setChildrenInteracting] = useState(false);

    return (
        <BlurAwareContainer
            aria-label={label}
            aria-labelledby={label}
            containerRef={ref}
            fullWidth={fullWidth}
            classes={'azo-drop-input'}
            onFocus={e => !disabled && onFocus(e)}
            onBlur={e => {
                if (!childrenInteracting && onBlur) return onBlur(e)
                // eslint-disable-next-line
                // ref?.current?.focus();
            }}
            onClickAway={e => open && !childrenInteracting && onBlur(e)}
            tabIndex={disabled ? -1 : 0}
        >
            <TextInput
                label={label}
                aria-label={label}
                aria-labelledby={label}
                value={value}
                placeholder={placeholder}
                disabled={disabled}
                readOnly={readOnly}
                helperText={helperText}
                error={error}
                containerProps={{
                    onMouseDown: () => {
                        if (!disabled) {
                            if (open) {
                                onBlur();
                            } else {
                                onFocus();
                            }
                        }
                    },
                }}
                classes={{root: [styles.dropTextInput, open ? styles.dropTextInputFocused : ''].join(' ')}}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position={'end'} className={styles.inputAdornmentRoot}>
                            {clearHandler ? (
                                <IconButton color={'secondary'} size={'small'} edge={'end'}
                                            tabIndex={disabled ? -1 : 0}
                                            aria-label={label + '-toggle'}
                                            onMouseDown={open ? clearHandler : null}>
                                    {loading ? <CircularProgress size={20}/> : (open ? <CloseIcon/> : adornmentIcon)}
                                </IconButton>
                            ) : (
                                loading ? <CircularProgress size={20}/> : (open ? <CloseIcon/> : adornmentIcon)
                            )}
                        </InputAdornment>
                    ),
                }}
                variant={'azo'}
                fullWidth={fullWidth}
                {...TextInputProps}
            />
            {open && (
                <Popper
                    onMouseDown={() => {
                        setChildrenInteracting(true);
                        setTimeout(() => setChildrenInteracting(false), 150);
                    }}
                    placement={placement}
                    open={open}
                    anchorEl={ref?.current}
                    style={{width: ref?.current ? ref.current.clientWidth : null, zIndex: 1300}}
                    sx={sx}
                    modifiers={{
                        flip: {
                            enabled: true,
                            options: {
                                rootBoundary: 'viewport'
                            }
                        },
                        preventOverflow: {
                            enabled: false,
                            boundariesElement: 'scrollParent',
                        },
                        hide: {
                            enabled: false
                        },
                        keepTogether: {
                            enabled: false
                        },
                        arrow: {
                            enabled: false
                        },
                    }}
                    {...PopperProps}>
                    {children}
                </Popper>
            )}
        </BlurAwareContainer>
    );
};

DropInput.propTypes = {
    open: PropTypes.bool,
    label: PropTypes.node,
    value: PropTypes.any,
    placeholder: PropTypes.any,
    readOnly: PropTypes.bool,
    children: PropTypes.any,
    TextInputProps: PropTypes.object,
    PopperProps: PropTypes.object,
    fullWidth: PropTypes.bool,
    adornmentIcon: PropTypes.node,
    containerRef: PropTypes.any,
    clearHandler: PropTypes.func,
    helperText: PropTypes.node,
    disabled: PropTypes.bool,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    error: PropTypes.bool,
    loading: PropTypes.bool,
    sx: PropTypes.object,
    placement: PropTypes.string
};

export default DropInput;