import {makeStyles} from "@mui/styles";

const withStyles = makeStyles(theme => ({
    checkboxLabel: {
        '& a:link': {
            color: theme.palette.secondary.main,
            textDecoration: 'underline',
        },
        '& a:visited': {
            color: theme.palette.secondary.main,
            textDecoration: 'underline',
        }
    },
    formControlLabel: {
        marginRight: 0,
    }
}));

export default withStyles;