import React from 'react';
import PropTypes from 'prop-types';
import {List, ListItem, ListItemIcon, ListItemText, Collapse} from "@mui/material";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import useStyles from './TabBarCollapsibleStyles';

const TabBarCollapsible = ({
                               tabs = [],
                               selected,
                               onSelect = null,
                               hideCollapseControlWhenSelected = true,
                               unmountOnExit = false
                           }) => {

    const classes = useStyles();
    const Hideable = ({children, hidden = false}) => hidden ? null : children;

    return (
        <List component='nav'>
            {tabs.map(({label, value, icon, children, hiddenWhenSelected}, idx) => (
                <div key={idx}>
                    <Hideable hidden={hiddenWhenSelected && selected === value}>
                        <ListItem onClick={() => onSelect(value)}
                                  classes={{root: [classes.tabListItemRoot, selected === value ? classes.tabListItemRootSelected : ''].join(' ')}}
                                  button>
                            <Hideable hidden={!icon}>
                                <ListItemIcon>
                                    {icon}
                                </ListItemIcon>
                            </Hideable>
                            <ListItemText primary={label}
                                          classes={{primary: selected === value ? classes.listItemText : ''}}/>
                            <Hideable hidden={selected === value && hideCollapseControlWhenSelected}>
                                <span className={classes.tabCollapseControl}>
                                {selected === value
                                    ? <ExpandLess fontSize={'large'}/>
                                    : <ExpandMore fontSize={'large'}/>
                                }
                            </span>
                            </Hideable>
                        </ListItem>
                    </Hideable>
                    <Collapse in={selected === value} unmountOnExit={unmountOnExit}>
                        {children}
                    </Collapse>
                </div>
            ))}
        </List>
    );
};

TabBarCollapsible.propTypes = {
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.any,
            value: PropTypes.string,
            icon: PropTypes.node,
            children: PropTypes.any,
            hiddenWhenSelected: PropTypes.bool,
        })
    ),
    selected: PropTypes.string,
    onSelect: PropTypes.func,
    hideCollapseControlWhenSelected: PropTypes.bool,
    unmountOnExit: PropTypes.bool,
};

export default TabBarCollapsible;