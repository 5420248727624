import React from 'react';
import PropTypes from 'prop-types';
import {DropInput, DropInputContent} from "../../../../../index";
import {MenuList, MenuItem} from "@mui/material";
import {t} from 'ttag';

const StopoverLegNightsSelect = ({
                                     label = '',
                                     placeholder = '',
                                     value = '',
                                     numberOfNights = 7,
                                     availableNights = [],
                                     onSelect = () => null,
                                     onFocus = () => null,
                                     onBlur = () => null,
                                     open = false,
                                     disabled = false,
                                     fullWidth = false,
                                     containerRef = undefined,
                                     loading = false,
                                     DropInputProps = {},
                                 }) => {

    return (
        <DropInput
            label={label}
            placeholder={placeholder}
            value={value}
            onFocus={onFocus}
            onBlur={onBlur}
            open={open}
            disabled={disabled}
            fullWidth={fullWidth}
            containerRef={containerRef}
            loading={loading}
            readOnly
            TextInputProps={{
                shrink: true,
            }}
            {...DropInputProps}
        >
            <DropInputContent>
                <MenuList>
                    {[...Array(numberOfNights)].map((n, idx) => {
                        const nightNumber = idx + 1;
                        const availableNight = availableNights.find(a => a.numberOfNights === nightNumber) || null;
                        return (
                            <MenuItem key={idx} disabled={!availableNight}
                                      onClick={() => onSelect(availableNight ? {...availableNight} : null)}>
                                {nightNumber === 1 ? t`${nightNumber} night` : t`${nightNumber} nights`}
                            </MenuItem>
                        );
                    })}
                </MenuList>
            </DropInputContent>
        </DropInput>
    );
};

StopoverLegNightsSelect.propTypes = {
    label: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    numberOfNights: PropTypes.number,
    availableNights: PropTypes.arrayOf(PropTypes.shape({
        numberOfNights: PropTypes.number,
        date: PropTypes.string,
    })),
    onSelect: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    open: PropTypes.bool,
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    loading: PropTypes.bool,
    containerRef: PropTypes.any,
    DropInputProps: PropTypes.object,
};


export default StopoverLegNightsSelect;