import React, {createRef, useLayoutEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import useStyles from './TabBarCrossSellingStyles';
import {Tab, Tabs, IconButton, Menu, MenuItem, useMediaQuery, useTheme} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import useWindowWidth from "../../../../config/helpers/useWindowWidth";

const TabBarCrossSelling = ({
                                tabs = [],
                                tabProps = {},
                                selected,
                                onSelect = null,
                                options = [],
                                optionSelected,
                                hideMenuWhenAllTabsVisible = false,
                                ...props
                            }) => {

    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(false);
    const [hiddenTabs, setHiddenTabs] = useState([]);
    const {breakpoints} = useTheme();
    const isMobile = useMediaQuery(breakpoints.down('sm'));
    const isSmDown = useMediaQuery(breakpoints.down('sm'));
    const tabsRef = useRef();
    const tabsRefs = Array.from(new Array(tabs.length)).map(() => createRef());
    const windowWidth = useWindowWidth(400);

    useLayoutEffect(() => {
        const tabsWidth = tabsRef.current?.offsetWidth;
        if (!tabsWidth) return;
        let usedSpace = 0;
        const hiddenTabs = [];
        tabs.forEach((tab, idx) => {
            const tabWidth = tabsRefs[idx].current?.offsetWidth + 5;
            // const tabWidth = ((window.innerWidth >= 576 || !idx) ? tab.label.length : 0) * 8 + (tab.icon ? 53 : 0);
            usedSpace += tabWidth;
            if (usedSpace > tabsWidth) {
                return hiddenTabs.push(tab);
            }
        });
        setHiddenTabs(hiddenTabs);
        // eslint-disable-next-line
    }, [isMobile, windowWidth, tabs]);

    const tabClickHandler = ({href = null, openInNewTab = false}, callbackFn = () => null) => {
        callbackFn();
        if (href) {
            window.open(href, openInNewTab ? '_blank' : '_self');
        }
    };

    return (
        <div className={classes.container}>
            <Tabs ref={tabsRef}
                  value={selected}
                  onChange={onSelect}
                  variant={'scrollable'}
                  scrollButtons={'off'}
                  classes={{
                      root: ['azo-sw-tabs-cross-selling-container', classes.root].join(' '),
                      flexContainer: classes.tabsFlexContainer
                  }}
                  {...props}
            >
                {tabs.map((tab, idx) => (
                    <Tab
                        ref={tabsRefs[idx]}
                        key={'visible' + idx}
                        aria-label={tab.label}
                        iconPosition={"start"}
                        icon={tab.icon}
                        label={!isMobile || !idx ? tab.label : ''}
                        onClick={() => tabClickHandler(tab)}
                        value={tab.value}
                        style={hiddenTabs.includes(tab) ? {
                            maxHeight: 0,
                            paddingTop: 0,
                            paddingBottom: 0,
                            marginTop: 0,
                            marginBottom: 0
                        } : {}}
                        classes={{
                            wrapper: classes.tabWrapper,
                            root: [classes.tabRoot, selected === tab.value ? classes.tabRootSelected : ''].join(' '),
                        }}
                        {...tabProps}
                    />
                ))}
            </Tabs>
            {Boolean(!hideMenuWhenAllTabsVisible || hiddenTabs.length) && (
                <IconButton
                    className={classes.menuIcon}
                    color={'secondary'}
                    aria-label={'more'}
                    aria-controls={'app-search-widget'}
                    aria-haspopup
                    disabled={!hiddenTabs.length}
                    edge={isSmDown ? 'end' : false}
                    onClick={e => setAnchorEl(e.currentTarget)}
                >
                    <MenuIcon color={'secondary'} aria-controls={'app-search-widget'}/>
                </IconButton>
            )}
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                classes={{paper: classes.menuPaper}}
                transformOrigin={{vertical: 'top', horizontal: 'right'}}
                aria-label={'Tab bar cross selling menu'}
            >
                {hiddenTabs.map((tab, idx) => (
                    <MenuItem key={idx}
                              selected={optionSelected}
                              className={classes.menuItem}
                              aria-label={tab.label}
                              onClick={() => tabClickHandler(tab, () => setAnchorEl(null))}>
                        {tab.label}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};

TabBarCrossSelling.propTypes = {
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.string,
            href: PropTypes.string,
            openInNewTab: PropTypes.bool,
        })
    ),
    tabProps: PropTypes.object,
    selected: PropTypes.string,
    onSelect: PropTypes.func,
    options: PropTypes.array,
    optionSelected: PropTypes.bool,
    hideMenuWhenAllTabsVisible: PropTypes.bool,
    'aria-label': PropTypes.string,
    'aria-labelledby': PropTypes.string,
    centered: PropTypes.bool,
    children: PropTypes.node,
    classes: PropTypes.object,
    className: PropTypes.string,
    component: PropTypes.elementType,
    indicatorColor: PropTypes.oneOf(['primary', 'secondary']),
    onChange: PropTypes.func,
    orientation: PropTypes.oneOf(['horizontal', 'vertical']),
    ScrollButtonComponent: PropTypes.elementType,
    scrollButtons: PropTypes.oneOf(['auto', 'desktop', 'off', 'on']),
    selectionFollowsFocus: PropTypes.bool,
    TabIndicatorProps: PropTypes.object,
    TabScrollButtonProps: PropTypes.object,
    textColor: PropTypes.oneOf(['inherit', 'primary', 'secondary']),
    value: PropTypes.any,
    variant: PropTypes.oneOf(['fullWidth', 'scrollable', 'standard']),
};

export default TabBarCrossSelling;