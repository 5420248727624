import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setSwBookRailFlyState} from "../../../../../store/reducers/search-widget";
import {Grid2, useMediaQuery, useTheme} from "@mui/material";
import {t} from 'ttag';
import useIsRailFlyEligibleConnected from "./useIsRailFlyEligibleConnected";
import RailFlyOpenButton from "../../../../Forms/Rail&Fly/RailFlyOpenButton/RailFlyOpenButton";

const BookRailFlyOpenButtonConnected = () => {

    const {breakpoints} = useTheme();
    const isMobile = useMediaQuery(breakpoints.down('sm'));
    const isRailFlyEligible = useIsRailFlyEligibleConnected();
    const open = useSelector(state => state.sw.book.railFly.open);
    const dispatch = useDispatch();

    if (open || !isRailFlyEligible) return null;

    return (
        <Grid2 size={{xs: 12}}>
            <RailFlyOpenButton
                title={t`Add an RAIL to your trip`}
                subtitle={t`Click here to add`}
                hideSubtitle={isMobile}
                onClick={() => dispatch(setSwBookRailFlyState({open: true}))}
            />
        </Grid2>
    );
};

export default BookRailFlyOpenButtonConnected;