import React from 'react';
import PropTypes from 'prop-types';
import {Box, IconButton} from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';

const NumberPicker = ({
                          value = 0,
                          min = Number.MIN_SAFE_INTEGER,
                          max = Number.MAX_SAFE_INTEGER,
                          onChange = (value, event) => null,
                          disabled = false,
                          readOnly = false,
                      }) => {

    return (
        <Box display={'flex'} alignItems={'center'}>
            <IconButton
                disabled={value <= min || disabled}
                onClick={!readOnly ? e => onChange(value > min ? value - 1 : value, e) : () => null}
                aria-label={'more'}
                variant={'contained'}
                color={'secondary'}>
                <IndeterminateCheckBoxIcon fontSize={'large'}/>
            </IconButton>
            <Box fontSize={'h6.fontSize'} color={disabled ? 'text.disabled' : 'primary.main'}
                 fontFamily={'OpenSansMedium'}>
                {value}
            </Box>
            <IconButton
                disabled={value >= max || disabled}
                onClick={!readOnly ? e => onChange(value < max ? value + 1 : value, e) : () => null}
                aria-label={'less'}
                variant={'contained'}
                color={'secondary'}>
                <AddBoxIcon fontSize={'large'}/>
            </IconButton>
        </Box>
    );
};

NumberPicker.propTypes = {
    value: PropTypes.number,
    onChange: PropTypes.func,
    min: PropTypes.number,
    max: PropTypes.number,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
};


export default NumberPicker;