import React, {createRef, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {SW_FLIGHT_STATUS_DATE_OPTIONS, SW_FLIGHT_STATUS_MODES} from "../../../../store/types";
import {AirportSelector, AutoForm, AzoButton, TabBarUnderlined, TextInput} from "../../../index";
import {t} from 'ttag'
import DateSelect from "./resources/DateSelect";
import HttpClient from 'axios';
import {Grid2, Typography} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

const FlightStatusForm = ({
                              formData = null,
                              loadAirportsFn = () => null,
                              airports = [],
                              airportsLoading = false,
                              onChange = () => null,
                              action = '',
                              method = 'POST',
                              ...props
                          }) => {

    const [destinations, setDestinations] = useState([]);
    const [loadingDestinations, setLoadingDestinations] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const formRef = createRef();

    const {
        mode,
        flightNumber,
        origin,
        destination,
        departure,
        arrival,
        date
    } = formData;

    useEffect(() => {
        if (!airports.length) {
            loadAirportsFn();
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (origin) {
            setLoadingDestinations(true);
            setDestinations([]);
            HttpClient.get(`${action}/destinations/for/${origin}`)
                .then(({data}) => {
                    const iataCodes = Object.values(data).map(market => Object.keys(market)).flat();
                    setDestinations(airports.filter(a => iataCodes.includes(a?.code)));
                })
                .finally(() => {
                    setLoadingDestinations(false);
                });
        }
        // eslint-disable-next-line
    }, [origin]);

    const canSubmit = (() => {
        switch (mode) {
            case SW_FLIGHT_STATUS_MODES.FLIGHT_NUMBER:
                return Boolean(flightNumber && date);
            case SW_FLIGHT_STATUS_MODES.ROUTE:
                return Boolean(origin && destination && date);
            case SW_FLIGHT_STATUS_MODES.DEPARTURE:
                return Boolean(departure && date);
            case SW_FLIGHT_STATUS_MODES.ARRIVAL:
                return Boolean(arrival && date);
            default:
                return false;
        }
    })();

    const kebabCase = (string, separator = '-') => {
        return string.replace(/\W+/g, " ")
            .split(/ |\B(?=[A-Z])/)
            .map(word => word.toLowerCase())
            .join(separator);
    };

    const submitHandler = () => {
        try {
            setSubmitting(true);
            formRef.current.submit();
        } catch (e) {
            setSubmitting(false);
        }
    }

    return (
        <Grid2 spacing={2} container>
            <Grid2 size={{xs: 12}}>
                <TabBarUnderlined
                    selected={mode}
                    onSelect={(e, v) => onChange({mode: v})}
                    tabs={[
                        {label: t`FLIGHT NUMBER`, value: SW_FLIGHT_STATUS_MODES.FLIGHT_NUMBER},
                        {label: t`ROUTE`, value: SW_FLIGHT_STATUS_MODES.ROUTE},
                        {label: t`DEPARTURE`, value: SW_FLIGHT_STATUS_MODES.DEPARTURE},
                        {label: t`ARRIVAL`, value: SW_FLIGHT_STATUS_MODES.ARRIVAL},
                    ]}
                />
            </Grid2>
            <Grid2 size={{xs: 12}}>
                {(() => {
                    switch (mode) {
                        case SW_FLIGHT_STATUS_MODES.FLIGHT_NUMBER:
                            return (
                                <Grid2 spacing={2} container>
                                    <Grid2 size={{xs: 12}}>
                                        <TextInput label={t`Flight number`} placeholder={'Ex. S4220'}
                                                   value={flightNumber}
                                                   onChange={e => onChange({flightNumber: e.target.value})}
                                                   variant={'azo'}
                                                   fullWidth
                                                   shrink
                                        />
                                    </Grid2>
                                    <Grid2 size={{xs: 12}}>
                                        <DateSelect label={t`DATE`}
                                                    value={date}
                                                    onChange={e => onChange({date: e.target.value})}
                                                    hideIcon
                                                    fullWidth
                                                    shrink
                                        />
                                    </Grid2>
                                </Grid2>
                            );
                        case SW_FLIGHT_STATUS_MODES.ROUTE:
                            return (
                                <Grid2 spacing={2} container>
                                    <Grid2 size={{xs: 12, sm: 6}}>
                                        <AirportSelector
                                            onChange={airport => onChange({origin: airport?.code})}
                                            label={t`Origin`}
                                            placeholder={t`Select airport`}
                                            name={'origin'}
                                            options={airports}
                                            variant={'azo'}
                                            loading={airportsLoading}
                                            loadingText={t`Loading...`}
                                            value={origin ? airports.find(a => a.code === origin) : null}
                                        />
                                    </Grid2>
                                    <Grid2 size={{xs: 12, sm: 6}}>
                                        <AirportSelector
                                            onChange={airport => onChange({destination: airport?.code})}
                                            label={t`Destination`}
                                            placeholder={t`Select airport`}
                                            name={'destination'}
                                            options={destinations}
                                            variant={'azo'}
                                            loading={loadingDestinations}
                                            loadingText={t`Loading...`}
                                            value={destination ? destinations.find(a => a.code === destination) : null}
                                        />
                                    </Grid2>
                                    <Grid2 size={{xs: 12}}>
                                        <DateSelect label={t`DATE`}
                                                    value={date}
                                                    onChange={e => onChange({date: e.target.value})}
                                                    hideIcon
                                                    fullWidth
                                                    shrink
                                        />
                                    </Grid2>
                                </Grid2>
                            );
                        case SW_FLIGHT_STATUS_MODES.DEPARTURE:
                            return (
                                <Grid2 spacing={2} container>
                                    <Grid2 size={{xs: 12}}>
                                        <AirportSelector
                                            onChange={airport => onChange({departure: airport?.code})}
                                            label={t`Departure`}
                                            placeholder={t`Select airport`}
                                            name={'departure'}
                                            options={airports}
                                            variant={'azo'}
                                            loading={airportsLoading}
                                            loadingText={t`Loading...`}
                                            value={departure ? airports.find(a => a.code === departure) : null}
                                        />
                                    </Grid2>
                                    <Grid2 size={{xs: 12}}>
                                        <DateSelect label={t`DATE`}
                                                    value={date}
                                                    onChange={e => onChange({date: e.target.value})}
                                                    hideIcon
                                                    fullWidth
                                                    shrink
                                        />
                                    </Grid2>
                                </Grid2>
                            );
                        case SW_FLIGHT_STATUS_MODES.ARRIVAL:
                            return (
                                <Grid2 spacing={2} container>
                                    <Grid2 size={{xs: 12}}>
                                        <AirportSelector
                                            onChange={airport => onChange({arrival: airport?.code})}
                                            label={t`Arrival`}
                                            placeholder={t`Select airport`}
                                            name={'arrival'}
                                            options={airports}
                                            variant={'azo'}
                                            loading={airportsLoading}
                                            loadingText={t`Loading...`}
                                            value={arrival ? airports.find(a => a.code === arrival) : null}
                                        />
                                    </Grid2>
                                    <Grid2 size={{xs: 12}}>
                                        <DateSelect label={t`DATE`}
                                                    value={date}
                                                    onChange={e => onChange({date: e.target.value})}
                                                    hideIcon
                                                    fullWidth
                                                    shrink
                                        />
                                    </Grid2>
                                </Grid2>
                            );
                        default:
                            return null;
                    }
                })()}
            </Grid2>
            <AutoForm
                formRef={formRef}
                formData={formData}
                action={action}
                method={method}
                inputProps={(key) => ({name: key === 'destination' ? 'dst_airport' : kebabCase(key)})}
                disabled={!canSubmit}
            />
            <Grid2 size={{xs: 12}}>
                <AzoButton
                    variant={'contained'}
                    color={'secondary'}
                    size={'large'}
                    disableElevation
                    tall
                    fullWidth
                    disabled={!canSubmit || submitting}
                    onClick={submitHandler}
                >
                    {submitting ? <CircularProgress/> : <Typography variant={'h6'}>{t`Search`}</Typography>}
                </AzoButton>
            </Grid2>
        </Grid2>
    );
};

FlightStatusForm.propTypes = {
    formData: PropTypes.shape({
        mode: PropTypes.oneOf(Object.values(SW_FLIGHT_STATUS_MODES)),
        origin: PropTypes.string,
        destination: PropTypes.string,
        departure: PropTypes.string,
        arrival: PropTypes.string,
        date: PropTypes.oneOf(Object.values(SW_FLIGHT_STATUS_DATE_OPTIONS)),
    }),
    onChange: PropTypes.func,
    airports: PropTypes.arrayOf(AirportSelector.airportShape),
    airportsLoading: PropTypes.bool,
    loadAirportsFn: PropTypes.func,
    action: PropTypes.string,
    method: PropTypes.string,
};

export default FlightStatusForm;