import {useSelector} from 'react-redux';
import {SW_FLIGHT_TYPES, SW_PASSENGER_TYPES} from "../../../../../store/types";
import moment from "moment";
import SETTINGS from "../../../../../config/settings";

const useIsStopoverAvailableConnected = () => {

    const {origin, destination, departure, arrival, flightType, passengerType, promoCode, withMiles} = useSelector(state => state.sw.book);
    const {eligibleCityPairs, availability} = useSelector(state => state.sw.book.stopover);
    const isRoundTrip = flightType === SW_FLIGHT_TYPES.RETURN;
    const isOneWay = flightType === SW_FLIGHT_TYPES.ONE_WAY;
    const eligibleCityPairDefinitions = eligibleCityPairs.find(e => e?.origin === origin?.code && e?.destination === destination?.code) || null;
    const {outboundShift = 0} = eligibleCityPairDefinitions || {};
    const {STOPOVER_MIN_NUMBER_OF_NIGHTS} = SETTINGS.SW;
    const availabilityDataID = `${origin?.code}:${destination?.code}:${departure}:${arrival}:${isRoundTrip}`;

    return (isRoundTrip || isOneWay)
        && passengerType === SW_PASSENGER_TYPES.ADULT
        && !promoCode
        && !withMiles
        && origin
        && destination
        && eligibleCityPairDefinitions
        && departure
        && (isOneWay || (isRoundTrip && arrival))
        && (isOneWay || (isRoundTrip && moment(arrival).diff(moment(departure).add(outboundShift, 'd'), 'd') >= STOPOVER_MIN_NUMBER_OF_NIGHTS))
        && availability
        && availability.id === availabilityDataID
        && (availability.outbound?.PDL?.length || availability.outbound?.TER?.length || availability.inbound?.PDL?.length || availability.inbound?.TER?.length);

};

export default useIsStopoverAvailableConnected;