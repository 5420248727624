import React from 'react';
import PropTypes from 'prop-types';

const AutoForm = ({
                      formData = null,
                      method = 'POST',
                      action = '#',
                      target = '',
                      formRef = null,
                      inputType = 'hidden',
                      inputProps = (k, v) => ({}),
                      filterFn = ([k, v]) => v !== null && v !== undefined,
                      disabled = false,
                      ...props
                  }) => {

    if (disabled) {
        return null;
    }

    return (
        <form method={method}
              action={action}
              target={target}
              ref={formRef}
              {...props}>
            {formData && Object.entries(formData).filter(filterFn).map(([key, value], idx) => (
                <input
                    type={inputType}
                    key={idx}
                    name={key}
                    value={String(value)}
                    {...(typeof inputProps === 'function' ? inputProps(key, value) : inputProps)}
                />
            ))}
        </form>
    );
};

AutoForm.propTypes = {
    formData: PropTypes.object,
    method: PropTypes.string,
    action: PropTypes.string,
    target: PropTypes.string,
    formRef: PropTypes.any,
    inputType: PropTypes.string,
    inputProps: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    filterFn: PropTypes.func,
    disabled: PropTypes.bool,
};

export default AutoForm;