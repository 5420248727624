import React from 'react';
import PropTypes from 'prop-types';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {AzoButton} from "../../../index";
import {Typography, useMediaQuery, useTheme} from "@mui/material";
import useStyles from "./StopoverButtonStyles";

const StopoverButton = ({
                            title = '',
                            subtitle = '',
                            onClick = null,
                            icon = <ArrowForwardIosIcon/>,
                            disabled = false,
                            ...props
                        }) => {

    const {breakpoints} = useTheme();
    const isMobile = useMediaQuery(breakpoints.down('sm'));
    const classes = useStyles();

    return (
        <AzoButton
            className={classes.label}
            onClick={onClick}
            disabled={disabled}
            variant={'contained'}
            color={'secondary'}
            size={'large'}
            endIcon={icon}
            tall
            fullWidth
            {...props}
        >
            {isMobile
                ? <Typography className={classes.title} variant={'subtitle1'}>{title} {subtitle}</Typography>
                : <Typography className={classes.title} variant={'subtitle1'} component={'div'}>{title} <Typography
                    className={classes.title} variant={'subtitle1'}>{subtitle}</Typography></Typography>
            }
        </AzoButton>
    );
};

StopoverButton.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    onClick: PropTypes.func,
    icon: PropTypes.node,
    disabled: PropTypes.bool,
};
export default StopoverButton;