import React from "react";
import {SvgIcon} from "@mui/material";

const OpenWindowIcon = props => (
    <SvgIcon width="44" height="44" viewBox="0 0 44 44" {...props}>
        <path
            d="M1120.146,582.463a4.715,4.715,0,0,1-4.714,4.714H1080.86a4.716,4.716,0,0,1-4.714-4.714V547.891a4.716,4.716,0,0,1,4.714-4.714h34.571a4.715,4.715,0,0,1,4.714,4.714Zm-4.714-36.143H1080.86a1.576,1.576,0,0,0-1.572,1.571v34.572a1.576,1.576,0,0,0,1.572,1.571h34.571a1.576,1.576,0,0,0,1.572-1.571V547.891A1.576,1.576,0,0,0,1115.432,546.32Zm-16.449,31.389a1.181,1.181,0,0,1-1.67,0l-11.3-11.3a1.181,1.181,0,0,1,0-1.67l.679-.677a1.173,1.173,0,0,1,1.678.017l8.1,8.408v-18.7a1.181,1.181,0,0,1,1.178-1.177h.983a1.181,1.181,0,0,1,1.177,1.177v18.69l8.1-8.406a1.18,1.18,0,0,1,1.678-.019l.679.677a1.2,1.2,0,0,1,0,1.68Z"
            transform="translate(-1076.146 -543.177)"
        />
    </SvgIcon>
);

export default OpenWindowIcon;