import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {FlightStatusForm, RealTimeFlightStatus, TabContent} from "../../../index";
import {
    loadFlightStatusRealTimeFlights,
    setSwFlightStatusRealTimeState,
    setSwFlightStatusState
} from "../../../../store/reducers/search-widget";
import SETTINGS from "../../../../config/settings";
import {SW_FLIGHT_STATUS_REAL_TIME_STATUS_TABS} from "../../../../store/types";

const FlightStatusContentConnected = () => {

    const flightStatus = useSelector(state => state.sw.flightStatus);
    const flightStatusAirports = useSelector(state => state.sw.flightStatusAirports);
    const flightStatusAirportsLoading = useSelector(state => state.sw.flightStatusAirportsLoading);
    const realTimeFlightStatus = useSelector(state => state.sw.realTimeFlightStatus);
    const dispatch = useDispatch();

    return (
        <TabContent>
            <FlightStatusForm
                formData={flightStatus}
                airports={flightStatusAirports}
                airportsLoading={flightStatusAirportsLoading}
                // loadAirportsFn={() => dispatch(loadFlightStatusAirports())}
                method={'GET'}
                action={`${SETTINGS.app.API_ENDPOINT}/flight-status`}
                onChange={formData => dispatch(setSwFlightStatusState(formData))}
            />
            <RealTimeFlightStatus
                airports={flightStatusAirports}
                selectedAirport={realTimeFlightStatus.selectedAirport}
                selectedTab={realTimeFlightStatus.selectedTab}
                onTabSelect={selectedTab => dispatch(setSwFlightStatusRealTimeState({selectedTab}))}
                onAirportSelect={selectedAirport => {
                    dispatch(loadFlightStatusRealTimeFlights(selectedAirport));
                    dispatch(setSwFlightStatusRealTimeState({selectedAirport}));
                }}
                // loadRealTimeFlightsFn={() => dispatch(loadFlightStatusRealTimeFlights())}
                flights={
                    realTimeFlightStatus.selectedTab === SW_FLIGHT_STATUS_REAL_TIME_STATUS_TABS.ARRIVALS
                        ? realTimeFlightStatus.arrivalFlights
                        : realTimeFlightStatus.departureFlights
                }
                loading={Boolean(flightStatusAirportsLoading || realTimeFlightStatus.loading)}
            />
        </TabContent>
    );
};

export default FlightStatusContentConnected;
