import React from 'react';
import {LoginForm} from "../../../index";
import {useDispatch, useSelector} from "react-redux";
import {setSwLoginState} from "../../../../store/reducers/search-widget";
import {setAppState, userAuthenticate} from "../../../../store/reducers/app";
import Alert from "@mui/material/Alert";

const LoginFormConnected = () => {

    const formData = useSelector(state => state.sw.login);
    const userLoggedIn = useSelector(state => state.app.userLoggedIn);
    // const wasAuthenticatedOnApp = useSelector(state => state.app.wasAuthenticatedOnApp);
    const needAuthentication = useSelector(state => state.app.needAuthentication);
    const isAuthenticating = useSelector(state => state.app.isAuthenticating);
    const authenticationError = useSelector(state => state.app.authenticationError);

    const dispatch = useDispatch();

    if (userLoggedIn || !needAuthentication) {
        return null;
    }

    const closeHandler = () => dispatch(setAppState({needAuthentication: false}));

    return (
        <LoginForm
            formData={formData}
            onChange={data => dispatch(setSwLoginState(data))}
            onSubmit={data => dispatch(userAuthenticate(data))}
            submitting={isAuthenticating}
            disabled={isAuthenticating}
            fullscreenDialogProps={{closeHandler, disabled: isAuthenticating}}
            helperText={(
                authenticationError && <Alert severity='error' onClose={() => dispatch(setAppState({authenticationError: null}))}>{authenticationError}</Alert>
            )}
            fullscreen
        />
    );
};

export default LoginFormConnected;