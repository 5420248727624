import React from 'react';
import PropTypes from 'prop-types';
import styles from './Month.module.scss';
import {Box} from "@mui/material";

const Month = ({
                   children = null,
                   disabled = false,
                   shy = false,
                   selected = false,
                   toLeftSelection = false,
                   toRightSelection = false,
                   clickHandler = () => null
}) => {

    const monthClasses = [styles.month];
    if (shy || disabled) monthClasses.push(styles.shy);
    if (disabled) monthClasses.push(styles.disabled);
    if (selected) monthClasses.push(styles.selected);
    if (toLeftSelection) monthClasses.push(styles.toLeftSelection);
    if (toRightSelection) monthClasses.push(styles.toRightSelection);

    const monthClickHandler = disabled ? () => null : clickHandler;

    return (
        <Box className={styles.container}>
            <Box className={styles.dummy}/>
            <Box className={monthClasses.join(' ')} onClick={monthClickHandler}>
                <Box className={styles.content}>
                    {children}
                </Box>
            </Box>
        </Box>
    );
};

Month.propTypes = {
    children: PropTypes.any,
    disabled: PropTypes.bool,
    selected: PropTypes.bool,
    shy: PropTypes.bool,
    toLeftSelection: PropTypes.bool,
    toRightSelection: PropTypes.bool,
    clickHandler: PropTypes.func,
};

export default Month;
