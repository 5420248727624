import {useEffect, useState} from 'react';
import {debounce} from "@mui/material";

const useWindowWidth = (debounceDelay = 400) => {

    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        window.addEventListener('resize', debounce(() => setWidth(window.innerWidth), debounceDelay));
        return () => window.removeEventListener('resize', debounce(() => setWidth(window.innerWidth), debounceDelay));
    }, [debounceDelay]);

    return width;
};

export default useWindowWidth;