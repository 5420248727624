import React from 'react';
import {TabContent, WebCheckInForm} from "../../../index";
import {useDispatch, useSelector} from "react-redux";
import {setSwCheckInState} from "../../../../store/reducers/search-widget";
import SETTINGS from "../../../../config/settings";

const WebCheckInConnected = () => {

    const webCheckin = useSelector(state => state.sw.checkIn);
    const currentLanguage = useSelector(state => state.app.currentLanguage);
    const dispatch = useDispatch();

    return (
        <TabContent>
            <WebCheckInForm
                formData={webCheckin}
                onChange={webCheckIn => dispatch(setSwCheckInState(webCheckIn))}
                apiEndpoint={SETTINGS.app.API_ENDPOINT}
                lang={currentLanguage.substring(0, 2)}
            />
        </TabContent>
    );
};

export default WebCheckInConnected;