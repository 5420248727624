import React from 'react';
import {Box, Button, useMediaQuery, useTheme, IconButton} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AzoLogo from "../../../../../resources/images/azo-logo.svg";
import {setSwBookState} from "../../../../../store/reducers/search-widget";
import {setAppState} from "../../../../../store/reducers/app";
import {t} from "ttag";
import {useDispatch, useSelector} from "react-redux";

const BookContentHeaderConnected = () => {

    const {breakpoints} = useTheme();
    const isMobile = useMediaQuery(breakpoints.down('sm'));
    const wasAuthenticatedOnApp = useSelector(state => state.app.wasAuthenticatedOnApp);
    const dispatch = useDispatch();


    const closeHandler = () => {
        if (wasAuthenticatedOnApp) {
            dispatch(setAppState({loading: true}));
            return window.location.reload(true);
        }
        dispatch(setSwBookState({fullscreen: false}));
    }

    return (
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} width={1}>
            <img src={AzoLogo} alt={'Azo logo'} style={{maxWidth: isMobile ? '80%' : '100%'}}/>
            <Box display={'flex'} color={'secondary.light'} justifyContent='flex-end'>
                {!isMobile ? (
                    <Button
                        startIcon={<CloseIcon/>}
                        onClick={closeHandler}
                        size={'large'}
                        style={{fontWeight: 'bold'}}
                        color={'inherit'}
                        aria-label={'close'}>
                        {t`Close`}
                    </Button>
                ) : (
                    <IconButton
                        onClick={closeHandler}
                        size={'medium'}
                        color={'inherit'}
                        aria-label={'close'}>
                        <CloseIcon/>
                    </IconButton>
                )}
            </Box>
        </Box>
    );
};

export default BookContentHeaderConnected;