import React from 'react';
import {useSelector} from "react-redux";
import {Box, Grid2} from '@mui/material';
import Container from "@mui/material/Container";
import {
    BookContentFlowConnected,
    BookContentHeaderConnected,
    CrossSellingConnected,
    FullscreenDialog,
    LoginFormConnected,
    TabContent
} from '../../../index';

const BookContentConnected = () => {

    const fullscreen = useSelector(state => state.sw.book.fullscreen);
    const backgroundImage = useSelector(state => state.sw.book.backgroundImage);
    /*const origin = useSelector(state => state.sw.book.origin);
    const destination = useSelector(state => state.sw.book.destination);
    const dispatch = useDispatch();*/

    if (!fullscreen) {
        return (
            <TabContent>
                <Box mb={2}>
                    <CrossSellingConnected />
                </Box>
                <Box mb={2}>
                    <Container maxWidth={'xl'} disableGutters>
                        <Box color={'common.black'}>
                            <BookContentFlowConnected/>
                        </Box>
                    </Container>
                </Box>
                {/*<Box mb={2}>*/}
                {/*    <RouteSelectorConnected/>*/}
                {/*</Box>*/}
                {/*<AzoButton*/}
                {/*    variant={'contained'}*/}
                {/*    color={'secondary'}*/}
                {/*    size={'large'}*/}
                {/*    onClick={() => dispatch(setSwBookState({fullscreen: true}))}*/}
                {/*    disabled={Boolean(!origin && !destination)}*/}
                {/*    disableElevation*/}
                {/*    fullWidth*/}
                {/*    tall>*/}
                {/*    <Typography variant={'h6'}>{t`Next`}</Typography>*/}
                {/*</AzoButton>*/}
            </TabContent>
        );
    }

    return (
        <FullscreenDialog
            className={'search-widget-v4-portal'}
            PaperProps={{style: {backgroundColor: 'rgba(0, 40, 87, .3)'}}}
            style={{
                backgroundColor: '#002857',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                ...(backgroundImage ? {backgroundImage: `url('${backgroundImage}')`} : {})
            }}
        >
            <LoginFormConnected />
            <Box p={{xs: 1, sm: 2, md: 5}}>
                <Container maxWidth={'xl'}>
                    <Grid2 container spacing={3}>
                        <BookContentHeaderConnected/>
                    </Grid2>
                    <Box mt={{xs: 3, sm: 3, md: 5}} color={'common.white'}>
                        <BookContentFlowConnected/>
                    </Box>
                </Container>
            </Box>
        </FullscreenDialog>
    );
};

export default BookContentConnected;