import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Box, Grid2, InputAdornment, Typography,} from '@mui/material';
import axios from "axios";
import useWebCheckInFormValidation from "./useWebCheckInFormValidation";
import {AutoForm, AzoButton, CheckBox, HelperIconInfo, TextInput} from "../../index";
import {t} from 'ttag';
import useStyles from './WebCheckInFormStyles';
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
const WebCheckInForm = ({
                            formData = null,
                            formDataValidator = {},
                            needValidation = false,
                            onChange = () => null,
                            disabled = false,
                            lang = '',
                            apiEndpoint = '/',
                            reservationCodeMaxChars = 6,
                        }) => {

    const classes = useStyles();

    const ref = useRef();
    const [data, setData] = useState({...formData});
    const [formAction, setFormAction] = useState(null);
    const [submitting, setSubmitting] = useState(false);
    const [submitError, setSubmitError] = useState(null);

    const {validator, isFormDataValid} = useWebCheckInFormValidation(formDataValidator);


    useEffect(() => {
        setData(formData);
    }, [setData, formData]);

    const handleDataChange = value => {
        const newData = {...data, ...value};
        setData({...newData});
        onChange({...newData});
    };

    const canSubmit = !disabled && !submitting && isFormDataValid(data);

    const submitHandler = () => {
        if (!isFormDataValid(data)) {
            return setData({...data, needValidation: true});
        }

        const PID = process.env.REACT_APP_SW_WEBCHECKIN_PLUSGRADE_PID;
        const APIKEY = process.env.REACT_APP_SW_WEBCHECKIN_PLUSGRADE_APIKEY;

        setFormAction(null);
        setSubmitError(null);
        setSubmitting(true);

        // if (true) {
        axios.get(`${apiEndpoint}/webcheckinurl/${data.pnr}/${data.lastName}/${lang}`)
            .then(response => {
                if (response?.data?.url) {
                    let action = response.data.url;
                    if (response.data.url.includes('/S4/')) {
                        action = `https://upg.plusgrade.com/offers/partner/${PID}/pnr/${data.pnr}/last-name/${data.lastName}/api-key/${APIKEY}?lang=${lang}&callbackUrl=${encodeURIComponent(response.data.url)}`;
                    }
                    window.location.href = action;
                    //setFormAction(action);
                    //ref.current.submit();
                } else {
                    setSubmitError(response.data.errorMessage);
                }
            })
            .catch(error => {
                setSubmitError(error.message);
            })
            .finally(() => {
                setSubmitting(false);
            });
        /*} else {
            //Always S4 for PDT environment only
            const webCheckInURL = `https://pdt.checkin.amadeus.net/static/PDT/S4/#/checkindirect?ln=${lang === 'pt' ? 'pt_PT' : 'en_US'}&IFormOfIdentification=PNR&ISurname=${data.lastName}&IIdentification=${data.pnr}`;
            const plusGradeIntegration = `https://upg.stage.plusgrade.com/offers/partner/${PID}/pnr/${data.pnr}/last-name/${data.lastName}/api-key/${APIKEY}?lang=${lang}&callbackUrl=${webCheckInURL}`
            setFormAction(plusGradeIntegration);
            ref.current.submit();
            setSubmitting(false);
        }*/
    };

    return (
        <Box>
            <AutoForm
                action={formAction}
                method={'GET'}
                formData={{}}
                formRef={ref}
            />
            <Grid2 spacing={2} container>
                <Grid2 size={{xs: 12}}>
                    <Typography variant={'subtitle1'} gutterBottom color={'primary'}>
                        {t`Insert booking information:`}
                    </Typography>
                </Grid2>
                <Grid2 size={{xs: 12}}>
                    <TextInput
                        label={t`BOOKING CODE`}
                        placeholder={t`Booking Code`}
                        value={data.pnr}
                        onChange={({target: {value}}) => handleDataChange({pnr: value.replace(' ', '').substring(0, reservationCodeMaxChars)})}
                        error={needValidation && !!validator.pnr(data)}
                        helperText={needValidation && validator.pnr(data, validator)?.join(' ')}
                        variant={'azo'}
                        disabled={disabled}
                        fullWidth
                        style={{zIndex: 0}}
                        shrink

                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <HelperIconInfo
                                        title={t`What is a booking code?`}
                                        subtitle={t`It's an alphanumeric code with 6 characters.`}
                                        heading={t`You can find it in your electronic ticket in "Booking reference".`}
                                        placement={'left'}
                                    />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid2>
                <Grid2 size={{xs: 12}}>
                    <TextInput
                        label={t`LAST NAME`}
                        placeholder={t`Last Name`}
                        value={data.lastName}
                        onChange={({target: {value}}) => handleDataChange({lastName: value})}
                        error={needValidation && !!validator.lastName(data)}
                        helperText={needValidation && validator.lastName(data, validator)?.join(' ')}
                        variant={'azo'}
                        disabled={disabled}
                        fullWidth
                        shrink
                    />
                </Grid2>
                <Grid2 size={{xs: 12}}>
                    <CheckBox
                        label={(
                            <Box lineHeight={'1.3em'} fontSize={'button.fontSize'} color={'primary.main'}
                                 className={classes.checkboxLabel}>
                                {t`I read and accept the`} <a
                                href={t`/before-boarding/check-in`}>{t`conditions`}</a> {t`and took notice of the`} <a
                                href={t`/information/prohibited-articles`}>{t`forbidden goods`}</a> {t`for transportation.`}
                            </Box>
                        )}
                        ariaLabel={t`Conditions`}
                        checked={data.conditions === true}
                        onChange={e => handleDataChange({conditions: Boolean(e.target.checked)})}
                        disabled={disabled}
                        error={needValidation && !!validator.conditions(data)}
                        helperText={needValidation && validator.conditions(data, validator)?.join(' ')}
                        color={'primary'}
                        formControlLabelProps={{className: classes.formControlLabel}}
                    />
                </Grid2>
                {submitError && (
                    <Grid2 size={{xs: 12}}>
                        <Alert severity="error" onClose={() => setSubmitError(null)}>{submitError}</Alert>
                    </Grid2>
                )}
                <Grid2 size={{xs: 12}}>
                    <AzoButton
                        onClick={submitHandler}
                        disabled={!canSubmit}
                        variant={'contained'}
                        color={'secondary'}
                        size={'large'}
                        disableElevation
                        fullWidth
                        tall>
                        {submitting ? <CircularProgress/> : <Typography variant={'h6'}>{t`Next`}</Typography>}
                    </AzoButton>
                </Grid2>
            </Grid2>
        </Box>
    );
};

WebCheckInForm.propTypes = {
    formData: PropTypes.shape({
        pnr: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        conditions: PropTypes.bool.isRequired,
    }),
    formDataValidator: PropTypes.object,
    needValidation: PropTypes.bool,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    lang: PropTypes.string,
    apiEndpoint: PropTypes.string,
    reservationCodeMaxChars: PropTypes.number,
};

export default WebCheckInForm;
