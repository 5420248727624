import {makeStyles} from "@mui/styles";

const withStyles = makeStyles(theme => ({
    links: {
        '& a:link': {
            color: theme.palette.secondary.main,
            textDecoration: 'none',
        },
        '& a:visited': {
            color: theme.palette.secondary.main,
            textDecoration: 'none',
        },
        '& a:hover': {
            color: theme.palette.secondary.main,
            textDecoration: 'underline',
        }
    },
}));

export default withStyles;