import React from 'react';
import Alert from "@mui/material/Alert";
import {useDispatch, useSelector} from "react-redux";
import {setSwBookState} from "../../../../../store/reducers/search-widget";
import {Grid2, useMediaQuery, useTheme, Snackbar} from "@mui/material";

const BookAlertConnected = () => {

    const {breakpoints} = useTheme();
    const isMobile = useMediaQuery(breakpoints.down('sm'));
    const multiCityAlertMessage = useSelector(state => state.sw.book.multiCityAlertMessage);
    const dispatch = useDispatch();

    if (!multiCityAlertMessage) return null;

    if (isMobile) {
        return (
            <Snackbar autoHideDuration={6000} anchorOrigin={{vertical: 'top', horizontal: 'right'}} onClose={() => dispatch(setSwBookState({multiCityAlertMessage: null}))} open>
                <Alert onClose={() => dispatch(setSwBookState({multiCityAlertMessage: null}))} {...multiCityAlertMessage}/>
            </Snackbar>
        );
    }

    return (
        <Grid2  size={{xs: 12}}>
            <Alert onClose={() => dispatch(setSwBookState({multiCityAlertMessage: null}))} {...multiCityAlertMessage}/>
        </Grid2>
    );
};

export default BookAlertConnected;