import he from 'he';
export const EXCEPTION_WORDS_ON_FILTER = ['airport', 'aeroporto', 'international', 'internacional'];

export const decodeHtml = html => {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
};

export const initializeAirports = (airports = []) => {

    if (!Array.isArray(airports)) {
        return [];
    }
    for (let aIdx in airports) {
        const a = airports[aIdx];
        for (let pIdx in a) {
            const p = a[pIdx];
            if (p && (typeof p === 'string')) {
                airports[aIdx][pIdx] = he.decode(p);
            }
        }
    }
    return airports
        .map(airport => { //OSP
            const inAzores = airport.azores;
            const inPortugal = airport.countryName === 'Portugal';
            const destinationsOSP = airport.destinations.filter(code => {
                const destAirport = [...airports].filter(apt => apt.code === code)[0];
                return (destAirport && ((inAzores && destAirport.countryName === 'Portugal') || (inPortugal && destAirport.azores)));
            });

            return {...airport, value: airport, label: `${airport.name}  ${airport.code}`, destinationsOSP};
        })
        .sort((a, b) => a.name - b.name);
};

export const replacements = (target, find, replacements) => {
    if (!target) return target;
    if (typeof find === 'string') find = [find];
    if (typeof replacements === 'string') replacements = find.map(() => replacements);
    if (!Array.isArray(find) || !Array.isArray(replacements)) return target;
    if (!replacements.length) return target;

    for (let i = 0; i < find.length; i++) {
        const regex = new RegExp(find[i], "g");
        target = target.replace(regex, replacements.hasOwnProperty(i) ? replacements[i] : replacements[replacements.length - 1]);
    }

    return target;
};

export const filterAirports = (option, keyword = null, exceptionWords = EXCEPTION_WORDS_ON_FILTER) => {
    if (option === null || keyword === null) {
        return true;
    }

    //const ew = new RegExp(`(${exceptionWords.join('|')})`);
    keyword = keyword.toLowerCase();
    return (option.hasOwnProperty('value') && typeof option.value === 'string'
        && replacements(option.value.toLowerCase(), exceptionWords, '').includes(keyword))
        || (option.hasOwnProperty('code') && replacements(option.code.toLowerCase(), exceptionWords, '').startsWith(keyword))
        || (option.hasOwnProperty('code') && replacements(option.code.toLowerCase(), exceptionWords, '').includes(keyword))
        || (option.hasOwnProperty('name') && replacements(option.name.toLowerCase(), exceptionWords, '').split(' ')
            .some(w => w.startsWith(keyword)))
        || (option.hasOwnProperty('name') && replacements(option.name.toLowerCase(), exceptionWords, '').includes(keyword))
        || (option.hasOwnProperty('cityName') && replacements(option.cityName.toLowerCase(), exceptionWords, '').includes(keyword))
        || (option.hasOwnProperty('countryName') && replacements(option.countryName.toLowerCase(), exceptionWords, '').includes(keyword))
        || (option.hasOwnProperty('regionName') && replacements(option.regionName.toLowerCase(), exceptionWords, '').includes(keyword))
        || (option === keyword);
};

export const sortAirports = (
    list,
    keyword = null,
    sortByProvidedKey = null,
    exceptionWords = EXCEPTION_WORDS_ON_FILTER,
    options = {
        code: ['startsWith'],
        name: ['innerStartsWith', 'includes'],
        cityName: ['includes'],
        regionName: ['includes'],
        countryName: ['includes']
    },
) => {
    if (!Array.isArray(list) || !keyword || keyword === '') return list;//options;

    //const ew = new RegExp(`(${exceptionWords.join('|')})`);
    keyword = keyword.toLowerCase();

    const sortByProvidedKeyFn = (a, b) => {
        if (a && b && a.hasOwnProperty(sortByProvidedKey) && b.hasOwnProperty(sortByProvidedKey)) {
            return replacements(a[sortByProvidedKey].toLowerCase(), exceptionWords, '') - replacements(b[sortByProvidedKey].toLowerCase(), exceptionWords, '');
        }

        return a - b;
    };

    let opts = sortByProvidedKey ? [...list].sort(sortByProvidedKeyFn) : [...list];

    let resultOps = [];
    for (let prop of Object.keys(options)) {
        for (let rule of options[prop]) {
            // eslint-disable-next-line
            opts = opts.filter(o => !resultOps.includes(o));
            switch (rule) {
                case 'includes':
                    resultOps = [...resultOps, ...[...opts].filter(
                        opt => replacements(opt.hasOwnProperty(prop) && opt[prop].toLowerCase(), exceptionWords, '').includes(keyword)
                    )];
                    break;
                case 'startsWith':
                    resultOps = [...resultOps, ...[...opts].filter(
                        opt => replacements(opt.hasOwnProperty(prop) && opt[prop].toLowerCase(), exceptionWords, '').startsWith(keyword)
                    )];
                    break;
                case 'innerStartsWith':
                    resultOps = [...resultOps, ...[...opts].filter(
                        opt => replacements(opt.hasOwnProperty(prop) && opt[prop].toLowerCase(), exceptionWords, '').split(' ')
                            .some(w => w.startsWith(keyword))
                    )];
                    break;
                default:
                    break;
            }
        }
    }

    return resultOps;
};