import React from 'react';
import PropTypes from 'prop-types';
import {Box, Typography} from "@mui/material";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

const MultiCityLegTitle = ({title = null, icon = <DragIndicatorIcon cursor={'grab'}/>}) => {

    return (
        <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
            <Typography variant={'h6'}>
                {title}
            </Typography>
            {icon}
        </Box>
    );
};

MultiCityLegTitle.propTypes = {
    title: PropTypes.node,
    icon: PropTypes.node,
};

export default MultiCityLegTitle;