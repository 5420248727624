import {t} from 'ttag';

export const toNull = c => !c ? null : c;

const usePromoCodeInputValidation = (validatorOverride = {}) => {

    const validator = {
        promoCode: ({promoCode: s}) => toNull(!/^[A-Za-z0-9]{0,10}$/g.test(s)) && [t`Invalid promo code`],
        ...validatorOverride
    };

    const isFormDataValid = (formData = {}, formDataValidator = {}) => {
        const dataValidator = {...validator, ...formDataValidator};
        return Object.entries(dataValidator).every(([validatorName, validator]) => {
            return typeof validator === 'function' ? !validator(formData, dataValidator) : !validator;
        });
    };

    return {validator, isFormDataValid, toNull};
};

export default usePromoCodeInputValidation;