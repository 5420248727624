import {makeStyles} from "@mui/styles";

const withStyles = makeStyles((theme) => ({
    container: compact => ({
        display: 'flex',
        flexDirection: compact ? 'column' : 'row',
    }),
    btnStartIcon: compact => (compact ? {
        margin: 0,
    } : {}),
    firstBtn: compact => (compact ? {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
    } : {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
    }),
    middleBtn: {
        borderRadius: 0,
    },
    lastBtn: compact => (compact ? {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
    } : {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
    }),
}));

export default withStyles;