import React from 'react';
import PropTypes from 'prop-types';
import {Box} from "@mui/material";

const TabContent = ({children = null}) => {

    return (
        <Box bgcolor={'common.white'} p={{ xs: 2, sm: 2, md: 2 }} borderRadius={'0 0 5px 5px'}>
            {children}
        </Box>
    );
};

TabContent.propTypes = {
    children: PropTypes.any,
};


export default TabContent;