import {alpha} from "@mui/material";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: '1px',
        borderTop: `1px solid ${theme.palette.secondary.main}`,
        borderBottomLeftRadius: '5px',
        borderBottomRightRadius: '5px',
    },
    inputRoot: {
        padding: '0!important',
    },
    input: {
        padding: '30px 45px 14px 14px!important',
    },
    inputFlatted: {
        padding: '1rem!important'
    },
    popupIndicator: {
        right: theme.spacing(1),
    },
    listItem: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        fontFamily: 'OpenSansMedium',
    },
    listItemCode: {
        fontFamily: 'monospace',
        fontWeight: 'bolder',
        color: alpha(theme.palette.primary.main, .5)
    },
    option: {
        '&[data-focus="true"]': {
            backgroundColor: alpha(theme.palette.secondary.main, .1),
        },
        '&[aria-selected="true"]': {
            backgroundColor: alpha(theme.palette.secondary.main, .15),
        },
    }
}));

export default useStyles;