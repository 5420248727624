import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styles from "./DatePicker.module.scss";
import moment from "moment";
import Years from "./Years/Years";
import Months from "./Months/Months";
import Calendar from "./Calendar/Calendar";
import Navigator from "./Controls/Navigator";
import SwipeableViews from 'react-swipeable-views';
import {virtualize} from 'react-swipeable-views-utils';
import debounce from 'debounce';
import {Box} from "@mui/material";

const VirtualizedSwipeableViews = virtualize(SwipeableViews);

class DatePicker extends Component {

    static EDIT_OPTIONS = {
        DAYS: 'days',
        MONTHS: 'months',
        YEARS: 'years',
    };

    constructor(props) {
        super(props);

        const defaultProps = {
            disableBuiltInSwipe: false,
            enableMouseSwipe: false,
            isOpen: true,
            monthOverflowDaysVisible: true,
            acceptKeyboardControls: false,
            isRangePicker: false,
            isMonthPicker: false,
            isYearPicker: false,
            abstractToMonths: true,
            abstractToDecades: true,
            pivot: moment(),
            start: null,
            end: null,
            selectedDateHandler: () => null,
            selectedRangeHandler: () => null,
            calendars: 1,
            navigable: true,
            noNavigation: false,
            mobile: false,
            disabled: false,
            pastDisabled: false,
            todayDisabled: false,
            filterDaysToDisableHandler: () => false,
            availableRange: null,
            overrideDayRender: null,
            t: str => str
        };

        this.props = props;

        this.state = {
            pivot: props.start ? props.start.clone() : props.pivot ? props.pivot.clone() : defaultProps.pivot.clone(),
            keyAnchor: props.start ? props.start.clone() : props.pivot ? props.pivot.clone() : defaultProps.pivot.clone(),
            start: props.start ? props.start.clone() : null,
            end: props.end ? props.end.clone() : null,
            selectingRange: null,
            selecting: false,
            canSwipe: true,
            slideIndex: 0,
            editType: props.isYearPicker
                ? DatePicker.EDIT_OPTIONS.YEARS
                : props.isMonthPicker
                    ? DatePicker.EDIT_OPTIONS.MONTHS
                    : DatePicker.EDIT_OPTIONS.DAYS
        }
    }

    componentDidMount() {
        this.mounted = true;
        const {acceptKeyboardControls} = this.props;
        if (acceptKeyboardControls) {
            this.debouncedKeyUpHandler = debounce(this.keyUpHandler, 25);
            window.addEventListener('keydown', this.debouncedKeyUpHandler);
        }
    }

    componentWillUnmount() {
        if (this.debouncedKeyUpHandler) {
            window.removeEventListener('keydown', this.debouncedKeyUpHandler);
        }
        this.mounted = false;
    }

    keyUpHandler = e => {
        if (this.mounted) {
            this.actionCreator(e);
        }
    };

    actionCreator = e => {
        const {calendars} = this.props;
        const {keyAnchor, pivot} = this.state;
        const updatedAnchor = keyAnchor.clone();
        const calendarsFirstMonth = pivot.clone();
        const calendarsLastMonth = pivot.clone().add(calendars-1, 'month');

        const code = e.code ? e.code : e.key;

        switch (code) {
            case 'Up':
            case 'ArrowUp':
                updatedAnchor.subtract(1, 'week');
                if (updatedAnchor.isBefore(calendarsFirstMonth, 'month')) {
                    if (!this.canNavigateBack()) break;
                    this.navigatePrev();
                }
                this.setState({keyAnchor: updatedAnchor}, () => this.dayHoveringHandler(updatedAnchor));
                break;
            case 'Down':
            case 'ArrowDown':
                updatedAnchor.add(1, 'week');
                if (updatedAnchor.isAfter(calendarsLastMonth, 'month')) {
                    if (!this.canNavigateNext()) break;
                    this.navigateNext();
                }
                this.setState({keyAnchor: updatedAnchor}, () => this.dayHoveringHandler(updatedAnchor));
                break;
            case 'Right':
            case 'ArrowRight':
                updatedAnchor.add(1, 'day');
                if (updatedAnchor.isAfter(calendarsLastMonth, 'month')) {
                    if (!this.canNavigateNext()) break;
                    this.navigateNext();
                }
                this.setState({keyAnchor: updatedAnchor}, () => this.dayHoveringHandler(updatedAnchor));
                break;
            case 'Left':
            case 'ArrowLeft':
                updatedAnchor.subtract(1, 'day');
                if (updatedAnchor.isBefore(calendarsFirstMonth, 'month')) {
                    if (!this.canNavigateBack()) break;
                    this.navigatePrev();
                }
                this.setState({keyAnchor: updatedAnchor}, () => this.dayHoveringHandler(updatedAnchor));
                break;
            case ' ':
            case 'Space':
            case 'NumpadEnter':
            case 'Enter':
                if (!this.isDisabledDate(keyAnchor.clone())) {
                    this.select(keyAnchor.clone());
                }
                break;
            default:
                return;
        }

        e.stopPropagation();
        e.preventDefault();
    };

    isDisabledDate = date => {
        const {disabled, pastDisabled, todayDisabled, filterDaysToDisableHandler, availableRange} = this.props;

        const today = moment();
        const unit = 'day';
        return (disabled ||
            (pastDisabled && date.isBefore(today, unit)) ||
            (todayDisabled && date.isSame(today, unit)) ||
            filterDaysToDisableHandler(date) ||
            (availableRange && (date.isBefore(availableRange.start, unit) || date.isAfter(availableRange.end, unit)))
        );
    };

    navigateNext = () => {
        this.setState(prevState => {
            const {n, unit} = this.getEditTypeNavigationUnits();
            const newPivot = prevState.pivot.clone().add(n, unit);
            return {pivot: newPivot};
        });
    };

    navigatePrev = () => {
        this.setState(prevState => {
            const {n, unit} = this.getEditTypeNavigationUnits();
            const newPivot = prevState.pivot.clone().subtract(n, unit);
            return {pivot: newPivot};
        });
    };

    isValidEditType = editType => {
        return Object.values(DatePicker.EDIT_OPTIONS).indexOf(editType) !== -1;
    };

    isEditTypeAllowed = editType => {
        if (!this.isValidEditType(editType)) {
            return false;
        }

        const {abstractToMonths, abstractToDecades, isMonthPicker, isYearPicker, navigable} = this.props;
        const cannotGoToYears = !abstractToDecades || isYearPicker;
        const cannotGoToMonths = !abstractToMonths || isYearPicker;
        const cannotGoToDays = isYearPicker || isMonthPicker;
        return !((editType === DatePicker.EDIT_OPTIONS.YEARS && cannotGoToYears) ||
            (editType === DatePicker.EDIT_OPTIONS.MONTHS && cannotGoToMonths) ||
            (editType === DatePicker.EDIT_OPTIONS.DAYS && cannotGoToDays)) && navigable;
    };

    switchEditType = editType => {
        if (!this.isEditTypeAllowed(editType)) {
            return;
        }

        this.setState({editType});
    };

    getEditTypeNavigationUnits = () => {
        const {editType} = this.state;
        switch (editType) {
            case DatePicker.EDIT_OPTIONS.DAYS:
                return {n: 1, unit: 'month'};
            case DatePicker.EDIT_OPTIONS.MONTHS:
                return {n: 1, unit: 'year'};
            case DatePicker.EDIT_OPTIONS.YEARS:
                return {n: 10, unit: 'year'};
            default:
                return {n: 1, unit: 'month'};
        }
    };

    canNavigateBack = () => {
        const {editType, pivot, disabled} = this.state;
        const {pastDisabled, availableRange, navigable} = this.props;
        const today = moment();

        if (disabled || !navigable) {
            return false;
        }

        switch (editType) {
            case DatePicker.EDIT_OPTIONS.DAYS:
                return (!pastDisabled || (pastDisabled && pivot.isAfter(today, 'month')))
                    && (!availableRange || (availableRange && pivot.isAfter(availableRange.start, 'month')));
            case DatePicker.EDIT_OPTIONS.MONTHS:
                return (!pastDisabled || (pastDisabled && pivot.isAfter(today, 'year')))
                    && (!availableRange || (availableRange && pivot.isAfter(availableRange.start, 'year')));
            case DatePicker.EDIT_OPTIONS.YEARS:
                return (!pastDisabled || (pastDisabled && pivot.isAfter(today, 'year')))
                    && (!availableRange || (availableRange && pivot.isAfter(availableRange.start, 'year')));//todo 10y
            default:
                return true;
        }
    };

    canNavigateNext = () => {
        const {editType, pivot, disabled} = this.state;
        const {availableRange, navigable} = this.props;

        if (disabled || !navigable) {
            return false;
        }

        switch (editType) {
            case DatePicker.EDIT_OPTIONS.DAYS:
                return availableRange ? pivot.isBefore(availableRange.end, 'month') : true;
            case DatePicker.EDIT_OPTIONS.MONTHS:
                return availableRange ? pivot.isBefore(availableRange.end, 'year') : true;
            case DatePicker.EDIT_OPTIONS.YEARS:
                return availableRange ? pivot.isBefore(availableRange.end, 'year') : true;//todo 10y
            default:
                return true;
        }
    };

    canAbstract = () => {
        const {editType} = this.state;

        const {abstractToMonths, abstractToDecades, navigable} = this.props;

        return ((abstractToMonths && editType === DatePicker.EDIT_OPTIONS.DAYS)
            || (abstractToDecades && editType === DatePicker.EDIT_OPTIONS.MONTHS)
            || editType === DatePicker.EDIT_OPTIONS.YEARS) && navigable;
    };

    abstractEditType = () => {
        const {editType} = this.state;

        switch (editType) {
            case DatePicker.EDIT_OPTIONS.DAYS:
                return this.switchEditType(DatePicker.EDIT_OPTIONS.MONTHS);
            case DatePicker.EDIT_OPTIONS.MONTHS:
                return this.switchEditType(DatePicker.EDIT_OPTIONS.YEARS);
            default:
                return this.switchEditType(DatePicker.EDIT_OPTIONS.DAYS);
        }
    };

    focusEditType = () => {
        const {editType} = this.state;

        switch (editType) {
            case DatePicker.EDIT_OPTIONS.YEARS:
                return this.switchEditType(DatePicker.EDIT_OPTIONS.MONTHS);
            case DatePicker.EDIT_OPTIONS.MONTHS:
                return this.switchEditType(DatePicker.EDIT_OPTIONS.DAYS);
            default:
                return this.switchEditType(DatePicker.EDIT_OPTIONS.DAYS);
        }
    };

    updatePivotAndFocus = pivot => {
        this.setState({pivot}, () => this.focusEditType());
    };

    select = date => {
        const {selectedDateHandler, selectedRangeHandler, isRangePicker} = this.props;
        const {selecting, start} = this.state;

        if (!isRangePicker) {
            return this.setState(
                {start: date.clone(), end: date.clone(), selecting: false, selectingRange: null},
                () => selectedDateHandler({date: date.clone(), start: date.clone(), end: date.clone()})
            );
        }

        if (!selecting) {
            return this.setState({
                start: date.clone(),
                end: date.clone(),
                selecting: true,
                selectingRange: {
                    start: date.clone(),
                    end: date.clone()
                }
            });
        }

        if (start instanceof moment && date instanceof moment && date.isBefore(start, 'day')) {
            return this.setState(
                {start: date.clone(), end: start.clone(), selecting: false, selectingRange: null},
                () => selectedRangeHandler({start: date.clone(), end: start.clone()})
            );
        }

        return this.setState(
            {end: date.clone(), selecting: false, selectingRange: null},
            () => selectedRangeHandler({start: start.clone(), end: date.clone()})
        );
    };

    dayHoveringHandler = dayHovered => {
        const {selecting, start} = this.state;
        if (start instanceof moment && selecting) {
            if (dayHovered.isBefore(start, 'days')) {
                return this.setState({selectingRange: {start: dayHovered.clone(), end: start.clone()}});
            }

            return this.setState({selectingRange: {start: start.clone(), end: dayHovered.clone()}});
        }
    };

    renderContent = (shift = 0) => {
        const {editType, pivot, start, end, keyAnchor, selectingRange} = this.state;
        const {
            pastDisabled, todayDisabled, disabled, availableRange, isYearPicker, overrideDayRender, monthOverflowDaysVisible,
            isMonthPicker, calendars, filterDaysToDisableHandler, acceptKeyboardControls, mobile, t
        } = this.props;
        const contentClasses = [styles.content];
        if (mobile) contentClasses.push(styles.mobile);

        let jsx = null;
        switch (editType) {
            case DatePicker.EDIT_OPTIONS.YEARS:
                jsx = (
                    <Years pivot={pivot.clone().add(shift*10, 'years')}
                           selectYearHandler={isYearPicker ? this.select : this.updatePivotAndFocus}
                           start={start}
                           end={end}
                           disabled={disabled}
                           pastDisabled={pastDisabled}
                           availableRange={availableRange}
                           t={t}
                    />
                );
                break;
            case DatePicker.EDIT_OPTIONS.MONTHS:
                jsx = (
                    <Months pivot={pivot.clone().add(shift, 'years')}
                            selectMonthHandler={isMonthPicker ? this.select : this.updatePivotAndFocus}
                            start={start}
                            end={end}
                            disabled={disabled}
                            pastDisabled={pastDisabled}
                            availableRange={availableRange}
                            t={t}
                    />
                );
                break;
            case DatePicker.EDIT_OPTIONS.DAYS:
            default:
                jsx = [];

                for (let i = 0; i < calendars; i++) {
                    jsx.push(
                        <Calendar key={`calendar-${i}`}
                                  pivot={pivot.clone().add(i+shift, 'month')}
                                  keyAnchor={acceptKeyboardControls ? keyAnchor : null}
                                  selectDayHandler={this.select}
                                  hoveredDayHandler={this.dayHoveringHandler}
                                  filterDaysToDisableHandler={filterDaysToDisableHandler}
                                  monthOverflowDaysVisible={monthOverflowDaysVisible}
                                  start={start}
                                  end={end}
                                  disabled={disabled}
                                  pastDisabled={pastDisabled}
                                  todayDisabled={todayDisabled}
                                  availableRange={availableRange}
                                  selectingRange={selectingRange}
                                  overrideDayRender={overrideDayRender}
                                  t={t}
                        />
                    );
                }
                break;
        }

        return (
            <Box className={contentClasses.join(' ')}>
                {jsx}
            </Box>
        );
    };

    renderCurrentLabel = (shift = 0) => {
        const {editType, pivot: originalPivot} = this.state;
        // const pivot = originalPivot;
        const pivot = (editType === DatePicker.EDIT_OPTIONS.DAYS && originalPivot.clone().add(shift, 'months'))
            || (editType === DatePicker.EDIT_OPTIONS.MONTHS && originalPivot.clone().add(shift, 'years'))
            || (editType === DatePicker.EDIT_OPTIONS.YEARS && originalPivot.clone().add(shift * 10, 'years'));
        const {calendars, t} = this.props;
        const year = pivot.format('YYYY');
        const decadeStart = year - (year % 10);
        const decadeEnd = year - (year % 10) + 10;
        const decade = decadeStart + ' - ' + decadeEnd;
        const month = t(pivot.format('MMMM'));
        const lastMonthOnCalendars = pivot.clone().add(calendars - 1, 'month');

        switch (editType) {
            case DatePicker.EDIT_OPTIONS.DAYS:
                const months = [];
                for (let m = pivot.clone(); m.isSameOrBefore(lastMonthOnCalendars.clone()); m.add(1, 'month')) {
                    months.push(<Box key={'month-'+m.format('YYYY-MM')}>{t(m.format('MMMM')) + ' ' + m.format('YYYY')}</Box>);
                }
                return months;
            case DatePicker.EDIT_OPTIONS.MONTHS:
                return year;
            case DatePicker.EDIT_OPTIONS.YEARS:
                return decade;
            default:
                return month + ' ' + year;
        }
    };

    renderControls = (shift = 0) => {
        const {noNavigation} = this.props;
        if (noNavigation) {
            return null;
        }

        const labelClasses = [styles.label];
        if (!this.canAbstract()) {
            labelClasses.push(styles.labelDisabled);
        }

        return (
            <Box className={styles.controls}>
                <Navigator clickHandler={this.navigatePrev} disabled={!this.canNavigateBack()}>
                    &#60;
                </Navigator>
                <Box className={labelClasses.join(' ')} onClick={this.abstractEditType}>
                    {this.renderCurrentLabel(shift)}
                </Box>
                <Navigator clickHandler={this.navigateNext} disabled={!this.canNavigateNext()}>
                    &#62;
                </Navigator>
            </Box>
        );

    };

    render() {
        const {children, isOpen} = this.props;

        if (!isOpen) {
            return null;
        }

        const slideRenderer = ({index, key}) => {
            const shift = index - this.state.slideIndex;
            return (
                <Box className={styles.container} key={key}>
                    {this.renderControls(shift)}
                    {this.renderContent(shift)}
                    {children}
                </Box>
            )
        };

        return (
            <VirtualizedSwipeableViews
                index={this.state.slideIndex}
                slideStyle={{overflow: 'hidden'}}
                onChangeIndex={idx => {
                    if (this.canNavigateBack() && idx < this.state.slideIndex) {
                        this.navigatePrev();
                        this.setState({slideIndex: idx});
                    }
                    if (this.canNavigateNext() && idx > this.state.slideIndex) {
                        this.navigateNext();
                        this.setState({slideIndex: idx});
                    }
                }}
                slideRenderer={slideRenderer}
                disabled={this.props.disableBuiltInSwipe}
                enableMouseEvents={this.props.enableMouseSwipe}
                overscanSlideBefore={1}
                overscanSlideAfter={1}
                resistance
            />
        );
    }
}

DatePicker.propTypes = {
    children: PropTypes.any,
    disableBuiltInSwipe: PropTypes.bool,
    enableMouseSwipe: PropTypes.bool,
    isOpen: PropTypes.bool,
    monthOverflowDaysVisible: PropTypes.bool,
    acceptKeyboardControls: PropTypes.bool,
    isRangePicker: PropTypes.bool,
    isMonthPicker: PropTypes.bool,
    isYearPicker: PropTypes.bool,
    abstractToMonths: PropTypes.bool,
    abstractToDecades: PropTypes.bool,
    pivot: PropTypes.instanceOf(moment),
    start: PropTypes.instanceOf(moment),
    end: PropTypes.instanceOf(moment),
    selectedDateHandler: PropTypes.func,
    selectedRangeHandler: PropTypes.func,
    calendars: PropTypes.oneOf([1, 2, 3, 4]),
    navigable: PropTypes.bool,
    noNavigation: PropTypes.bool,
    mobile: PropTypes.bool,
    disabled: PropTypes.bool,
    pastDisabled: PropTypes.bool,
    todayDisabled: PropTypes.bool,
    filterDaysToDisableHandler: PropTypes.func,
    availableRange: PropTypes.shape({
        start: PropTypes.instanceOf(moment),
        end: PropTypes.instanceOf(moment)
    }),
    overrideDayRender: PropTypes.func,
    t: PropTypes.func,
};

export default DatePicker;