import {useSelector} from 'react-redux';
import {SW_FLIGHT_TYPES, SW_PASSENGER_TYPES} from "../../../../../store/types";
import {AIRPORTS_AZORES, AIRPORTS_WITH_RAIL_FLY} from "../../../../../store/reducers/search-widget";
import moment from "moment/moment";

const DATE_RANGES = [
    { start: '2024-04-28', end: '2024-10-24' },
    { start: '2025-03-30', end: '2025-10-22' },
    // Add more ranges as needed
];

const isDateEligible = (date) => {
    const dayOfWeek = date.day();
    return DATE_RANGES.some(range => {
        const startDate = moment(range.start);
        const endDate = moment(range.end);
        return date.isBetween(startDate, endDate, null, '[]') && (dayOfWeek === 0 || dayOfWeek === 4);
    });
};

const useIsRailFlyEligibleConnected = () => {


    const origin = useSelector(state => state.sw.book.origin);
    const destination = useSelector(state => state.sw.book.destination);
    const passengerType = useSelector(state => state.sw.book.passengerType);
    const promoCode = useSelector(state => state.sw.book.promoCode);
    const flightType = useSelector(state => state.sw.book.flightType);
    const withMiles = useSelector(state => state.sw.book.withMiles);
    const isRoundTrip = flightType === SW_FLIGHT_TYPES.RETURN;
    const isOneWay = flightType === SW_FLIGHT_TYPES.ONE_WAY;
    const departureStr = useSelector(state => state.sw.book.departure);
    const arrivalStr = useSelector(state => state.sw.book.arrival);
    const departure = departureStr && moment(departureStr);
    const arrival = arrivalStr && moment(arrivalStr);
    const isEligibleToRailFly = (AIRPORTS_WITH_RAIL_FLY.includes(origin?.code) && AIRPORTS_AZORES.includes(destination?.code)) || (AIRPORTS_WITH_RAIL_FLY.includes(destination?.code) && AIRPORTS_AZORES.includes(origin?.code));

    const isStartDateEligible = !isOneWay ? arrival && isDateEligible(arrival) : true;
    const isEndDateEligible = departure && isDateEligible(departure);

    return Boolean(
        (isRoundTrip || isOneWay)
        && passengerType === SW_PASSENGER_TYPES.ADULT
        && !promoCode
        && !withMiles
        && origin
        && destination
        && isEligibleToRailFly
        && isStartDateEligible
        && isEndDateEligible
    );

};

export default useIsRailFlyEligibleConnected;