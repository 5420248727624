import React from 'react';
import PropTypes from 'prop-types';
import {deprecatedPropType, Dialog} from "@mui/material";

const FullscreenDialog = ({
                              children = null,
                              open = true,
                              closeHandler = null,
                              fullScreen = true,
                              ...props
                          }) => {

    return (
        <Dialog open={open}
                onClose={closeHandler}
                fullScreen={fullScreen}
                TransitionProps={{role: 'presentation'}}
                {...props}>
            {children}
        </Dialog>
    );
};

FullscreenDialog.propTypes = {
    closeHandler: PropTypes.func,
    'aria-describedby': PropTypes.string,
    'aria-labelledby': PropTypes.string,
    BackdropProps: PropTypes.object,
    children: PropTypes.node,
    classes: PropTypes.object,
    className: PropTypes.string,
    disableBackdropClick: PropTypes.bool,
    disableEscapeKeyDown: PropTypes.bool,
    fullScreen: PropTypes.bool,
    fullWidth: PropTypes.bool,
    maxWidth: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs', false]),
    onBackdropClick: PropTypes.func,
    onClose: PropTypes.func,
    onEnter: deprecatedPropType(PropTypes.func, 'Use the `TransitionProps` property instead.'),
    onEntered: deprecatedPropType(PropTypes.func, 'Use the `TransitionProps` property instead.'),
    onEntering: deprecatedPropType(PropTypes.func, 'Use the `TransitionProps` property instead.'),
    onEscapeKeyDown: PropTypes.func,
    onExit: deprecatedPropType(PropTypes.func, 'Use the `TransitionProps` property instead.'),
    onExited: deprecatedPropType(PropTypes.func, 'Use the `TransitionProps` property instead.'),
    onExiting: deprecatedPropType(PropTypes.func, 'Use the `TransitionProps` property instead.'),
    open: PropTypes.bool,
    PaperComponent: PropTypes.elementType,
    PaperProps: PropTypes.object,
    scroll: PropTypes.oneOf(['body', 'paper']),
    TransitionComponent: PropTypes.elementType,
    transitionDuration: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.shape({
            appear: PropTypes.number,
            enter: PropTypes.number,
            exit: PropTypes.number,
        }),
    ]),
    TransitionProps: PropTypes.object,
};

export default FullscreenDialog;