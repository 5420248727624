import React from 'react';
import PropTypes from 'prop-types';
import {Radio, FormControl, FormControlLabel, FormHelperText} from '@mui/material';
import useStyles from './RadioButtonStyles';


const RadioButton = ({
                         label = '',
                         ariaLabel = '',
                         labelPlacement = 'end',
                         formControlLabelValue = '',
                         radioValue = '',
                         checked = false,
                         disabled = false,
                         onChange = () => null,
                         radioInputProps = {},
                         helperText = '',
                         ...props
                     }) => {

    const classes = useStyles();

    return (
        <FormControl disabled={disabled}>
            <FormControlLabel
                disabled={disabled}
                onChange={onChange}
                control={
                    <Radio
                        role={'radio'}
                        aria-label={ariaLabel || props.ariaLabel}
                        aria-labelledby={ariaLabel || props.ariaLabel}
                        value={radioValue}
                        checked={checked}
                        disabled={disabled}
                        color={'secondary'}
                        inputProps={{
                            ...radioInputProps,
                            'aria-label': ariaLabel || props.ariaLabel,
                            'aria-labelledby': ariaLabel || props.ariaLabel,
                            'role': 'radio',
                        }}
                        classes={classes}
                        {...props}
                    />
                }
                value={formControlLabelValue}
                label={label}
                labelPlacement={labelPlacement}
            />
            {helperText && (
                <FormHelperText>
                    {helperText}
                </FormHelperText>
            )}
        </FormControl>
    );
};

RadioButton.propTypes = {
    label: PropTypes.node,
    ariaLabel: PropTypes.string,
    labelPlacement: PropTypes.oneOf(['bottom', 'top', 'start', 'end']),
    formControlLabelValue: PropTypes.any,
    radioValue: PropTypes.any,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    radioInputProps: PropTypes.object,
    helperText: PropTypes.node,
};

export default RadioButton;