import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Grid2, useMediaQuery, useTheme} from "@mui/material";
import {AddRemoveFlightButtons, DatePickerInput, MultiCityLegTitle, RouteSelector} from "../../../../index";
import {crudMultiCityLeg, setSwState, validateMultiCityLegs} from "../../../../../store/reducers/search-widget";
import {t} from "ttag";
import {COMPONENTS, MULTI_REFS} from "../../common/book-form-input-refs";
import moment from "moment";

const BookMultiCityLegsConnected = () => {
    const {breakpoints} = useTheme();
    const isMobile = useMediaQuery(breakpoints.down('sm'));
    const isTablet = useMediaQuery(breakpoints.down('md'));
    const legs = useSelector(state => state.sw.book.multiCityLegs);
    const multiCityIsValid = useSelector(state => state.sw.book.multiCityIsValid);
    const allAirports = useSelector(state => state.sw.allAirports);
    const focusedComponent = useSelector(state => state.sw.focusedComponent);
    const dispatch = useDispatch();
    let lastDeparture = null;

    return legs.map((leg, idx) => {
        const flightNumber = idx + 1;
        const containerDepartureRef = MULTI_REFS[`MULTI_LEG_${idx}_DEPARTURE_REF`];
        const routeRef = COMPONENTS[`MULTI_LEG_${idx}_ROUTE_SELECTOR_REF`];
        const departureRef = COMPONENTS[`MULTI_LEG_${idx}_DEPARTURE_REF`];
        // const prevRef = idx > 0 ? COMPONENTS[`MULTI_LEG_${idx - 1}_DEPARTURE_REF`] : COMPONENTS.NONE;
        const nextRef = idx < legs.length - 1 ? COMPONENTS[`MULTI_LEG_${idx + 1}_ROUTE_SELECTOR_REF`] : COMPONENTS.NONE;
        const departure = leg.departure && moment(leg.departure);
        const localLastDeparture = lastDeparture;
        if (departure) lastDeparture = departure.format('YYYY-MM-DD');

        return [
            isTablet && (
                <Grid2 key={`title${idx}`}  size={{xs: 12}}>
                    <MultiCityLegTitle title={t`Flight ${flightNumber}`} icon={null}/>
                </Grid2>
            ),
            <Grid2 key={`route${idx}`}  size={{xs: 12}} >
                <RouteSelector
                    airportsData={allAirports}
                    onChange={cityPair => {
                        const alreadyBeenModified = Boolean(leg.routeWasModified);
                        dispatch(crudMultiCityLeg({idx, leg: {origin: cityPair?.origin, destination: cityPair?.destination, routeWasModified: true}}));
                        dispatch(validateMultiCityLegs());
                        dispatch(setSwState({focusedComponent: !alreadyBeenModified ? departureRef : COMPONENTS.NONE}));
                        // dispatch(syncMainLegBetweenFlows({toCommonFlow: true}));
                    }}
                    onFocus={() => dispatch(setSwState({focusedComponent: routeRef}))}
                    onBlur={() => focusedComponent === routeRef && dispatch(setSwState({focusedComponent: COMPONENTS.NONE}))}
                    originFocus={focusedComponent === routeRef}
                    origin={leg.origin}
                    destination={leg.destination}
                    variant={isMobile ? 'stacked' : 'normal'}
                    originPlaceholder={t`Departure from...`}
                    destinationPlaceholder={t`Where do you want to go?`}
                    error={leg.duplicatedCityPairError}
                />
            </Grid2>,
            <Grid2 key={`departure${idx}`} size={{xs: 12, lg: 10}}>
                <DatePickerInput
                    label={t`DEPARTURE DATE`}
                    placeholder={t`Departure Date`}
                    date={departure}
                    containerRef={containerDepartureRef}
                    onFocus={() => dispatch(setSwState({focusedComponent: departureRef}))}
                    onBlur={() => focusedComponent === departureRef && dispatch(setSwState({focusedComponent: COMPONENTS.NONE}))}
                    open={focusedComponent === departureRef}
                    onChange={({date}) => {
                        const alreadyBeenModified = Boolean(leg.departureWasModified);
                        dispatch(crudMultiCityLeg({idx, leg: {departure: date.format('YYYY-MM-DD'), departureWasModified: true}}));
                        dispatch(validateMultiCityLegs());
                        dispatch(setSwState({focusedComponent: !alreadyBeenModified ? nextRef : COMPONENTS.NONE}));
                        // dispatch(syncMainLegBetweenFlows({toCommonFlow: true}));
                    }}
                    DatePickerProps={{
                        pivot: (departure ? moment(departure) : (localLastDeparture ? moment(localLastDeparture).add(1, 'd') : undefined)),
                    }}
                    DropInputProps={{TextInputProps: {shrink: true}}}
                    error={Boolean(leg.inconsistentDatesError)}
                />
            </Grid2>,
            <Grid2 key={`controls${idx}`} display={'grid'} alignItems={'center'} size={{xs: 12, lg: 2}} >
                <AddRemoveFlightButtons
                    addBtnClickHandler={() => {
                        dispatch(crudMultiCityLeg({
                            leg: {origin: null, destination: null, departure: null},
                            atIdx: idx + 1
                        }));
                        dispatch(validateMultiCityLegs());
                    }}
                    removeBtnClickHandler={() => {
                        dispatch(crudMultiCityLeg({idx}));
                        dispatch(validateMultiCityLegs());
                    }}
                    addBtnDisabled={legs.length >= 4 || !multiCityIsValid}
                    removeBtnDisabled={legs.length <= 2}
                    addBtnChildren={t`Add Flight`}
                    removeBtnChildren={t`Remove Flight`}
                />
            </Grid2>
        ];
    });
};

export default BookMultiCityLegsConnected;