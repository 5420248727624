import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Grid2, Typography} from "@mui/material";
import {t} from "ttag";
import {AzoButton, TextInput} from "../../index";
import useLoginFormValidation from "./useLoginFormValidation";
import LoginFullscreenWrapper from './resources/LoginFullscreenWrapper';
import CircularProgress from "@mui/material/CircularProgress";
import useStyles from './LoginFormStyles';


const LoginForm = ({
                       formData = null,
                       formDataValidator = {},
                       onChange = () => null,
                       onSubmit = () => null,
                       needValidation = false,
                       disabled = false,
                       helperText = null,
                       fullscreen = false,
                       submitting = false,
                       fullscreenDialogProps = {}
                   }) => {
    const classes = useStyles();

    const [data, setData] = useState({...formData});
    const {validator, isFormDataValid} = useLoginFormValidation(formDataValidator);

    useEffect(() => {
        setData(formData)
    }, [setData, formData]);

    const handleDataChange = value => {
        const newData = ({...data, ...value});
        setData({...newData});
        onChange({...newData});
    };

    const canSubmit = !disabled && !submitting && isFormDataValid(data);

    const submitHandler = () => {
        if (!isFormDataValid(data)) {
            return setData({...data, needValidation: true});
        }

        onSubmit({...data});
    };

    const form = (
        <form>
            <Grid2 spacing={3} container>
                <Grid2 size={{xs: 12}}>
                    <br/>
                </Grid2>
                <Grid2 size={{xs: 12}}>
                    <Typography variant={'h4'} gutterBottom color={'primary'} align={'center'}>
                        {t`LOGIN`}
                    </Typography>
                </Grid2>
                <Grid2 size={{xs: 12}}>
                    <TextInput
                        label={t`Member ID or e-mail`}
                        value={data.username}
                        autoComplete={'username'}
                        onChange={({target: {value}}) => handleDataChange({username: value})}
                        error={needValidation && !!validator.username(data)}
                        helperText={needValidation && validator.username(data, validator)?.join(' ')}
                        disabled={disabled}
                        variant={'azo'}
                        autoFocus
                        fullWidth
                    />
                </Grid2>
                <Grid2 size={{xs: 12}}>
                    <TextInput
                        label={t`Password`}
                        type={'password'}
                        autoComplete={'current-password'}
                        value={data.password}
                        onKeyPress={e => e.key === 'Enter' && submitHandler()}
                        onChange={({target: {value}}) => handleDataChange({password: value})}
                        error={needValidation && !!validator.password(data)}
                        helperText={needValidation && validator.password(data, validator)?.join(' ')}
                        disabled={disabled}
                        variant={'azo'}
                        fullWidth
                    />
                </Grid2>
                {helperText && (
                    <Grid2 size={{xs: 12}}>
                        {helperText}
                    </Grid2>
                )}
                <Grid2 size={{xs: 12}}>
                    <AzoButton
                        onClick={submitHandler}
                        disabled={!canSubmit}
                        variant={'contained'}
                        color={'secondary'}
                        size={'medium'}
                        disableElevation
                        fullWidth>
                        {submitting ? <CircularProgress/> : <Typography variant={'h6'}>{t`Login`}</Typography>}
                    </AzoButton>
                </Grid2>
                <Grid2 size={{xs: 12}}>
                    <Typography className={classes.links} variant={'subtitle1'} gutterBottom color={'secondary'}
                                align={'center'}>
                        <a href={'/user/password'}>{t`Reset your password`}</a>
                    </Typography>
                </Grid2>
                <Grid2 size={{xs: 12}}>
                    <Typography variant={'body1'} gutterBottom color={'primary'} align={'center'}>
                        {t`Join SATA IMAGINE and earn miles.`}
                    </Typography>
                    <Typography className={classes.links} variant={'subtitle1'} gutterBottom color={'secondary'}
                                align={'center'}>
                        <a href={'/user/register'}>{t`Create new account`}</a>
                    </Typography>
                </Grid2>
                <Grid2 size={{xs: 12}}/>
            </Grid2>
        </form>
    );

    if (fullscreen) {
        return (
            <LoginFullscreenWrapper {...fullscreenDialogProps}>
                {form}
            </LoginFullscreenWrapper>
        );
    }

    return form;
};

LoginForm.propTypes = {
    formData: PropTypes.shape({
        username: PropTypes.string,
        password: PropTypes.string,
    }),
    formDataValidator: PropTypes.object,
    onChange: PropTypes.func,
    onSubmit: PropTypes.func,
    needValidation: PropTypes.bool,
    disabled: PropTypes.bool,
    helperText: PropTypes.any,
    fullscreen: PropTypes.bool,
    submitting: PropTypes.bool,
    fullscreenDialogProps: PropTypes.object,
};


export default LoginForm;