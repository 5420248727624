import React from 'react';
import {Box, Grid2, useMediaQuery, useTheme} from "@mui/material";
import {
    ArrivalDatePickerConnected,
    BookSubmitButtonConnected,
    BookWithMilesCheckboxConnected,
    //BookWithCashNMilesCheckboxConnected,
    DepartureDatePickerConnected,
    FlightTypeSelectorConnected,
    PassengersCountSelectorConnected,
    PassengerTypeSelectorConnected,
    PromoCodeInputConnected,
    RouteSelectorConnected,
    BookAlertConnected,
    BookMultiCityLegsConnected,
    BookMultiCityAlertConnected,
    BookStopoverConnected,
    useIsStopoverEligibleConnected,
    BookStopoverOpenButtonConnected,
    //BookStopoverWithExperiencesConnected,
} from "../../../index";
import {useSelector} from "react-redux";
import {SW_FLIGHT_TYPES} from "../../../../store/types";
import useIsRailFlyEligibleConnected from "./resources/useIsRailFlyEligibleConnected";
import BookRailFlyConnected from "./resources/BookRailFlyConnected";
import BookRailFlyOpenButtonConnected from "./resources/BookRailFlyOpenButtonConnected";

const BookContentFlowConnected = () => {

    const flightType = useSelector(state => state.sw.book.flightType);
    const isStopoverEligible = useIsStopoverEligibleConnected();
    const isStopoverOpen = useSelector(state => state.sw.book.stopover.open);

    const isRailFlyEligible = useIsRailFlyEligibleConnected();
    const isRailFlyOpen = useSelector(state => state.sw.book.railFly.open);

    const {breakpoints} = useTheme();
    const isTablet = useMediaQuery(breakpoints.down('md'));
    const isMobile = useMediaQuery(breakpoints.down('sm'));


    if (flightType === SW_FLIGHT_TYPES.MULTI) {
        return (
            <Grid2 container spacing={2}>
                <Grid2 size={{xs:12}}>
                    <FlightTypeSelectorConnected/>
                </Grid2>
                <BookMultiCityLegsConnected/>
                <BookMultiCityAlertConnected/>
                <Grid2 size={{xs: 12}}>
                    <PassengersCountSelectorConnected/>
                </Grid2>
                <Grid2 size={{xs:12}}>
                    <Box display={'flex'} alignItems={'flex-start'} flexDirection={isTablet ? 'column' : 'row'} justifyContent={isTablet ? 'flex-end' : 'space-between'}>
                        <PassengerTypeSelectorConnected/>
                    </Box>
                </Grid2>
                <Grid2 size={{xs:12}}>
                    <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                        <BookSubmitButtonConnected/>
                    </Box>
                </Grid2>
            </Grid2>
        );
    }

    //STOPOVER
    if (isStopoverEligible && isStopoverOpen) {
        return (
            <Grid2 container spacing={2}>
                <Grid2 size={{xs:12}}>
                    <FlightTypeSelectorConnected/>
                </Grid2>
                <Grid2 size={{xs:12}}>
                    <RouteSelectorConnected/>
                </Grid2>
                <Grid2 size={{xs:12, sm: 6}}>
                    <DepartureDatePickerConnected/>
                </Grid2>
                <Grid2 size={{xs:12, sm: 6}}>
                    <ArrivalDatePickerConnected/>
                </Grid2>
                <Grid2 size={{xs:12}}>
                    <BookStopoverConnected/>
                </Grid2>
                {/*<Grid xs={12} item>
                    <BookStopoverWithExperiencesConnected/>
                </Grid>*/}
            </Grid2>
        )
    }

    // RAIL FLY
    if (isRailFlyEligible && isRailFlyOpen) {
        return (
            <Grid2 container spacing={2}>
                <Grid2 size={{xs:12}}>
                    <FlightTypeSelectorConnected/>
                </Grid2>
                <Grid2 size={{xs:12}}>
                    <RouteSelectorConnected/>
                </Grid2>
                <Grid2 size={{xs:12, sm:6}}>
                    <DepartureDatePickerConnected/>
                </Grid2>
                <Grid2 size={{xs:12, sm:6}}>
                    <ArrivalDatePickerConnected/>
                </Grid2>
                <Grid2 size={{xs:12}}>
                    <BookRailFlyConnected/>
                </Grid2>
            </Grid2>
        )
    }

    //COMMON FLOW
    return (
        <Grid2 container spacing={2}>
            <Grid2 size={{xs:12}}>
                <FlightTypeSelectorConnected/>
            </Grid2>
            <Grid2 size={{xs:12}}>
                <RouteSelectorConnected/>
            </Grid2>
            <Grid2 size={{xs:12, sm: 6}}>
                <DepartureDatePickerConnected/>
            </Grid2>
            <Grid2 size={{xs:12, sm: 6}}>
                <ArrivalDatePickerConnected/>
            </Grid2>
            <BookStopoverOpenButtonConnected/>
            <BookRailFlyOpenButtonConnected/>
            <Grid2 size={{xs:12, sm: 6}}>
                <PassengersCountSelectorConnected/>
            </Grid2>
            <Grid2 size={{xs:12, sm:6}}>
                <PromoCodeInputConnected/>
            </Grid2>
            <BookAlertConnected/>
            <Grid2 size={{xs: 12}}>
                <Grid2 container spacing={0.5}>
                    <Grid2 size={{xs:12, md: 6, lg: 12}}>
                        <Box minWidth={{md: '600px'}} display={'flex'} alignItems={'flex-start'} flexDirection={isTablet ? 'column' : 'row'} justifyContent={isTablet ? 'flex-end' : 'space-between'}>
                            <PassengerTypeSelectorConnected/>
                        </Box>
                    </Grid2>
                    <Grid2 size={{xs: 12, md: 6, lg: 12}}>
                        <Box display={'flex'} alignItems={isMobile ? 'flex-end' : 'flex-start'} flexDirection={isMobile ? 'column' : 'row'} justifyContent={'flex-end'}>
                            <BookWithMilesCheckboxConnected/>
                            {/*<div style={isMobile ? {} : {marginRight: '10px', marginLeft: '10px'}} />*/}
                            {/*<BookWithCashNMilesCheckboxConnected/>*/}
                        </Box>
                    </Grid2>
                </Grid2>
            </Grid2>
            <Grid2 size={{xs:12}}>
                <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                    <BookSubmitButtonConnected/>
                </Box>
            </Grid2>
        </Grid2>
    );


};

export default BookContentFlowConnected;