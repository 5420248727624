import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import CalendarIcon from "../../../Icons/CalendarIcon";
import {DatePicker, DropInput, DropInputContent} from '../../../index';
import {t} from 'ttag';
import {useMediaQuery} from "@mui/material";

const DatePickerInput = ({
                             open = false,
                             label = 'Date input',
                             placeholder = 'Date',
                             date = null,
                             onFocus = e => null,
                             onBlur = e => null,
                             onChange = date => null,
                             DropInputProps = {},
                             DatePickerProps = {},
                             containerRef,
                             clearHandler = null,
                             disabled = false,
                             error = false,
                             placement = 'bottom',
                         }) => {
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

    useEffect(() => {
        window.addEventListener('resize', onBlur);

        return () => {
            window.removeEventListener('resize', onBlur);
        };
    }, [onBlur]);

    return (
        <DropInput
            label={label}
            aria-label={label}
            aria-labelledby={label}
            value={date ? date.format('DD MMMM') : ''}
            placeholder={placeholder}
            fullWidth
            readOnly
            containerRef={containerRef}
            clearHandler={clearHandler}
            adornmentIcon={<CalendarIcon/>}
            disabled={disabled}
            onFocus={onFocus}
            onBlur={onBlur}
            open={open}
            error={error}
            sx={{width: containerRef?.current?.offsetWidth < 375 ? !isMobile ? '375px !important' : `${containerRef?.current?.offsetWidth + 45}px !important` : null}}
            placement={isMobile ? 'bottom' : placement}
            {...DropInputProps}
        >
            <DropInputContent className={'azo-date-picker-input'} py={1}>
                <DatePicker
                    start={date}
                    end={date}
                    selectedDateHandler={onChange}
                    disabled={disabled}
                    pastDisabled
                    // monthOverflowDaysVisible={false}
                    // acceptKeyboardControls
                    t={s => {
                        const monthNames = {
                            January: t`January`,
                            February: t`February`,
                            March: t`March`,
                            April: t`April`,
                            May: t`May`,
                            June: t`June`,
                            July: t`July`,
                            August: t`August`,
                            September: t`September`,
                            October: t`October`,
                            November: t`November`,
                            December: t`December`,
                        };
                        return monthNames.hasOwnProperty(s) ? monthNames[s] : s;
                    }}
                    disableBuiltInSwipe={false}
                    enableMouseSwipe={false}
                    isOpen={true}
                    calendars={1}
                    isYearPicker={false}
                    isMonthPicker={false}
                    monthOverflowDaysVisible={true}
                    abstractToMonths={true}
                    abstractToDecades={false}
                    navigable={true}
                    {...DatePickerProps}
                />
            </DropInputContent>
        </DropInput>
    );
};

DatePickerInput.propTypes = {
    label: PropTypes.string,
    placeholder: PropTypes.string,
    date: PropTypes.shape({
        format: PropTypes.func,
    }),
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    DropInputProps: PropTypes.object,
    DatePickerProps: PropTypes.object,
    containerRef: PropTypes.any,
    clearHandler: PropTypes.func,
    disabled: PropTypes.bool,
    open: PropTypes.bool,
    error: PropTypes.bool,
    placement: PropTypes.string
};

export default DatePickerInput;