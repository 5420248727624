export const SW_TABS = {
    BOOK: 'BOOK',
    CHECK_IN: 'CHECK_IN',
    FLIGHT_STATUS: 'FLIGHT_STATUS',
    MY_TRIP: 'MY_TRIP',
}

export const SW_CROSS_SELLING_TABS = {
    FLIGHT: 'FLIGHT',
    EXPERIENCES: 'EXPERIENCES',
    HOTEL: 'HOTEL',
    CAR: 'CAR',
    PARKING: 'PARKING',
    ACTIVITIES: 'ACTIVITIES',
}

export const SW_RAIL_FLY_LEG_TYPES = {
    RETURN: 'RETURN',
    ONE_WAY: 'ONE_WAY',
    ROUND_TRIP: 'ROUND_TRIP',
}
export const SW_FLIGHT_TYPES = {
    RETURN: 'RETURN',
    RETURN_AWARD: 'RETURN_AWARD',
    ONE_WAY: 'ONEWAY',
    ONEWAY_AWARD: 'ONEWAY_AWARD',
    MULTI: 'MULTI',
}

export const SW_FLIGHT_STATUS_MODES = {
    FLIGHT_NUMBER: 'flight-number',
    ROUTE: 'route',
    DEPARTURE: 'departure',
    ARRIVAL: 'arrival'
}

export const SW_FLIGHT_STATUS_DATE_OPTIONS = {
    YD: 'YD',
    TD: 'TD',
    TM: 'TM',
    AT: 'AT',
}

export const SW_FLIGHT_STATUS_REAL_TIME_STATUS_CODES = {
    ON_SCHEDULE: 'On schedule',
    AHEAD_OF_SCHEDULE: 'Ahead of schedule',
    DEPARTED: 'Departed',
    ARRIVED: 'Arrived',
    DELAYED: 'Delayed',
    DIVERTED: 'Diverted',
    CANCELLED: 'Cancelled',
}

export const SW_FLIGHT_STATUS_REAL_TIME_STATUS_TABS = {
    ARRIVALS: 'ARRIVALS',
    DEPARTURES: 'DEPARTURES',
}

export const SW_PASSENGER_TYPES = {
    ADULT: 'ADULT',
    RESIDENT: 'RESIDENT',
    STUDENT: 'STUDENT'
};
