import React from 'react';
import PropTypes from 'prop-types';
import {Box, Typography} from "@mui/material";
import stopoverAzoresImage from '../../../../resources/images/stopover-azores.svg';
import useStyles from "./StopoverAzoresImageStyles";

const StopoverAzoresImage = ({origin = null, title = null, destination = null}) => {

    const classes = useStyles();

    return (
        <Box display={'flex'} flexDirection={'column'} flex={1}>
            <img
                className={classes.img}
                src={stopoverAzoresImage}
                alt={'Stopover Azores'}
            />
            <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} flexGrow={'0'}
                 position={'relative'}>
                <Typography className={[classes.origin, classes.truncate].join(' ')} variant={'body1'}
                            color={'primary'}>
                    {origin}
                </Typography>
                <Typography className={classes.title} variant={'h6'} color={'primary'}>
                    {title}
                </Typography>
                <Typography className={[classes.destination, classes.truncate].join(' ')} variant={'body1'}
                            color={'primary'}>
                    {destination}
                </Typography>
            </Box>
        </Box>
    );
};

StopoverAzoresImage.propTypes = {
    origin: PropTypes.node,
    title: PropTypes.node,
    destination: PropTypes.node,
};

export default StopoverAzoresImage;