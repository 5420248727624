import React from 'react';
import styles from './AzoButton.module.scss';
import {Button} from '@mui/material';
import PropTypes from 'prop-types';

const AzoButton = ({children, tall = false, disabled, ...props}) => {

    return (
        <Button
            classes={{root: ['azo-sw-button', tall ? styles.buttonRootTall : '', disabled ? styles.disabled : ''].join(' ')}}
            disabled={disabled}
            {...props}>
            {children}
        </Button>
    );
};

AzoButton.propTypes = {
    tall: PropTypes.bool,
    children: PropTypes.node,
    classes: PropTypes.object,
    className: PropTypes.string,
    color: PropTypes.oneOf(['default', 'inherit', 'primary', 'secondary']),
    component: PropTypes.elementType,
    disabled: PropTypes.bool,
    disableElevation: PropTypes.bool,
    disableFocusRipple: PropTypes.bool,
    disableRipple: PropTypes.bool,
    endIcon: PropTypes.node,
    focusVisibleClassName: PropTypes.string,
    fullWidth: PropTypes.bool,
    href: PropTypes.string,
    size: PropTypes.oneOf(['large', 'medium', 'small']),
    startIcon: PropTypes.node,
    type: PropTypes.oneOfType([PropTypes.oneOf(['button', 'reset', 'submit']), PropTypes.string]),
    variant: PropTypes.oneOf(['contained', 'outlined', 'text']),
};


export default AzoButton;