import React from 'react';
import {TabContent, MyTripForm} from "../../../index";
import {useDispatch, useSelector} from "react-redux";
import {setSwMyTripState} from "../../../../store/reducers/search-widget";

const MyTripConnected = () => {

    const myTrip = useSelector(state => state.sw.myTrip);
    const currentLanguage = useSelector(state => state.app.currentLanguage);
    const dispatch = useDispatch();

    return (
        <TabContent>
            <MyTripForm
                formData={myTrip}
                onChange={trip => dispatch(setSwMyTripState(trip))}
                formAction={`${process.env.REACT_APP_SUBMIT_URL_TP2}`}
                lang={currentLanguage.substring(0, 2)}
            />
        </TabContent>
    );
};

export default MyTripConnected;
