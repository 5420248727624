import React from 'react';
import PropTypes from 'prop-types';
import {SW_FLIGHT_STATUS_REAL_TIME_STATUS_CODES} from "../../../../../store/types";
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    useMediaQuery,
    useTheme
} from "@mui/material";
import useStyles from './FlightsTableStyles';
import moment from "moment";
import he from "he";

const FlightsTable = ({flights = [], columnTitles = {}, limitFlights = 5, t = s => s, ...props}) => {

    const classes = useStyles();
    const {breakpoints} = useTheme();
    const isMobile = useMediaQuery(breakpoints.down('sm'));

    const localColumnTitles = {
        datetime: 'Hour',
        flightNumber: 'Flight',
        airport: 'Origin',
        status: 'Status',
        ...columnTitles,
    };

    const mapStatusToColor = status => {
        switch (status) {
            case SW_FLIGHT_STATUS_REAL_TIME_STATUS_CODES.ARRIVED:
            case SW_FLIGHT_STATUS_REAL_TIME_STATUS_CODES.DEPARTED:
                return isMobile ? '#3A8340' : '#4caf50';
            case SW_FLIGHT_STATUS_REAL_TIME_STATUS_CODES.ON_SCHEDULE:
            case SW_FLIGHT_STATUS_REAL_TIME_STATUS_CODES.AHEAD_OF_SCHEDULE:
            case SW_FLIGHT_STATUS_REAL_TIME_STATUS_CODES.DELAYED:
                return 'primary.main';
            case SW_FLIGHT_STATUS_REAL_TIME_STATUS_CODES.DIVERTED:
            case SW_FLIGHT_STATUS_REAL_TIME_STATUS_CODES.CANCELLED:
                return 'error.main';
            default:
                return 'primary.main';
        }
    };

    //FILTER ALGORITHM
    //total <= 5 | show all
    //total > 5  | Show only 5 :: without arrived flights before last 5 min
    //if (total after 2nd rule) < 5 | Show last 5 flights
    const filteredFlights = (() => {
        if (!limitFlights || flights.length <= limitFlights) return flights;
        const now = moment();
        const flightsWithoutArrivedBeforeLast5min = flights.filter(flight => {
            if (flight.statusCode === SW_FLIGHT_STATUS_REAL_TIME_STATUS_CODES.ARRIVED) {
                const flightTime = moment(flight.datetime);
                return Boolean(flightTime.isSameOrAfter(now.clone().add(-5, 'minutes')));
            }

            return true;
        });

        if (flightsWithoutArrivedBeforeLast5min.length < limitFlights) return flights.slice(-limitFlights);

        return flightsWithoutArrivedBeforeLast5min.slice(0, limitFlights);
    })();

    return (
        <TableContainer classes={{root: classes.root}}>
            <Table stickyHeader size={'small'} aria-label={'Flights Table'} {...props}>
                <TableHead>
                    <TableRow>
                        <TableCell><Box color={'primary.main'}
                                        fontFamily={'OpenSansMedium'}>{localColumnTitles.datetime}</Box></TableCell>
                        <TableCell><Box color={'primary.main'}
                                        fontFamily={'OpenSansMedium'}>{localColumnTitles.flightNumber}</Box></TableCell>
                        <TableCell><Box color={'primary.main'}
                                        fontFamily={'OpenSansMedium'}>{localColumnTitles.airport}</Box></TableCell>
                        <TableCell align={'right'}><Box color={'primary.main'}
                                                        fontFamily={'OpenSansMedium'}>{localColumnTitles.status}</Box></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {filteredFlights.map((flight, idx) => (
                        <TableRow key={idx}>
                            <TableCell><Box
                                color={'primary.main'}>{flight.datetime?.substring(11, 16)}</Box></TableCell>
                            <TableCell><Box color={'primary.main'}
                                            fontFamily={'OpenSansMedium'}>{flight.flightNumber}</Box></TableCell>
                            <TableCell><Box color={'primary.main'}>{he.decode(flight.airport)}</Box></TableCell>
                            <TableCell align={'right'}><Box color={mapStatusToColor(flight.statusCode)}
                                                            whiteSpace={'nowrap'}
                                                            fontFamily={'OpenSansMedium'}>{t(flight.status)}</Box></TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

FlightsTable.STATUS_CODES = SW_FLIGHT_STATUS_REAL_TIME_STATUS_CODES;

FlightsTable.propTypes = {
    flights: PropTypes.arrayOf(
        PropTypes.shape({
            datetime: PropTypes.string,
            flightNumber: PropTypes.string,
            airport: PropTypes.string,
            status: PropTypes.string,
            statusCode: PropTypes.oneOf(Object.values(FlightsTable.STATUS_CODES)),
        })
    ),
    columnTitles: PropTypes.shape({
        datetime: PropTypes.string,
        flightNumber: PropTypes.string,
        airport: PropTypes.string,
        status: PropTypes.string,
    }),
    children: PropTypes.node,
    classes: PropTypes.object,
    className: PropTypes.string,
    component: PropTypes.elementType,
    padding: PropTypes.oneOf(['default', 'checkbox', 'none']),
    size: PropTypes.oneOf(['small', 'medium']),
    stickyHeader: PropTypes.bool,
    t: PropTypes.func,
    limitFlights: PropTypes.oneOfType([PropTypes.number, PropTypes.bool])
};


export default FlightsTable;