import React from 'react';
import {PassengerTypeSelector} from "../../../../index";
import {useDispatch, useSelector} from "react-redux";
import {setSwBookState} from "../../../../../store/reducers/search-widget";
import {SW_FLIGHT_TYPES} from "../../../../../store/types";

const PassengerTypeSelectorConnected = () => {

    const passengerType = useSelector(state => state.sw.book.passengerType);
    const origin = useSelector(state => state.sw.book.origin);
    const destination = useSelector(state => state.sw.book.destination);
    const promoCode = useSelector(state => state.sw.book.promoCode);
    const isOSP = Boolean(origin?.countryName === 'Portugal' && destination?.countryName === 'Portugal' && (origin?.azores || destination?.azores));
    const children = useSelector(state => state.sw.book.children);
    const infants = useSelector(state => state.sw.book.infants);
    const withMiles = useSelector(state => state.sw.book.withMiles);
    const flightType = useSelector(state => state.sw.book.flightType);
    const dispatch = useDispatch();

    const disabledTypes = [];
    if (Boolean(children + infants) || flightType === SW_FLIGHT_TYPES.MULTI) disabledTypes.push(PassengerTypeSelector.PASSENGER_TYPES.STUDENT);
    if ((origin && destination && !isOSP) || promoCode || withMiles) {
        disabledTypes.push(PassengerTypeSelector.PASSENGER_TYPES.RESIDENT);
        disabledTypes.push(PassengerTypeSelector.PASSENGER_TYPES.STUDENT);
    }

    return (
        <PassengerTypeSelector
            type={passengerType}
            onChange={type => dispatch(setSwBookState({passengerType: type}))}
            disabledTypes={disabledTypes}
        />
    );
};

export default PassengerTypeSelectorConnected;