import moment from "moment";
import APP_SETTINGS from "./settings";

export const encodeQueryData = (data = {}) => {
    return Object.keys(data).map(k => {
        return data[k] === undefined ? undefined : encodeURIComponent(k) + '=' + encodeURIComponent(data[k]);
    }).filter(v => Boolean(v)).join('&');
}

export const decodeQueryString = (str = '') => {
    const decodedQueryStr = {};
    const paramsArray = str.replace('?', '').split('&');
    paramsArray.forEach(param => {
        const paramArray = param.split('=');
        if (paramArray.length >= 2) decodedQueryStr[paramArray[0]] = decodeURIComponent(paramArray[1]);
    });
    return decodedQueryStr;
};

export const parseApiDateTime = apiDateTime => {
    if (!apiDateTime) return null;
    if (typeof apiDateTime === 'object') {
        const {date: dateWithMs} = apiDateTime;
        const dateWithoutMs = dateWithMs.split('.')[0];
        return moment.utc(dateWithoutMs).local().clone();
    }

    return moment.utc(apiDateTime).local().clone();
};

export const loadFile = async (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onload = () => resolve({
            content: btoa(reader.result),
            name: file.name,
            type: file.type,
            size: file.size
        });
        reader.onerror = error => reject(error);
    });
};

export const randomArbitrary = (min, max) => {
    return Math.random() * (max - min) + min;
};

export const randomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const asyncEvery = async (arr, predicate) => {
    for (let e of arr) {
        if (!await predicate(e)) return false;
    }
    return true;
};

export const asyncSome = async (arr, predicate) => {
    for (let e of arr) {
        if (await predicate(e)) return true;
    }
    return false;
};

export const getGeoStraightLineDistance = (lat1, lon1, lat2, lon2) => {
    const deg2rad = deg => deg * (Math.PI / 180);
    const R = 6371; // Radius of the earth in km
    const dLat = deg2rad(lat2-lat1);  // deg2rad below
    const dLon = deg2rad(lon2-lon1);
    const a =
        Math.sin(dLat/2) * Math.sin(dLat/2) +
        Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
        Math.sin(dLon/2) * Math.sin(dLon/2)
    ;
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    return R * c; // Distance in km
}

export const determineCloserAirport = (lat = null, long = null, airports = []) => {
    if (lat !== null && long !== null && Array.isArray(airports)) {
        let closerAirport = null;
        airports.forEach(airport => {
            if (airport && airport.code && airport.latitude && airport.longitude) {
                const distance = getGeoStraightLineDistance(lat, long, airport.latitude, airport.longitude);
                if (!closerAirport || (closerAirport && closerAirport.distance > distance)) {
                    closerAirport = {...airport, distance};
                }
            }
        });

        return closerAirport;
    }
    return null;
}

export const getCoordsPromised = () => {
    return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
    })
};

export const byteToMB = (bytes) => (bytes / 1000000).toFixed(1);

export const localToUtcStr = localTimeMomentInstance => localTimeMomentInstance.clone().utc().format(APP_SETTINGS.DATE_TIME_FORMAT);
