import React, {useEffect} from 'react';
import {LoadingConnected, SearchWidget} from './components';
import {init} from "./store/reducers/app";
import {useDispatch} from "react-redux";

function App() {

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(init());
  }, [dispatch]);

  return (
    <>
      <LoadingConnected/>
      <SearchWidget/>
    </>
  );
}

export default App;
