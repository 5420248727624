import {t} from 'ttag';
import cx from "classnames";
import PropTypes from 'prop-types';
import React, {useEffect, useState} from "react";
import {Box, Container} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import useStyles from './RouteSelectorStyles';
import {AirportSelector} from "./index";
import SyncAltIcon from "../../Icons/SyncAltIcon";
import {useDispatch, useSelector} from "react-redux";
import {loadFlightRoutesDestinations} from "../../../store/reducers/search-widget";

const RouteSelector = ({
                           airportsData,
                           autoFocus = false,
                           variant = 'normal',
                           onChange,
                           originPlaceholder,
                           destinationPlaceholder,
                           optionField = 'name',
                           compressed = false,
                           originFocus = false,
                           destinationFocus = false,
                           onBlur = e => null,
                           onFocus = e => null,
                           originError = false,
                           destinationError = false,
                           error = false,
                           ...props
                       }) => {
    let destinationInput;
    let originInput;
    let switching = false;
    const dispatch = useDispatch();

    const classes = useStyles(error);

    const [origin, setOrigin] = useState(props.origin);
    const [destinations, setDestinations] = useState([]);
    const [destination, setDestination] = useState(props.destination);
    const [originFocused, setOriginFocused] = useState(originFocus);
    const [destinationFocused, setDestinationFocused] = useState(destinationFocus);
    const routesDestinations = useSelector(state => state.sw.flightRoutesDestinations);
    const withMiles = useSelector(state => state.sw.book.withMiles);
    const [destinationsLoading, setDestinationsLoading] = useState(false);
    const originsLoading = useSelector(state => state.sw.flightRoutesAirportsLoading);
    const routesDestinationLoading = useSelector(state => state.sw.loadFlightRoutesDestinationsLoading);

    useEffect(() => {
        if (origin?.code) {
            dispatch(loadFlightRoutesDestinations(origin?.code));
        }
    }, [dispatch, origin])

    useEffect(() => {
        setOrigin(props.origin);
        // eslint-disable-next-line
    }, [props.origin]);

    useEffect(() => {
        setDestination(props.destination);
        // eslint-disable-next-line
    }, [props.destination]);

    useEffect(() => {
        setOriginFocused(originFocus);
        if (originFocus && originInput) originInput.focus()
        // eslint-disable-next-line
    }, [originFocus]);

    useEffect(() => {
        setDestinationFocused(destinationFocus);
        if (destinationFocus && destinationInput) destinationInput.focus()
        // eslint-disable-next-line
    }, [destinationFocus]);

    useEffect(() => {
        if (routesDestinations) {
            withMiles ? setDestinations(routesDestinations.filter(d => d.award === true)) : setDestinations(routesDestinations);
            setDestinationsLoading(false);
        }
        // eslint-disable-next-line
    }, [routesDestinations, withMiles]);

    /**
     * Origins selector change handler
     * @param {object} value
     */
    const handleOriginChange = value => {
        setOrigin(value);
        setDestination(null);
        if (originInput) originInput.blur();
        setDestinations([]);
        if (destinationInput && !switching) destinationInput.focus();
    };

    /**
     * Destinations selector change handler
     * @param {object} value
     */
    const handleDestinationChange = value => {
        setDestination(value);
        if (destinationInput) destinationInput.blur();
        if (!switching && onChange) onChange({origin, destination: value});
        switching = false;
    };

    /**
     * Switching origin for destination handler
     */
    const handleRouteSwitch = () => {
        if (!origin || !destination) return;
        switching = true;
        handleOriginChange(destination);
        handleDestinationChange(origin);
        if (onChange) onChange({origin: destination, destination: origin});
    };

    /**
     * Origin selector component
     *
     * @returns {JSX.Element}
     */
    const originSelector = (noBorder = false) => {
        return <AirportSelector
            onChange={handleOriginChange}
            autoFocus={autoFocus}
            label={t`From`}
            placeholder={originPlaceholder}
            name={'origin'}
            options={airportsData}
            variant={'azo'}
            leftRadius={variant !== 'stacked'}
            onFocus={e => {
                setOriginFocused(true)
                if (onFocus) {
                    onFocus(e);
                }
            }}
            onBlur={e => {
                setOriginFocused(false);
                if (!destinationFocus && onBlur) onBlur(e);
            }}
            inputRef={input => originInput = input}
            loading={originsLoading}
            value={origin}
            optionField={optionField}
            compressed={compressed}
            error={originError || error}
            noBorder={noBorder}
        />;
    }

    /**
     * Destination selector component
     *
     * @returns {JSX.Element}
     */
    const destinationSelector = (noBorder = false) => {
        return <AirportSelector
            label={t`To`}
            placeholder={destinationPlaceholder}
            name={'destination'}
            options={destinations || []}
            variant={'azo'}
            rightRadius={variant !== 'stacked'}
            onFocus={e => setDestinationFocused(true)}
            onBlur={e => {
                setDestinationFocused(false);
                if (!originFocus && onBlur) onBlur(e);
            }}
            inputRef={input => destinationInput = input}
            noOptionsText={origin ? t`No airports found.` : t`Please select an origin first.`}
            onChange={handleDestinationChange}
            value={destination}
            optionField={optionField}
            loading={routesDestinationLoading || destinationsLoading}
            compressed={compressed}
            error={destinationError || error}
            noBorder={noBorder}
        />;
    }

    /**
     * Regular component render
     *
     * @returns {JSX.Element}
     * @constructor
     */
    const Normal = () => (
        <Box className={classes.root}>
            <Box className={classes.item}>
                {originSelector(true)}
            </Box>
            <Box className={cx(classes.leftMiddleItem, {focused: originFocused || destinationFocused})}>
                <IconButton onClick={handleRouteSwitch} aria-label={'exchange-route'} color={'secondary'}>
                    <SyncAltIcon/>
                </IconButton>
            </Box>
            <Box className={classes.item}>
                {destinationSelector(true)}
            </Box>
        </Box>
    );

    /**
     * Origin and destination are stack on top of each other. Used im mobile devices
     *
     * @returns {JSX.Element}
     * @constructor
     */
    const Stacked = () => (
        <Box className={cx("azo-route-selector")}>
            {originSelector()}
            <Box className={classes.stacked}>
                {destinationSelector()}
            </Box>
        </Box>
    );

    /**
     *
     * @returns {JSX.Element}
     * @constructor
     */
    const Small = () => (
        <Container maxWidth={'md'} disableGutters>
            {Normal()}
        </Container>
    );

    switch (variant) {
        case 'stacked':
            return <>{Stacked()}</>;

        case 'small':
            return <>{Small()}</>;

        case 'standard':
        default:
            return <>{Normal()}</>;
    }
};

RouteSelector.propTypes = {
    airportsData: PropTypes.arrayOf(AirportSelector.airportShape).isRequired,
    originPlaceholder: PropTypes.string,
    destinationPlaceholder: PropTypes.string,
    autoFocus: PropTypes.bool,
    onChange: PropTypes.func,
    variant: PropTypes.oneOf(['normal', 'stacked', 'small']),
    origin: PropTypes.object,
    destination: PropTypes.object,
    optionField: PropTypes.oneOf(['name', 'code']),
    compressed: PropTypes.bool,
    originFocus: PropTypes.bool,
    destinationFocus: PropTypes.bool,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    originError: PropTypes.bool,
    destinationError: PropTypes.bool,
    error: PropTypes.bool,
};


export default RouteSelector;
