import React from 'react';
import PropTypes from 'prop-types';
import {t} from "ttag";
import {Box, useMediaQuery, useTheme, Typography} from "@mui/material";
import {RadioButton} from "../../index";
import {SW_FLIGHT_TYPES} from "../../../store/types";

const FlightTypeSelector = ({type = null, onChange = () => null, disabledTypes = []}) => {

    const {breakpoints} = useTheme();
    const isLowerThenSmall = useMediaQuery(breakpoints.down('sm'));

    return (
        <Box display={'flex'} flexDirection={'row'}>
            <RadioButton
                label={<Typography variant={isLowerThenSmall ? 'caption' : undefined}>{t`Round trip`}</Typography>}
                ariaLabel={t`Round trip`}
                checked={type === SW_FLIGHT_TYPES.RETURN}
                onChange={() => onChange(SW_FLIGHT_TYPES.RETURN)}
                disabled={disabledTypes.includes(SW_FLIGHT_TYPES.RETURN)}
            />
            <RadioButton
                label={<Typography variant={isLowerThenSmall ? 'caption' : undefined}>{t`One-way`}</Typography>}
                ariaLabel={t`One-way`}
                checked={type === SW_FLIGHT_TYPES.ONE_WAY}
                onChange={() => onChange(SW_FLIGHT_TYPES.ONE_WAY)}
                disabled={disabledTypes.includes(SW_FLIGHT_TYPES.ONE_WAY)}
            />
            <RadioButton
                label={<Typography variant={isLowerThenSmall ? 'caption' : undefined}>{t`Multi-city`}</Typography>}
                ariaLabel={t`Multi-city`}
                checked={type === SW_FLIGHT_TYPES.MULTI}
                onChange={() => onChange(SW_FLIGHT_TYPES.MULTI)}
                disabled={disabledTypes.includes(SW_FLIGHT_TYPES.MULTI)}
            />
        </Box>
    );
};

FlightTypeSelector.FLIGHT_TYPES = SW_FLIGHT_TYPES;

FlightTypeSelector.propTypes = {
    type: PropTypes.oneOf(Object.values(FlightTypeSelector.FLIGHT_TYPES)),
    disabledTypes: PropTypes.arrayOf(PropTypes.oneOf(Object.values(FlightTypeSelector.FLIGHT_TYPES))),
    onChange: PropTypes.func,
};



export default FlightTypeSelector;