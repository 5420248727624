import React, {useState} from 'react';
import {t} from 'ttag';
import {AzoButton} from "../../../../index";
import {BOOK_FORM_REF} from "../../common/book-form-input-refs";
import {useSelector} from "react-redux";
import {Typography} from "@mui/material";
import {SW_FLIGHT_TYPES, SW_PASSENGER_TYPES} from "../../../../../store/types/index";
import CircularProgress from "@mui/material/CircularProgress";

const BookSubmitButtonConnected = () => {

    const [submitting, setSubmitting] = useState(false);
    const userLoggedIn = useSelector(state => state.app.userLoggedIn);
    const currentLanguage = useSelector(state => state.app.currentLanguage);
    const {
        origin = '',
        destination = '',
        departure = '',
        arrival = '',
        adults = '',
        children = '',
        infants = '',
        promoCode = '',
        passengerType = '',
        flightType = '',
        withMiles = false,
        multiCityLegs = [],
        multiCityIsValid = false,
    } = useSelector(state => state.sw.book);

    const canSubmit = (() => {
        switch (flightType) {
            case SW_FLIGHT_TYPES.RETURN:
                return origin
                    && destination
                    && departure
                    && arrival
                    && passengerType
                    && adults;
            case SW_FLIGHT_TYPES.ONE_WAY:
                return origin
                    && destination
                    && departure
                    && passengerType
                    && adults;
            case SW_FLIGHT_TYPES.MULTI:
                return Boolean(multiCityIsValid);
            default:
                return false;
        }
    })();

    const form = (() => {
        if (!canSubmit) return null;
        let paxType = "adults";
        switch (flightType) {
            case SW_FLIGHT_TYPES.RETURN:
            case SW_FLIGHT_TYPES.ONE_WAY:
                const canSendPromoCode = !withMiles && ![SW_PASSENGER_TYPES.RESIDENT, SW_PASSENGER_TYPES.STUDENT].includes(passengerType);

                let ori = `${origin?.code || []}.${destination.code || []}.${departure}`;
                let dest = `${destination.code || []}.${origin?.code || []}.${arrival}`;

                if (passengerType === SW_PASSENGER_TYPES.RESIDENT) {
                    paxType = "residents";
                } else if (passengerType === SW_PASSENGER_TYPES.STUDENT){
                    paxType = "students";
                }

                return (
                    <form method={'GET'}
                          action={`${process.env.REACT_APP_SUBMIT_URL_TP2}`}
                        // target={'_blank'}
                          ref={BOOK_FORM_REF}>
                        <input type={'hidden'} name={'step'} value={'flights'}/>
                        <input type={'hidden'} name={'pointOfSale'} value={'PT'}/>
                        <input type={'hidden'} name={paxType} value={adults}/>
                        <input type={'hidden'} name={'children'} value={children}/>
                        <input type={'hidden'} name={'infants'} value={infants}/>
                        <input type={'hidden'} name={'lang'} value={currentLanguage.substring(0, 2)}/>
                        <input type={'hidden'} name={'award'} value={withMiles ? 'true' : 'false'}/>
                        <input type={'hidden'} name={'loggedIn'} value={userLoggedIn ? 'true' : 'false'}/>
                        {canSendPromoCode && promoCode.trim().length !== 0 ? <input type={'hidden'} name={'discount'} value={'PROMOTION:'.concat(promoCode)} /> : ''}
                        {flightType === SW_FLIGHT_TYPES.RETURN
                            ? <input type={'hidden'} name={'search'} value={`${ori}*${dest}`}/>
                            : <input type={'hidden'} name={'search'} value={`${ori}`}/>
                        }
                    </form>
                );
            case SW_FLIGHT_TYPES.MULTI:
                if (passengerType === SW_PASSENGER_TYPES.RESIDENT) {
                    paxType = "residents";
                }

                return (
                    <form method={'GET'}
                          action={`${process.env.REACT_APP_SUBMIT_URL_TP2}`}
                        // target={'_blank'}
                          ref={BOOK_FORM_REF}>
                        <input type={'hidden'} name={'step'} value={'flights'}/>
                        <input type={'hidden'} name={'pointOfSale'} value={'PT'}/>
                        <input type={'hidden'} name={paxType} value={adults}/>
                        <input type={'hidden'} name={'children'} value={children}/>
                        <input type={'hidden'} name={'infants'} value={infants}/>
                        <input type={'hidden'} name={'lang'} value={currentLanguage.substring(0, 2)}/>
                        <input type={'hidden'} name={'search'} value={multiCityLegs.map((l, idx) => {
                            const leg = `${l.origin.code}.${l.destination.code}.${l.departure}`;
                            return multiCityLegs[idx + 1] ? `${leg}*` : leg;
                        }).join('')}/>
                    </form>
                );
            default:
                return null;
        }
    })();

    const submitHandler = () => {
        try {
            setSubmitting(true);
            BOOK_FORM_REF.current.submit();
            setTimeout(() => {
                setSubmitting(false);
            }, 3000);
        } catch (e) {
            setSubmitting(false);
        }
    }

    return (
        <AzoButton
            onClick={submitHandler}
            disabled={!canSubmit || submitting}
            variant={'contained'}
            color={'secondary'}
            size={'large'}
            style={{maxWidth: '300px'}}
            disableElevation
            fullWidth
            tall>
            {canSubmit && form}
            {submitting ? <CircularProgress/> : <Typography variant={'h6'}>{t`Search`}</Typography>}
        </AzoButton>
    );
};

export default BookSubmitButtonConnected;
