import React from 'react';
import PropTypes from 'prop-types';
import {Box, Container, IconButton, useMediaQuery, useTheme} from "@mui/material";
import {FullscreenDialog} from "../../../index";
import CloseIcon from "@mui/icons-material/Close";

const LoginFullscreenWrapper = ({children =null, closeHandler = () => null, disabled = false}) => {

    const {breakpoints} = useTheme();
    const isMobile = useMediaQuery(breakpoints.down('sm'));

    const localCloseHandler = disabled ? () => null : closeHandler;

    return (
        <FullscreenDialog PaperProps={{style: {backgroundColor: isMobile ? 'white' : 'rgba(0, 0, 0, .5)'}}} closeHandler={localCloseHandler}>
            <Box bgcolor={'transparent'} display={'flex'} justifyContent={'center'} alignItems={isMobile ? 'flex-start' : 'center'} height={'100vh'} onMouseDown={localCloseHandler}>
                <Box bgcolor={'white'} p={{xs: 1, sm: 1, md: 1}} display={'flex'} justifyContent={'center'} alignItems={'center'} borderRadius={'6px'} position={'relative'} onMouseDown={e => e.stopPropagation()}>
                    <Box color={'primary'} position={'absolute'} right={'12px'} top={'12px'} zIndex={1}>
                        <IconButton
                            disabled={disabled}
                            onClick={localCloseHandler}
                            size={'small'}
                            color={'inherit'}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Container style={{maxWidth: '388px'}}>
                        {children}
                    </Container>
                </Box>
            </Box>
        </FullscreenDialog>
    );
};

LoginFullscreenWrapper.propTypes = {
    children: PropTypes.any,
    closeHandler: PropTypes.func,
    disabled: PropTypes.bool,
};



export default LoginFullscreenWrapper;