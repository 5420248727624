import {createAction, createAsyncThunk, createReducer} from '@reduxjs/toolkit';
import {
    SW_CROSS_SELLING_TABS,
    SW_FLIGHT_STATUS_DATE_OPTIONS,
    SW_FLIGHT_STATUS_MODES,
    SW_FLIGHT_STATUS_REAL_TIME_STATUS_TABS,
    SW_FLIGHT_TYPES,
    SW_PASSENGER_TYPES, SW_RAIL_FLY_LEG_TYPES,
    SW_TABS
} from "../types";
import HttpClient from "axios";
import SETTINGS from "../../config/settings";
import airportsMock from "../mocks/airports.json";
import bookingConfigMock from '../mocks/booking-config.json';
import moment from "moment";
import {t} from "ttag";

const QUERY_PARAMS = new URLSearchParams(window.location.search);
const allAirports = window.originListData ? window.originListData : airportsMock.originListData;
let flightSearchAirports = null;

export const AIRPORTS_WITH_RAIL_FLY = ['FRA'];
export const AIRPORTS_AZORES = ['SMA', 'PDL', 'TER', 'HOR', 'PIX', 'GRW', 'SJZ', 'FLW', 'CVU'];

export const setSwState = createAction('sw/setSwState');
export const setSwBookState = createAction('sw/setSwBookState');
export const setSwBookStopoverState = createAction('sw/setSwBookStopoverState');
export const setSwBookRailFlyState = createAction('sw/setSwBookRailFlyState');
export const setSwBookStopoverOutboundState = createAction('sw/setSwBookStopoverOutboundState');
export const setSwBookStopoverInboundState = createAction('sw/setSwBookStopoverInboundState');
export const setSwBookRailFlyOutboundState = createAction('sw/setSwBookRailFlyOutboundState');
export const setSwBookRailFlyInboundState = createAction('sw/setSwBookRailFlyInboundState');
export const setSwCheckInState = createAction('sw/setSwCheckInState');
export const setSwMyTripState = createAction('sw/setSwMyTripState');
export const setSwFlightStatusState = createAction('sw/setSwFlightStatusState');
export const setSwFlightStatusRealTimeState = createAction('sw/setSwFlightStatusRealTimeState');
export const setSwLoginState = createAction('sw/setSwLoginState');
export const setSwBookWithMiles = createAsyncThunk(
    'sw/setSwBookWithMiles',
    async ({withMiles}, {dispatch, getState}) => {
        const {app: {userLoggedIn, devTools: {debug}}} = getState();
        dispatch(setSwState({focusedComponent: null}));

        if (withMiles && !userLoggedIn && !debug) {
            return window.location.href = '/user/login';
            // return dispatch(setAppState({needAuthentication: true}));
        }

        dispatch(setSwBookState({withMiles}));
    }
);
export const loadStopoverSlideshowImages = createAsyncThunk(
    'sw/loadStopoverSlideshowImages',
    async (args, {dispatch, getState}) => {
        dispatch(setSwBookStopoverState({slideshowImagesLoading: true}));
        return HttpClient.get(`${SETTINGS.app.API_ENDPOINT}/flight-search/slideshow`)
            .then(({data}) => {
                if (Array.isArray(data)) {
                    dispatch(setSwBookStopoverState({
                        slideshowImages: data.map(img => ({
                            title: img.title,
                            position: img.position,
                            slideshow: img.slideshow,
                            airport: img.airport,
                            image: {
                                id: img.image?.id,
                                src: img.image?.src,
                                title: img.image?.title,
                                alt: img.image?.alt,
                                width: img.image?.width,
                                height: img.image?.height,
                            },
                            tags: img.tags,
                        }))
                    }));
                }
            })
            .finally(() => {
                dispatch(setSwBookStopoverState({slideshowImagesLoading: false}));
            });
    }
);

export const loadFlightStatusAirports = createAsyncThunk(
    'sw/loadFlightStatusAirports',
    async (args, {dispatch, getState}) => {
        const {sw: {allAirports}} = getState();
        dispatch(setSwState({flightStatusAirportsLoading: true}));
        return HttpClient.get(`${SETTINGS.app.API_ENDPOINT}/flight-status/origins`)
            .then(({data}) => {
                const iataCodes = Object.values(data).map(market => Object.keys(market)).flat();
                const flightStatusAirports = allAirports.filter(a => iataCodes.includes(a?.code));
                dispatch(setSwState({flightStatusAirports}));
                return flightStatusAirports;
            })
            .finally(() => {
                dispatch(setSwState({flightStatusAirportsLoading: false}));
            });
    }
);
export const loadFlightRoutesAirports = createAsyncThunk(
    'sw/loadFlightRoutesAirports',
    (args, {dispatch}) => {
        dispatch(setSwState({flightRoutesAirportsLoading: true}));
        return HttpClient.get(`${SETTINGS.app.API_ENDPOINT}/flight-search/routes`)
            .then(async ({data}) => {
                const routes = data?.data?.airports.map(data => ({code: data?.code, award: data?.meta?.award})).flat();
                try {
                    const {data: airports} = await HttpClient.get(`${SETTINGS.app.API_ENDPOINT}/flight-search/airports`);
                    const CURRENT_LANGUAGE = SETTINGS.i18n.LOCALE_DEFAULT.toLowerCase() === 'pt' ? 'pt-pt' : SETTINGS.i18n.LOCALE_DEFAULT;
                    const {data: translations} = await HttpClient.get(`${SETTINGS.app.API_ENDPOINT}/${CURRENT_LANGUAGE}/flight-search/airports/translation`);
                    let flightRoutesAirports = airports.filter(a => routes.find(route => route?.code === a?.code));
                    flightRoutesAirports = flightRoutesAirports.map(r => ({
                        ...r,
                        award: routes.find(code => code?.code === r?.code)?.award
                    }));
                    routes.forEach(route => {
                        if (flightRoutesAirports.some(airport => airport.code === route.code) && translations?.airports[route.code]) {
                            flightRoutesAirports = flightRoutesAirports.map(airport => (
                                {
                                    ...airport,
                                    name: translations?.airports[airport.code] || airport.name,
                                    countryName: translations?.countries[airport.countryCode] || airport.countryName,
                                    cityName: translations?.cities[airport.cityCode] || airport.cityName,
                                    regionName: airports.find((a) => a.countryCode === airport.countryCode)?.regionName || airport?.regionName
                                }
                            ))
                        }
                        if (!flightRoutesAirports.some(airport => airport.code === route.code) && translations.airports[route.code]) {
                            const countryCode = data?.data?.airports.find((a) => a.code === route.code)?.countryCode;
                            const cityCode = data?.data?.airports.find((a) => a.code === route.code)?.cityCode;
                            flightRoutesAirports.push({
                                code: route.code,
                                name: translations[route.code],
                                cityCode: cityCode || "",
                                cityName: translations?.cities[cityCode] || "",
                                countryCode: countryCode || "",
                                countryName: translations?.countries[countryCode] || "",
                                regionName: airports.find((a) => a.countryCode === countryCode)?.regionName || "",
                                x: 0,
                                y: 0,
                                azores: false,
                                airlineOperated: false,
                                destinations: [],
                                partnerAirport: false
                            })
                        }
                    });
                    flightRoutesAirports = flightRoutesAirports.sort((a, b) => a.name.localeCompare(b.name));
                    flightSearchAirports = flightRoutesAirports;
                    dispatch(setSwState({flightRoutesAirports}));
                    return flightRoutesAirports;
                } catch {
                    const routes = data?.data?.airports.map(data => {
                        return {code: data?.code, award: data?.meta?.award}
                    }).flat();
                    let flightRoutesAirports = airportsMock.originListData.filter(a => routes.find(code => code?.code === a?.code));
                    flightRoutesAirports = flightRoutesAirports.map(r => {
                        return {...r, award: routes.find(code => code?.code === r?.code)?.award}
                    })
                    flightSearchAirports = flightRoutesAirports;
                    dispatch(setSwState({flightRoutesAirports}));
                    return flightRoutesAirports;
                }
            })
            .finally(() => {
                dispatch(setSwState({flightRoutesAirportsLoading: false}));
            });
    }
);
export const loadFlightRoutesDestinations = createAsyncThunk(
    'sw/loadFlightRoutesDestinations',
    async (code, {dispatch}) => {
        dispatch(setSwState({loadFlightRoutesDestinationsLoading: true}));
        return HttpClient.get(`${SETTINGS.app.API_ENDPOINT}/flight-search/routes/${code}`)
            .then(({data}) => {
                const destinations = data?.data?.applicableDestinations?.[code].flat();
                let flightRoutesDestinations = flightSearchAirports.filter(a => destinations.find(code => code?.code === a?.code));
                flightRoutesDestinations = flightRoutesDestinations.map(d => {
                    return {...d, award: destinations.find(code => code?.code === d?.code)?.award}
                })
                dispatch(setSwState({flightRoutesDestinations}));
                return flightRoutesDestinations;
            })
            .finally(() => {
                dispatch(setSwState({loadFlightRoutesDestinationsLoading: false}));
            });
    }
);
export const loadFlightStatusRealTimeFlights = createAsyncThunk(
    'sw/loadFlightStatusRealTimeFlights',
    async (targetAirport, {dispatch, getState}) => {
        const {sw: {allAirports}} = getState();
        const urlExtension = targetAirport?.code ? `?airport=${targetAirport.code}` : '';
        dispatch(setSwFlightStatusRealTimeState({loading: true}));
        return HttpClient.get(`${SETTINGS.app.API_ENDPOINT}/flight-status/realtime${urlExtension}`)
            .then(({data: {airport, arrivals, departures}}) => {
                dispatch(setSwFlightStatusRealTimeState({
                    selectedAirport: allAirports.find(a => a.code === airport),
                    arrivalFlights: [...arrivals.map(flight => ({
                        datetime: flight.datetime,
                        flightNumber: flight.flightNumber,
                        airport: allAirports.find(a => a.code === flight.airport)?.name,
                        status: flight.status,
                        statusCode: flight.status,
                    }))],
                    departureFlights: [...departures.map(flight => ({
                        datetime: flight.datetime,
                        flightNumber: flight.flightNumber,
                        airport: allAirports.find(a => a.code === flight.airport)?.name,
                        status: flight.status,
                        statusCode: flight.status,
                    }))]
                }));
            })
            .finally(() => {
                dispatch(setSwFlightStatusRealTimeState({loading: false}));
            });
    }
);
export const syncMainLegBetweenFlows = createAsyncThunk(
    'sw/syncMainLegBetweenFlows',
    async ({toMultiCity = false, toCommonFlow = false}, {dispatch, getState}) => {
        const {sw: {book: {origin, destination, departure, multiCityLegs: legs}}} = getState();
        const [firstLeg] = legs;
        if (toMultiCity) {
            dispatch(crudMultiCityLeg({leg: {...firstLeg, origin, destination, departure}, idx: 0}));
            dispatch(validateMultiCityLegs());
        }
        if (toCommonFlow) dispatch(setSwBookState({
            origin: firstLeg.origin,
            destination: firstLeg.destination,
            departure: firstLeg.departure
        }));
    }
);
export const crudMultiCityLeg = createAsyncThunk(
    'sw/crudMultiCityLeg',
    async ({leg = null, idx = null, atIdx = null}, {dispatch, getState}) => {
        const {sw: {book: {multiCityLegs: legs}}} = getState();
        let updatedLegs = [...legs];
        if (leg && idx === null && legs.length < 8 && atIdx !== null) updatedLegs.splice(atIdx, 0, leg); //create (on the provided atIdx)
        if (leg && idx === null && legs.length < 8 && atIdx === null) updatedLegs.push(leg); //create (Append)
        if (leg && idx !== null) updatedLegs = legs.map((l, i) => i !== idx ? l : {...l, ...leg}); //update
        if (!leg && idx !== null && legs.length > 2) updatedLegs = legs.filter((l, i) => i !== idx); //delete
        dispatch(setSwBookState({multiCityLegs: updatedLegs}));
    }
);
export const validateMultiCityLegs = createAsyncThunk(
    'sw/validateMultiCityLegs',
    async (args, {dispatch, getState}) => {
        const {sw: {book: {multiCityLegs: legs}}} = getState();
        let hasUnfilledLegs = false;
        let hasInconsistentDatesError = false;
        let hasDuplicatedCityPairError = false;
        let lastDeparture = null;
        const updatedLegs = legs.map((leg, idx) => {
            let inconsistentDatesError = false;
            let duplicatedCityPairError = false;
            const isFilled = Boolean(leg.origin && leg.destination && leg.departure);
            if (!isFilled) {
                hasUnfilledLegs = true;
            }
            if (leg.departure && lastDeparture && moment(leg.departure).isBefore(moment(lastDeparture))) {
                hasInconsistentDatesError = true;
                inconsistentDatesError = true;
            }
            if (leg.origin && leg.destination && legs.filter((l, i) => i !== idx).some(l => l?.origin?.code === leg.origin.code && l?.destination?.code === leg.destination.code)) {
                hasDuplicatedCityPairError = true;
                duplicatedCityPairError = true;
            }
            const error = Boolean(inconsistentDatesError || duplicatedCityPairError);
            if (leg.departure) lastDeparture = leg.departure;
            return {...leg, error, duplicatedCityPairError, inconsistentDatesError};
        });
        let multiCityAlertMessage = null;
        if (hasInconsistentDatesError) multiCityAlertMessage = {
            severity: 'warning',
            children: t`The inserted dates are not valid. Please select new dates and try again.`
        };
        if (hasDuplicatedCityPairError) multiCityAlertMessage = {
            severity: 'warning',
            children: t`There are duplicate route segments in your selection. Please review the selected options and try again.`
        };
        if (hasInconsistentDatesError && hasDuplicatedCityPairError) multiCityAlertMessage = {
            severity: 'warning',
            children: t`The inserted dates are not valid and there are duplicated route segments. Please review the selected options and try again.`
        };
        const multiCityIsValid = !hasUnfilledLegs && !hasInconsistentDatesError && !hasDuplicatedCityPairError;
        dispatch(setSwBookState({multiCityLegs: updatedLegs, multiCityAlertMessage, multiCityIsValid}));
        return multiCityIsValid;
    }
);
export const retrieveStopoverEligibleCityPairs = createAsyncThunk(
    'sw/retrieveStopoverEligibleCityPairs',
    async (args, {dispatch, getState}) => {
        if (!SETTINGS.SW.STOPOVER_ENABLED) return false;
        dispatch(setSwBookStopoverState({loading: true}));
        return HttpClient.get(`${SETTINGS.app.API_ENDPOINT}/sata-stopover/eligible-city-pairs`)
            .then(({data}) => {
                dispatch(setSwBookStopoverState({eligibleCityPairs: data}));
            })
            .catch(e => {
                console.error(e.message);
            })
            .finally(() => {
                dispatch(setSwBookStopoverState({loading: false}));
            });
    }
);
export const retrieveStopoverAvailability = createAsyncThunk(
    'sw/retrieveStopoverAvailability',
    async (args, {dispatch, getState}) => {
        if (!SETTINGS.SW.STOPOVER_ENABLED) return false;
        dispatch(setSwBookStopoverState({loading: true}));
        //AVAILABILITY :: CHECK ELIGIBILITY
        const {
            STOPOVER_MIN_STAY_NUMBER_OF_NIGHTS_AT_DESTINATION,
            STOPOVER_MIN_NUMBER_OF_NIGHTS,
            STOPOVER_MAX_NUMBER_OF_NIGHTS
        } = SETTINGS.SW;
        const {
            app: {devTools: {mode}},
            sw: {
                book: {
                    flightType,
                    origin,
                    destination,
                    departure,
                    arrival,
                    stopover: {outbound, inbound, eligibleCityPairs, availability, open}
                }
            }
        } = getState();
        const isRoundTrip = flightType === SW_FLIGHT_TYPES.RETURN;
        const isOneWay = flightType === SW_FLIGHT_TYPES.ONE_WAY;

        const eligibleCityPairDefinitions = eligibleCityPairs.find(e => e?.origin === origin?.code && e?.destination === destination?.code) || null;
        const {outboundShift = 0, inboundShift = 0} = eligibleCityPairDefinitions || {};
        const isEligibleToRailFly = (AIRPORTS_WITH_RAIL_FLY.includes(origin?.code) && AIRPORTS_AZORES.includes(destination?.code)) || (AIRPORTS_WITH_RAIL_FLY.includes(destination?.code) && AIRPORTS_AZORES.includes(origin?.code));

        const isEligible = (isRoundTrip || isOneWay)
            && !isEligibleToRailFly
            && origin
            && destination
            && departure
            && (isOneWay || (isRoundTrip && arrival))
            && eligibleCityPairDefinitions
            && (isOneWay || (isRoundTrip && moment(arrival).diff(moment(departure).add(outboundShift, 'd'), 'd') >= STOPOVER_MIN_NUMBER_OF_NIGHTS));

        if (!isEligible) return dispatch(setSwBookStopoverState({loading: false}));

        if (open) dispatch(setSwState({focusedComponent: null}));

        const availabilityDataID = `${origin?.code}:${destination?.code}:${departure}:${arrival}:${isRoundTrip}`;

        //AVAILABILITY :: ALREADY IN STATE
        if (availability?.id === availabilityDataID) {
            return dispatch(setSwBookStopoverState({loading: false}));
        }

        if (mode === SETTINGS.app.MODES.MOCK) {
            dispatch(setSwState({focusedComponent: null}));
            return dispatch(setSwBookStopoverState({
                availability: {
                    id: availabilityDataID,
                    origin,
                    destination,
                    departure,
                    arrival,
                    outbound: {
                        PDL: [
                            {date: '2020-11-26', numberOfNights: 1},
                            {date: '2020-11-27', numberOfNights: 2},
                            {date: '2020-11-29', numberOfNights: 4},
                        ],
                        TER: []
                    },
                    inbound: {
                        PDL: [
                            {date: '2020-12-01', numberOfNights: 1},
                            {date: '2020-12-05', numberOfNights: 5},
                            {date: '2020-12-07', numberOfNights: 7},
                        ],
                        TER: []
                    }
                },
                loading: false,
            }));
        }

        //AVAILABILITY :: RETRIEVE FROM SERVER
        HttpClient.post(`${SETTINGS.app.API_ENDPOINT}/sata-stopover/available-dates`, {
            origin: origin?.code,
            destination: destination?.code,
            departureDate: departure,
            returnDate: isRoundTrip ? arrival : null
        })
            .then(({data: {outbound: aOutbound, inbound: aInbound}}) => {
                const mDeparture = moment(departure).add(outboundShift, 'd');
                const mArrival = (arrival && moment(arrival).add(inboundShift, 'd')) || null;

                let mOutboundLimitDate = mDeparture.clone().add(STOPOVER_MAX_NUMBER_OF_NIGHTS, 'd');
                if (isRoundTrip && moment(arrival).isSameOrBefore(mOutboundLimitDate, 'd')) mOutboundLimitDate = moment(arrival).add(-STOPOVER_MIN_STAY_NUMBER_OF_NIGHTS_AT_DESTINATION, 'd');
                const mInboundLimitDate = isRoundTrip && mArrival.clone().add(STOPOVER_MAX_NUMBER_OF_NIGHTS, 'd');

                const retrievedAvailability = {
                    id: availabilityDataID,
                    origin,
                    destination,
                    departure,
                    arrival,
                    outbound: aOutbound.reduce((o, a) => ({
                        ...o, [a.airportIataCode]: a.dates.filter(date => {
                            return origin?.code !== a.airportIataCode
                                && destination?.code !== a.airportIataCode
                                && moment(date).isAfter(mDeparture, 'd')
                                && moment(date).isSameOrBefore(mOutboundLimitDate, 'd');
                        }).map(date => ({date, numberOfNights: moment(date).diff(mDeparture, 'd')}))
                    }), {}),
                    inbound: aInbound.reduce((o, a) => ({
                        ...o, [a.airportIataCode]: a.dates.filter(date => {
                            return isRoundTrip
                                && origin?.code !== a.airportIataCode
                                && destination?.code !== a.airportIataCode
                                && moment(date).isAfter(mArrival, 'd')
                                && moment(date).isSameOrBefore(mInboundLimitDate, 'd');
                        }).map(date => ({date, numberOfNights: moment(date).diff(mArrival, 'd')}))
                    }), {}),
                };

                const stopoverAvailable = retrievedAvailability.outbound?.PDL?.length
                    || retrievedAvailability.outbound?.TER?.length
                    || retrievedAvailability.inbound?.PDL?.length
                    || retrievedAvailability.inbound?.TER?.length;

                dispatch(setSwBookStopoverState({
                    availability: stopoverAvailable ? retrievedAvailability : null,
                    alertMessage: null
                }));

                //POPULATE OUTBOUND LEG BASED ON AVAILABILITY
                if (stopoverAvailable) {
                    dispatch(setSwBookStopoverOutboundState({
                        enabled: Boolean(outbound.enabled && (retrievedAvailability.outbound.PDL?.length || retrievedAvailability.outbound.TER?.length)),
                        selectedAirport: outbound.selectedAirport && retrievedAvailability.outbound[outbound.selectedAirport]?.length ? outbound.selectedAirport : null,
                        numberOfNights: outbound.selectedAirport && outbound.numberOfNights && retrievedAvailability.outbound[outbound.selectedAirport]?.some(({numberOfNights}) => numberOfNights === outbound?.numberOfNights) ? outbound.numberOfNights : null,
                        departureDate: outbound.selectedAirport && outbound.departureDate && retrievedAvailability.outbound[outbound.selectedAirport]?.some(({departureDate}) => departureDate === outbound?.departureDate) ? outbound.departureDate : null,
                    }));
                    //POPULATE INBOUND LEG BASED ON AVAILABILITY
                    dispatch(setSwBookStopoverInboundState({
                        enabled: Boolean(inbound.enabled && (retrievedAvailability.inbound.PDL?.length || retrievedAvailability.inbound.TER?.length)),
                        selectedAirport: inbound.selectedAirport && retrievedAvailability.inbound[inbound.selectedAirport]?.length ? inbound.selectedAirport : null,
                        numberOfNights: inbound.selectedAirport && inbound.numberOfNights && retrievedAvailability.inbound[inbound.selectedAirport]?.some(({numberOfNights}) => numberOfNights === inbound?.numberOfNights) ? inbound.numberOfNights : null,
                        departureDate: inbound.selectedAirport && inbound.departureDate && retrievedAvailability.inbound[inbound.selectedAirport]?.some(({departureDate}) => departureDate === inbound?.departureDate) ? inbound.departureDate : null,
                    }));
                } else {
                    dispatch(setSwBookStopoverState({
                        alertMessage: {
                            severity: 'warning',
                            children: t`On the selected dates, there are no Stopover Azores offers available. Please change your travel dates to find the best deals.`
                        }
                    }));
                }
            })
            .catch(({message}) => {
                console.error('API Availability retrieve error:', message);
                dispatch(setSwBookStopoverState({alertMessage: {severity: 'error', children: message}}));
            })
            .finally(() => {
                dispatch(setSwBookStopoverState({loading: false}));
            });
    }
);

// export const retrieveRailFlyAvailability = createAsyncThunk(
//     'sw/retrieveRailFlyAvailability',
//     async (args, {dispatch, getState}) => {
//         // if (!SETTINGS.SW.RAILFLY_ENABLED) return false;
//         dispatch(setSwBookRailFlyState({loading: true}));
//         const {
//             sw: {
//                 book: {
//                     flightType,
//                     origin,
//                     destination,
//                     departure,
//                     arrival,
//                     railFly: {eligibleCityPairs, availability, open}
//                 }
//             }
//         } = getState();
//         const isRoundTrip = flightType === SW_FLIGHT_TYPES.RETURN;
//         const isOneWay = flightType === SW_FLIGHT_TYPES.ONE_WAY;
//
//         const eligibleCityPairDefinitions = eligibleCityPairs.find(e => e?.origin === origin?.code && e?.destination === destination?.code) || null;
//         const isEligibleToRailFly = (AIRPORTS_WITH_RAILFLY.includes(origin?.code) && AIRPORTS_AZORES.includes(destination?.code)) || (AIRPORTS_WITH_RAILFLY.includes(destination?.code) && AIRPORTS_AZORES.includes(origin?.code));
//         const isEligible = (isRoundTrip || isOneWay)
//             && origin
//             && destination
//             && departure
//             && (isOneWay || (isRoundTrip && arrival))
//             && eligibleCityPairDefinitions
//             && isEligibleToRailFly
//             && (isOneWay || (isRoundTrip));
//
//
//         console.log('isEligibleToRailFly');
//         if (isEligible) {
//             dispatch(setSwBookRailFlyState({enabled: isEligibleToRailFly}));
//         }
//         if (!isEligible) return dispatch(setSwBookRailFlyState({loading: false}));
//         if (open) dispatch(setSwState({focusedComponent: null}));
//
//         const availabilityDataID = `${origin?.code}:${destination?.code}:${departure}:${arrival}:${isRoundTrip}`;
//
//         //AVAILABILITY :: ALREADY IN STATE
//         if (availability?.id === availabilityDataID) {
//             return dispatch(setSwBookRailFlyState({loading: false}));
//         }
//     }
// );

export const queryParamsData = {
    origin: allAirports.find(a => a.code === QUERY_PARAMS.get('sw_book_origin')) || null,
    destination: allAirports.find(a => a.code === QUERY_PARAMS.get('sw_book_destination')) || null,
    departure: QUERY_PARAMS.get('sw_book_departure'),
    arrival: QUERY_PARAMS.get('sw_book_return'),
    adults: QUERY_PARAMS.get('sw_book_adults'),
    children: QUERY_PARAMS.get('sw_book_children'),
    infants: QUERY_PARAMS.get('sw_book_infants'),
    promoCode: QUERY_PARAMS.get('sw_book_promoCode'),
    flightType: QUERY_PARAMS.get('sw_book_flightType'),
    fullscreen: QUERY_PARAMS.get('sw_book_fullscreen'),
};

export const queryParamsParser = level => {
    const parsedData = {};
    if (level === 'root' || level === '/') {
        if (Object.values(SW_TABS).includes(QUERY_PARAMS.get('sw_selected_tab'))) {
            parsedData.selectedTab = QUERY_PARAMS.get('sw_selected_tab');
        }
    }
    if (level === 'book') {
        const today = moment();

        if ([SW_FLIGHT_TYPES.RETURN, SW_FLIGHT_TYPES.ONE_WAY].includes(queryParamsData.flightType)) {
            parsedData.flightType = queryParamsData.flightType;
        }
        if (queryParamsData.origin && queryParamsData.destination && queryParamsData.origin?.code !== queryParamsData.destination?.code) {
            parsedData.origin = queryParamsData.origin;
            parsedData.destination = queryParamsData.destination;
        }
        if (queryParamsData.departure && moment(queryParamsData.departure).isValid() && moment(queryParamsData.departure).isSameOrAfter(today.format('YYYY-MM-DD'))) {
            parsedData.departure = queryParamsData.departure.substring(0, 4) + '-' + queryParamsData.departure.substring(4, 6) + '-' + queryParamsData.departure.substring(6, 8);
        }
        if (queryParamsData.arrival && moment(queryParamsData.arrival).isValid() && moment(queryParamsData.arrival).isSameOrAfter(today.format('YYYY-MM-DD')) && (!parsedData.departure || (parsedData.departure && moment(queryParamsData.arrival).isSameOrAfter(moment(parsedData.departure).format('YYYY-MM-DD'))))) {
            parsedData.arrival = queryParamsData.arrival.substring(0, 4) + '-' + queryParamsData.arrival.substring(4, 6) + '-' + queryParamsData.arrival.substring(6, 8);
        }
        if (!isNaN(parseInt(queryParamsData.adults)) && parseInt(queryParamsData.adults) >= 1 && parseInt(queryParamsData.adults) <= 8) {
            parsedData.adults = parseInt(queryParamsData.adults);
        }
        if (!isNaN(parseInt(queryParamsData.children)) && ((!parsedData.adults && parseInt(queryParamsData.children) >= 0 && parseInt(queryParamsData.children) <= 7) || (parsedData.adults && parseInt(queryParamsData.children) >= 0 && parseInt(queryParamsData.children) <= (8 - parsedData.adults)))) {
            parsedData.children = parseInt(queryParamsData.children);
        }
        if (!isNaN(parseInt(queryParamsData.infants)) && ((!parsedData.adults && parseInt(queryParamsData.infants) >= 0 && parseInt(queryParamsData.infants) <= 1) || (parsedData.adults && parseInt(queryParamsData.infants) >= 0 && parseInt(queryParamsData.infants) <= parsedData.adults))) {
            parsedData.infants = parseInt(queryParamsData.infants);
        }
        if (queryParamsData.promoCode && queryParamsData.promoCode.length <= 10) {
            parsedData.promoCode = queryParamsData.promoCode.substring(0, 10).toUpperCase();
        }
        if (queryParamsData.fullscreen) {
            parsedData.fullscreen = true;
        }
    }
    if (level === 'checkIn') {
        if (QUERY_PARAMS.get('sw_checkIn_pnr')) {
            parsedData.pnr = QUERY_PARAMS.get('sw_checkIn_pnr').replace(/[^0-9a-zA-Z]/g, '').substr(0, 6);
        }
        if (QUERY_PARAMS.get('sw_checkIn_lastName')) {
            parsedData.lastName = QUERY_PARAMS.get('sw_checkIn_lastName').replace(/[^a-zA-Z ]+/g, '');
        }
    }

    return parsedData;
}

export const reducer = createReducer(
    {
        bookingConfig: bookingConfigMock,
        selectedTab: SW_TABS.BOOK,
        focusedComponent: null,
        allAirports,
        flightStatusAirportsLoading: false,
        flightStatusAirports: [],
        book: {
            origin: null,
            destination: null,
            departure: null,
            arrival: null,
            adults: 1,
            children: 0,
            infants: 0,
            passengerType: SW_PASSENGER_TYPES.ADULT,
            promoCode: '',
            flightType: SW_FLIGHT_TYPES.RETURN,
            fullscreen: false,
            withMiles: false,
            withCashNMiles: false,
            crossSellingTab: SW_CROSS_SELLING_TABS.FLIGHT,
            backgroundImage: window.SW_BACKGROUND_IMAGE || null,
            alertMessage: null,
            multiCityAlertMessage: null,
            multiCityIsValid: false,
            multiCityLegs: [
                {origin: null, destination: null, departure: null},
                {origin: null, destination: null, departure: null},
            ],
            stopover: {
                loading: false,
                eligibleCityPairs: [],
                open: false,
                availability: null,
                alertMessage: null,
                slideshowImages: [],
                slideshowImagesLoading: false,
                outbound: {
                    enabled: true,
                    selectedAirport: null,
                    numberOfNights: null,
                    departureDate: null,
                },
                inbound: {
                    enabled: false,
                    selectedAirport: null,
                    numberOfNights: null,
                    departureDate: null,
                }
            },
            railFly: {
                loading: false,
                open: true,
                enabled: false,
                legType: SW_RAIL_FLY_LEG_TYPES.ROUND_TRIP,
            },
            ...queryParamsParser('book')
        },
        checkIn: {
            pnr: '',
            lastName: '',
            conditions: false,
            ...queryParamsParser('checkIn')
        },
        myTrip: {
            pnr: '',
            lastName: '',
        },
        flightStatus: {
            mode: (() => {
                return Object.values(SW_FLIGHT_STATUS_MODES).includes(QUERY_PARAMS.get('mode'))
                    ? QUERY_PARAMS.get('mode')
                    : SW_FLIGHT_STATUS_MODES.FLIGHT_NUMBER;
            })(),
            flightNumber: QUERY_PARAMS.get('flight-number') || '',
            origin: QUERY_PARAMS.get('origin') || null,
            destination: QUERY_PARAMS.get('dst_airport') || null,
            departure: QUERY_PARAMS.get('departure') || null,
            arrival: QUERY_PARAMS.get('arrival') || null,
            date: Object.values(SW_FLIGHT_STATUS_DATE_OPTIONS).includes(QUERY_PARAMS.get('date')) ? QUERY_PARAMS.get('date') : 'TD',
        },
        realTimeFlightStatus: {
            loading: false,
            selectedAirport: null,
            selectedTab: SW_FLIGHT_STATUS_REAL_TIME_STATUS_TABS.ARRIVALS,
            arrivalFlights: [],
            departureFlights: []
        },
        login: {
            username: '',
            password: '',
        },
        ...queryParamsParser('root')
    },
    {
        [setSwState]: (state, action) => ({...state, ...action.payload}),
        [setSwBookState]: (state, action) => ({...state, book: {...state.book, ...action.payload}}),
        [setSwBookStopoverState]: (state, action) => ({
            ...state,
            book: {...state.book, stopover: {...state.book.stopover, ...action.payload}}
        }),
        [setSwBookRailFlyState]: (state, action) => ({
            ...state,
            book: {...state.book, railFly: {...state.book.railFly, ...action.payload}}
        }),
        [setSwBookStopoverOutboundState]: (state, action) => ({
            ...state,
            book: {
                ...state.book,
                stopover: {...state.book.stopover, outbound: {...state.book.stopover.outbound, ...action.payload}}
            }
        }),
        [setSwBookStopoverInboundState]: (state, action) => ({
            ...state,
            book: {
                ...state.book,
                stopover: {...state.book.stopover, inbound: {...state.book.stopover.inbound, ...action.payload}}
            }
        }),
        [setSwCheckInState]: (state, action) => ({...state, checkIn: {...state.checkIn, ...action.payload}}),
        [setSwMyTripState]: (state, action) => ({...state, myTrip: {...state.myTrip, ...action.payload}}),
        [setSwFlightStatusState]: (state, action) => ({
            ...state,
            flightStatus: {...state.flightStatus, ...action.payload}
        }),
        [setSwFlightStatusRealTimeState]: (state, action) => ({
            ...state,
            realTimeFlightStatus: {...state.realTimeFlightStatus, ...action.payload}
        }),
        [setSwLoginState]: (state, action) => ({...state, login: {...state.login, ...action.payload}}),
    }
);

export default reducer;
