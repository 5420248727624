import React from 'react';
import PropTypes from 'prop-types';
import moment from "moment";
import styles from './Months.module.scss';
import Month from "./Month/Month";
import {Box} from "@mui/material";

function Months({
                    pivot = moment(),
                    start = null,
                    end = null,
                    children = null,
                    disabled = false,
                    pastDisabled = false,
                    selectMonthHandler = () => null,
                    availableRange = null,
                    inlineStyle = {},
                    t = str => str
                }) {

    const classes = disabled ? [styles.container, styles.disabled].join(' ') : styles.container;

    const today = moment();
    const year = pivot.format('YYYY');
    const firstMonth = moment(`${year}-01-01`);
    const lastMonth = moment(`${year}-12-01`);

    const months = [];
    const unit = 'month';
    for (let m = firstMonth.clone(); m.isSameOrBefore(lastMonth); m.add(1, unit)) {
        const current = m.clone();
        const isOutOfAvailableRange = availableRange
            ? current.isBefore(availableRange.start, unit) || current.isAfter(availableRange.end, unit)
            : false;
        const isSelected = current.isSameOrAfter(start, unit) && current.isSameOrBefore(end, unit);
        const isDisabled = disabled || isOutOfAvailableRange || (current.isBefore(today, unit) && pastDisabled);
        const isToRightSelection = current.isSame(start, unit);
        const isToLeftSelection = current.isSame(end, unit);
        months.push(
            <Month key={current.format('MMMM')}
                   clickHandler={() => selectMonthHandler(current.clone())}
                   disabled={isDisabled}
                   toRightSelection={isToRightSelection}
                   toLeftSelection={isToLeftSelection}
                   selected={isSelected}>
                <span title={t(current.format('MMMM'))}>
                    {t(current.format('MMMM')).substring(0, 3)}
                </span>
            </Month>
        );
    }

    return (
        <Box className={classes} style={inlineStyle}>
            <Box className={styles.monthsContainer}>
                {months}
                {children}
            </Box>
        </Box>
    );
}

Months.propTypes = {
    inlineStyle: PropTypes.object,
    selectMonthHandler: PropTypes.func,
    children: PropTypes.any,
    disabled: PropTypes.bool,
    pastDisabled: PropTypes.bool,
    pivot: PropTypes.instanceOf(moment),
    start: PropTypes.instanceOf(moment),
    end: PropTypes.instanceOf(moment),
    availableRange: PropTypes.shape({
        start: PropTypes.instanceOf(moment),
        end: PropTypes.instanceOf(moment)
    }),
    t: PropTypes.func
};

export default Months;
