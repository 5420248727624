import {makeStyles} from "@mui/styles";

const withStyles = makeStyles(theme => ({
    popperMessage: {
        margin: '8px',
        textAlign: 'left',
        zIndex: 1,
        backgroundColor: theme.palette.secondary.main,
        padding: '8px',
        color: 'white',
        borderRadius: '3px',
        maxWidth: '320px',
    },
    popperTitle: {
        fontWeight: 'bold',
        lineHeight: '1.2rem',
    },
    popperText: {
        lineHeight: '1.2rem',
    },
    topPlacementTriangle: {
        width: '0',
        height: '0',
        borderLeft: '6px solid transparent!important',
        borderTop: `8px solid ${theme.palette.secondary.main}`,
        borderRight: '6px solid transparent!important',
        bottom: '0',
        left: 'calc(50% - 4px)',
        position: 'absolute',
        "@media (max-width:399px)": {
            display: 'none',
        },
    },
    rightPlacementTriangle: {
        width: '0',
        height: '0',
        borderTop: '6px solid transparent!important',
        borderRight: `8px solid ${theme.palette.secondary.main}`,
        borderBottom: '6px solid transparent!important',
        left: '0',
        top: 'calc(50% - 6px)',
        position: 'absolute',
        "@media (max-width:399px)": {
            display: 'none',
        }
    },
    bottomPlacementTriangle: {
        width: '0',
        height: '0',
        borderLeft: '6px solid transparent!important',
        borderBottom: `8px solid ${theme.palette.secondary.main}`,
        borderRight: '6px solid transparent!important',
        top: '0',
        left: 'calc(50% - 4px)',
        position: 'absolute',
        "@media (max-width:399px)": {
            display: 'none',
        }
    },
    leftPlacementTriangle: {
        width: '0',
        height: '0',
        borderTop: '6px solid transparent!important',
        borderLeft: `8px solid ${theme.palette.secondary.main}`,
        borderBottom: '6px solid transparent!important',
        right: '0',
        top: 'calc(50% - 6px)',
        position: 'absolute',
        "@media (max-width:399px)": {
            display: 'none',
        }
    },
}));

export default withStyles;