import {t} from "ttag";
import React from 'react';
import PropTypes from 'prop-types';
import {Box, Typography} from "@mui/material";
import azoFraTrain from '../../../../resources/images/azo-fra-train.svg';
import trainFraAzo from '../../../../resources/images/train-fra-azo.svg';
import useStyles from "./Rail&FlyAzoresImageStyles";

const RailFlyAzoresImage = ({origin = null}) => {

    const classes = useStyles();
    const isOriginFrankfurt = origin === 'Frankfurt';

    return (
        <Box display={'flex'} flexDirection={'column'}>
            <img
                className={classes.img}
                src={isOriginFrankfurt ? trainFraAzo : azoFraTrain}
                alt={'Rail & Fly'}
            />
            <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} flexGrow={'0'}
                 position={'relative'}>
                <Typography className={classes.origin} variant={'h6'} color={'primary'}>
                    {!isOriginFrankfurt ? t`Azores` : t`Rail`}
                </Typography>
                <Typography className={classes.title} variant={'h6'} color={'primary'}>
                    {t`Frankfurt`}
                </Typography>
                <Typography className={classes.destination} variant={'h6'} color={'primary'}>
                    {!isOriginFrankfurt ? t`Rail` : t`Azores`}
                </Typography>
            </Box>
        </Box>
    );
};

RailFlyAzoresImage.propTypes = {
    origin: PropTypes.node,
};


export default RailFlyAzoresImage;