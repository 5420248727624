import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import useMyTripFormValidation from "./useMyTripFormValidation";
import {Box, Grid2, Typography} from "@mui/material";
import {t} from "ttag";
import {AzoButton, HelperIconInfo, TextInput} from "../../index";
import CircularProgress from "@mui/material/CircularProgress";
import {TEMPORARY_PNR_BLOCK} from "../../../resources/TemporaryPnrBlock";
import Alert from "@mui/material/Alert";

const MyTripForm = ({
                        formData = null,
                        formDataValidator = {},
                        formAction = '/',
                        needValidation = false,
                        onChange = () => null,
                        disabled = false,
                        lang = '',
                        reservationCodeMaxChars = 6,
                    }) => {
    const [data, setData] = useState({...formData});
    const [submitting, setSubmitting] = useState(false);
    const [submitError, setSubmitError] = useState(false);

    const {validator, isFormDataValid} = useMyTripFormValidation(formDataValidator);

    //const formRef = createRef();

    useEffect(() => {
        setData(formData);
    }, [setData, formData]);

    const handleDataChange = value => {
        const newData = {...data, ...value};
        setData({...newData});
        onChange({...newData});
    }

    const canSubmit = !disabled && !submitting && isFormDataValid(data);

    const submitHandler = () => {
        if (TEMPORARY_PNR_BLOCK.includes(data.pnr)) {
            setSubmitError(true);
            return;
        }

        if (!isFormDataValid(data)) {
            return setData({...data, needValidation: true});

        }
        setSubmitting(true);
        window.location.href = `${formAction}?step=manageTrip&lang=${lang}#lastName=${data.lastName}&recordLocator=${data.pnr}`;
        //formRef.current.submit();
    };

    return (
        <Box>
            <Grid2 spacing={2} container>
                <Grid2 size={{xs: 12}}>
                    <Typography variant={'subtitle1'} gutterBottom color={'primary'}>
                        {t`Here you can select your seat on board and book additional services for your flight.`}
                    </Typography>
                </Grid2>
                <Grid2 size={{xs: 12}}>
                    <TextInput
                        label={t`BOOKING CODE`}
                        placeholder={t`Booking Code`}
                        value={data.pnr}
                        onChange={({target: {value}}) => handleDataChange({pnr: value.replace(' ', '').substring(0, reservationCodeMaxChars)})}
                        error={needValidation && !!validator.pnr(data)}
                        helperText={needValidation && validator.pnr(data, validator)?.join(' ')}
                        variant={'azo'}
                        disabled={disabled}
                        fullWidth
                        style={{zIndex: 0}}
                        shrink
                        InputProps={{
                            endAdornment: (
                                <HelperIconInfo
                                    title={t`What is a booking code?`}
                                    subtitle={t`It's an alphanumeric code with 6 characters.`}
                                    heading={t`You can find it in your electronic ticket in "Booking reference".`}
                                    placement={'left'}
                                />
                            ),
                        }}
                    />
                </Grid2>
                <Grid2 size={{xs: 12}}>
                    <TextInput
                        label={t`LAST NAME`}
                        placeholder={t`Last Name`}
                        value={data.lastName}
                        onChange={({target: {value}}) => handleDataChange({lastName: value})}
                        error={needValidation && !!validator.lastName(data)}
                        helperText={needValidation && validator.lastName(data, validator)?.join(' ')}
                        variant={'azo'}
                        disabled={disabled}
                        fullWidth
                        shrink
                    />
                </Grid2>
                {/*<AutoForm
                    formData={data}
                    formRef={formRef}
                    action={`${formAction}?${PROS_str}`}
                />*/}
                {submitError && (
                    <Grid2 size={{xs: 12}}>
                        <Alert severity="error"
                               onClose={() => setSubmitError(false)}>{t`It was not possible to access your booking, please contact our Contact Center or your Travel Agent.`}</Alert>
                    </Grid2>
                )}
                <Grid2 size={{xs: 12}}>
                    <AzoButton
                        onClick={submitHandler}
                        disabled={!canSubmit}
                        variant={'contained'}
                        color={'secondary'}
                        size={'large'}
                        disableElevation
                        fullWidth
                        tall>
                        {submitting ? <CircularProgress/> : <Typography variant={'h6'}>{t`Next`}</Typography>}
                    </AzoButton>
                </Grid2>
            </Grid2>
        </Box>
    );
};

MyTripForm.propTypes = {
    formData: PropTypes.shape({
        pnr: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
    }),
    formDataValidator: PropTypes.object,
    formAction: PropTypes.string,
    needValidation: PropTypes.bool,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    lang: PropTypes.string,
    reservationCodeMaxChars: PropTypes.number,
};

export default MyTripForm;
