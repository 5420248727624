import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {DatePickerInput} from "../../../../index";
import {
    syncMainLegBetweenFlows,
    setSwBookState,
    setSwState,
    retrieveStopoverAvailability,
} from "../../../../../store/reducers/search-widget";
import {SW_FLIGHT_TYPES} from "../../../../../store/types";
import {COMPONENTS, DEPARTURE_REF} from "../../common/book-form-input-refs";
import moment from 'moment';
import {t} from 'ttag';

const DepartureDatePickerConnected = () => {

    const focusedComponent = useSelector(state => state.sw.focusedComponent);
    const flightType = useSelector(state => state.sw.book.flightType);
    const departureStr = useSelector(state => state.sw.book.departure);
    const arrivalStr = useSelector(state => state.sw.book.arrival);
    const departure = departureStr && moment(departureStr);
    const arrival = arrivalStr && moment(arrivalStr);
    const dispatch = useDispatch();

    return (
        <DatePickerInput
            label={t`DEPARTURE DATE`}
            placeholder={t`Departure Date`}
            date={departure}
            containerRef={DEPARTURE_REF}
            onFocus={() => dispatch(setSwState({focusedComponent: COMPONENTS.DEPARTURE_REF}))}
            onBlur={() => focusedComponent === COMPONENTS.DEPARTURE_REF && dispatch(setSwState({focusedComponent: COMPONENTS.NONE}))}
            open={focusedComponent === COMPONENTS.DEPARTURE_REF}
            onChange={({date}) => {
                if (arrival && date.isAfter(moment(arrival))) dispatch(setSwBookState({arrival: null}));
                dispatch(setSwBookState({departure: date.format('YYYY-MM-DD')}));
                dispatch(syncMainLegBetweenFlows({toMultiCity: true}));
                if (flightType === SW_FLIGHT_TYPES.RETURN) dispatch(setSwState({focusedComponent: COMPONENTS.ARRIVAL_REF}))
                if (flightType === SW_FLIGHT_TYPES.ONE_WAY) dispatch(setSwState({focusedComponent: COMPONENTS.NONE}));
                dispatch(retrieveStopoverAvailability());
            }}
            DatePickerProps={{
                pivot: departure ? departure : (arrival ? arrival : undefined)
            }}
            DropInputProps={{TextInputProps: {shrink: true}}}
            placement={'bottom-start'}
        />
    );
};

export default DepartureDatePickerConnected;