import React, {useRef, useState} from 'react';
import HelpIcon from "@mui/icons-material/Help";
import {Box, ClickAwayListener, IconButton, Popper, Typography, Fade} from "@mui/material";
import PropTypes from 'prop-types';
import useStyles from "./HelperIconInfoStyles";

const HelperIconInfo = ({title = null, subtitle = null, heading = null, children = null, color = null, backgroundColor = null, placement = 'left', icon = <HelpIcon/>, iconButtonProps = {}, popperProps =  {}, popperMessageProps = {}, arrowProps = {}}) => {

    const ref = useRef();
    const [anchorEl, setAnchorEl] = useState(ref.current);
    const classes = useStyles();

    return (
        <ClickAwayListener mouseEvent={'onMouseDown'} touchEvent={'onTouchStart'} onClickAway={() => anchorEl && setAnchorEl(null)}>
            <Box>
                <IconButton
                    role={'button'}
                    aria-label={'open helper info'}
                    onClick={(e) => setAnchorEl(!anchorEl ? e.currentTarget : null)}
                    size={'small'}
                    color={'secondary'}
                    {...iconButtonProps}
                >
                    {icon}
                </IconButton>
                <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} placement={placement} style={{zIndex: 1300}} transition {...popperProps}>
                    {({placement: popperPlacement, TransitionProps}) => (
                        <Fade {...TransitionProps}>
                            <Box>
                                <Box className={classes[`${popperPlacement}PlacementTriangle`]} style={{borderColor: backgroundColor}} {...arrowProps}/>
                                <Box className={classes.popperMessage} style={{backgroundColor, color}} {...popperMessageProps}>
                                    {title && (
                                        <Typography variant={'subtitle2'} className={classes.popperTitle}>
                                            {title}
                                        </Typography>
                                    )}
                                    {subtitle && (
                                        <Typography variant={'caption'} className={classes.popperText}>
                                            {subtitle}
                                        </Typography>
                                    )}
                                    {heading && (
                                        <Typography variant={'caption'} className={classes.popperText} display={'block'}>
                                            {heading}
                                        </Typography>
                                    )}
                                    {children}
                                </Box>
                            </Box>
                        </Fade>
                    )}
                </Popper>
            </Box>
        </ClickAwayListener>
    );
};

HelperIconInfo.propTypes = {
    title: PropTypes.any,
    subtitle: PropTypes.any,
    heading: PropTypes.any,
    children: PropTypes.any,
    placement: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
    color: PropTypes.string,
    backgroundColor: PropTypes.string,
    icon: PropTypes.node,
    iconButtonProps: PropTypes.object,
    popperMessageProps: PropTypes.object,
    popperProps: PropTypes.object,
    arrowProps: PropTypes.object,
};


export default HelperIconInfo;