import React from 'react';
import PropTypes from 'prop-types';
import styles from './Year.module.scss';
import {Box} from "@mui/material";


const Year = ({children = null, disabled = false, shy = false, selected = false, clickHandler = () => null}) => {

    const classes = [styles.year];
    if (shy || disabled) classes.push(styles.shy);
    if (disabled) classes.push(styles.disabled);
    if (selected) classes.push(styles.selected);

    const monthClickHandler = disabled ? () => null : clickHandler;

    return (
        <Box className={styles.container}>
            <Box className={styles.dummy}/>
            <Box className={classes.join(' ')} onClick={monthClickHandler}>
                <Box className={styles.content}>
                    {children}
                </Box>
            </Box>
        </Box>

    );
};

Year.propTypes = {
    children: PropTypes.any,
    disabled: PropTypes.bool,
    selected: PropTypes.bool,
    shy: PropTypes.bool,
    clickHandler: PropTypes.func,
};
export default Year;
