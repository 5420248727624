import React from 'react';
import PropTypes from 'prop-types';
import styles from "./BlurAwareContainer.module.scss";
import ClickAwayListener from "@mui/material/ClickAwayListener";


const BlurAwareContainer = ({containerRef, children, classes = '', tabIndex = 0, onClickAway = e => null, fullWidth = false, ...props}) => {

    return (
        <ClickAwayListener
            mouseEvent={'onMouseDown'}
            touchEvent={'onTouchStart'}
            onClickAway={onClickAway}>
            <div ref={containerRef}
                 className={[styles.blurAwareContainer, fullWidth ? styles.fullWidth : '', classes].join(' ')}
                 tabIndex={tabIndex}
                 {...props}
            >
                {children}
            </div>
        </ClickAwayListener>

    );
};

BlurAwareContainer.propTypes = {
    containerRef: PropTypes.any.isRequired,
    children: PropTypes.any.isRequired,
    classes: PropTypes.string,
    tabIndex: PropTypes.number,
    onClickAway: PropTypes.func,
    fullWidth: PropTypes.bool,
};



export default BlurAwareContainer;