import React from 'react';
import PropTypes from 'prop-types';
import {AzoButton} from "../../../index";
import {Typography} from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import useStyles from "./RailFlyOpenButtonStyles";


const RailFlyOpenButton = ({
                               title = 'Add Rail&Fly',
                               subtitle = 'Click to add',
                               onClick = null,
                               endIcon = <ArrowDropDownIcon/>,
                               disabled = false,
                               hideSubtitle = false
                           }) => {

    const classes = useStyles();

    return (
        <AzoButton
            className={classes.button}
            onClick={onClick}
            disabled={disabled}
            variant={'contained'}
            color={'secondary'}
            size={'medium'}
            disableElevation
            fullWidth
            classes={{
                label: classes.buttonLabel
            }}>
            <Typography className={classes.title} variant={'subtitle1'}>
                {title}
            </Typography>
            <Typography variant={'subtitle1'} className={classes.subtitle}>
                {!hideSubtitle && subtitle} {endIcon}
            </Typography>
        </AzoButton>
    );
};

RailFlyOpenButton.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    onClick: PropTypes.func,
    endIcon: PropTypes.node,
    disabled: PropTypes.bool,
    hideSubtitle: PropTypes.bool,
};

export default RailFlyOpenButton;