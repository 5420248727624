import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(theme => ({
    button: {
        borderRadius: isMobile => isMobile ? '50%' : '18px',
        height: '37px',
        minWidth: '37px',
    },
    buttonStartIcon: isMobile => isMobile ? {marginRight: '-2px'} : {}
}));

export default useStyles;