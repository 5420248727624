import React from 'react';
import PropTypes from 'prop-types';
import {Box, TextField} from '@mui/material';
import styles from './TextInput.module.scss';
import useStyles from './TextInputStyles';

const TextInput = ({containerProps= {}, variant = 'standard', readOnly= false, margin = 'none', fullWidth, infiniteOptions = {}, borderRadius= null, borderWidth, disabled, shrink = false, flat = false, ariaLabel, ariaDisabled = false, error= false, ...props}) => {

    const classes = useStyles({...infiniteOptions, borderRadius, borderWidth, disabled, error});

    const containerClasses = ['azo-text-input', styles.container];
    if (fullWidth) containerClasses.push(styles.fullWidth);

    if (variant !== 'azo') {
        return (
            <Box className={containerClasses.join(' ')} {...containerProps}>
                <TextField
                    role={'textbox'}
                    variant={variant}
                    readOnly={readOnly}
                    margin={margin}
                    fullWidth={fullWidth}
                    disabled={disabled}
                    // aria-disabled={ariaDisabled}
                    error={error}
                    // aria-label={ariaLabel}
                    // aria-labelledby={ariaLabel}
                    inputProps={{
                        // 'aria-label': ariaLabel || props.label,
                        // 'aria-labelledby': ariaLabel || props.label,
                        // 'aria-disabled': ariaDisabled,
                        ...props.inputProps,
                    }}
                    {...props}
                />
            </Box>
        );
    }

    return (
        <Box className={containerClasses.join(' ')} {...containerProps}>
            <TextField
                margin={margin}
                fullWidth={fullWidth}
                className={classes.root}
                disabled={disabled}
                // aria-disabled={ariaDisabled}
                // aria-label={ariaLabel}
                // aria-labelledby={ariaLabel}
                error={error}
                {...props}
                InputLabelProps={{
                    classes: {
                        root: classes.labelRoot,
                        shrink: classes.labelShrink,
                        disabled: classes.labelDisabled,
                        error: classes.labelError,
                    },
                    shrink: Boolean(shrink) || undefined,
                    ...props.InputLabelProps,
                }}
                InputProps={{
                    classes: {
                        input: [classes.input, flat ? classes.inputFlatted : ''].join(' '),
                        disabled: classes.inputDisabled,
                        notchedOutline: classes.inputNotchedOutline
                    },
                    ...props.InputProps,
                }}
                inputProps={{
                    ...(readOnly ? {readOnly: true, tabIndex: -1} : {}),
                    // 'aria-label': ariaLabel || props.label,
                    // 'aria-labelledby': ariaLabel || props.label,
                    // 'aria-disabled': ariaDisabled,
                    // 'role': 'textbox',
                    ...props.inputProps,
                }}
                FormHelperTextProps={{
                    className: classes.helpText,
                    variant: 'standard',
                    ...props.FormHelperTextProps,
                }}
            />
        </Box>
    );
};

TextInput.propTypes = {
    containerProps: PropTypes.object,
    autoComplete: PropTypes.string,
    autoFocus: PropTypes.bool,
    ariaLabel: PropTypes.string,
    ariaDisabled: PropTypes.bool,
    children: PropTypes.node,
    classes: PropTypes.object,
    className: PropTypes.string,
    color: PropTypes.oneOf(['primary', 'secondary']),
    defaultValue: PropTypes.any,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    FormHelperTextProps: PropTypes.object,
    fullWidth: PropTypes.bool,
    helperText: PropTypes.node,
    hiddenLabel: PropTypes.bool,
    id: PropTypes.string,
    InputLabelProps: PropTypes.object,
    inputProps: PropTypes.object,
    InputProps: PropTypes.object,
    inputRef: PropTypes.any,
    label: PropTypes.node,
    margin: PropTypes.oneOf(['dense', 'none', 'normal']),
    multiline: PropTypes.bool,
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    rows: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    rowsMax: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    select: PropTypes.bool,
    SelectProps: PropTypes.object,
    size: PropTypes.oneOf(['medium', 'small']),
    type: PropTypes.string,
    value: PropTypes.any,
    variant: PropTypes.oneOf(['filled', 'outlined', 'standard', 'azo']),
    readOnly: PropTypes.bool,
    infiniteOptions: PropTypes.shape({
        infiniteTop: PropTypes.bool,
        infiniteRight: PropTypes.bool,
        infiniteBottom: PropTypes.bool,
        infiniteLeft: PropTypes.bool,
    }),
    borderRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    borderWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    shrink: PropTypes.bool,
    flat: PropTypes.bool,
};

export default TextInput;
