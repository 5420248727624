import React, {useEffect, useState} from 'react';
import {RouteSelector} from '../../../../index';
import {useDispatch, useSelector} from "react-redux";
import {
    syncMainLegBetweenFlows,
    setSwState,
    setSwBookState,
    retrieveStopoverAvailability,
} from "../../../../../store/reducers/search-widget";
import {COMPONENTS} from "../../common/book-form-input-refs";
import {useMediaQuery, useTheme} from "@mui/material";
import {t} from 'ttag';
import {SW_PASSENGER_TYPES} from '../../../../../store/types';


const RouteSelectorConnected = () => {

    const {breakpoints} = useTheme();
    const isMobile = useMediaQuery(breakpoints.down('sm'));
    const flightRoutesAirports = useSelector(state => state.sw.flightRoutesAirports);
    const fullscreen = useSelector(state => state.sw.book.fullscreen);
    const origin = useSelector(state => state.sw.book.origin);
    const destination = useSelector(state => state.sw.book.destination);
    const passengerType = useSelector(state => state.sw.book.passengerType);
    const withMiles = useSelector(state => state.sw.book.withMiles);
    const isResidentOrStudent = [SW_PASSENGER_TYPES.RESIDENT, SW_PASSENGER_TYPES.STUDENT].includes(passengerType);
    const [airports, setAirports] = useState([])
    const dispatch = useDispatch();

    useEffect(() => {
        if (flightRoutesAirports) {
            withMiles ? setAirports([...flightRoutesAirports.filter(a => a.award === true)]) : setAirports([...flightRoutesAirports])
        }
    }, [flightRoutesAirports, withMiles]);

    const isOSP = (o, d) => o?.countryName === 'Portugal' && d?.countryName === 'Portugal' && (o?.azores || d?.azores);

    return (
        <RouteSelector
            airportsData={airports}
            onChange={cityPair => {
                dispatch(setSwBookState({
                    origin: cityPair?.origin,
                    destination: cityPair?.destination,
                }));
                dispatch(syncMainLegBetweenFlows({toMultiCity: true}));
                if (isResidentOrStudent && !isOSP(cityPair?.origin, cityPair?.destination)) {
                    dispatch(setSwBookState({
                        passengerType: 'ADULT',
                        alertMessage: {severity: 'warning', children: t`Passenger type was changed.`}
                    }));
                }
                if (fullscreen) dispatch(setSwState({focusedComponent: COMPONENTS.DEPARTURE_REF}));
                dispatch(retrieveStopoverAvailability());
                // dispatch(retrieveRailFlyAvailability());
            }}
            origin={origin}
            destination={destination}
            variant={isMobile ? 'stacked' : 'normal'}
            originPlaceholder={!isMobile && fullscreen ? t`Departure from...` : undefined}
            destinationPlaceholder={!isMobile && fullscreen ? t`Where do you want to go?` : undefined}
            compressed={!isMobile && !fullscreen}
        />
    );
};

export default RouteSelectorConnected;