import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setSwState} from "../../../../store/reducers/search-widget";
import {TabBar} from "../../../index";
import {t} from 'ttag';
import {SW_TABS} from "../../../../store/types";

const TabBarConnected = () => {

    const selectedTab = useSelector(state => state.sw.selectedTab);
    const dispatch = useDispatch();

    return (
        <TabBar
            tabs={[
                {label: t`BOOK`, value: SW_TABS.BOOK},
                {label: t`CHECK-IN`, value: SW_TABS.CHECK_IN},
                {label: t`FLIGHT STATUS`, value: SW_TABS.FLIGHT_STATUS},
                {label: t`MY TRIP`, value: SW_TABS.MY_TRIP},
            ]}
            selected={selectedTab}
            onSelect={(e, tabValue) => dispatch(setSwState({selectedTab: tabValue}))}
        />
    );
};

export default TabBarConnected;