import React from 'react';
import PropTypes from 'prop-types';
import {DropInput, DropInputContent} from "../../../../../index";
import {MenuList, MenuItem} from "@mui/material";


const StopoverLegIslandSelect = ({
                                     label = '',
                                     placeholder = '',
                                     value = '',
                                     onSelect = () => null,
                                     onFocus = () => null,
                                     onBlur = () => null,
                                     open = false,
                                     disabled = false,
                                     fullWidth = false,
                                     containerRef = undefined,
                                     disabledIslands = [],
                                     loading = false,
                                     DropInputProps = {},
                                 }) => {

    return (
        <DropInput
            label={label}
            placeholder={placeholder}
            value={(() => {
                if (value === 'PDL') return 'São Miguel';
                if (value === 'TER') return 'Terceira';
                return value;
            })()}
            containerRef={containerRef}
            onFocus={onFocus}
            onBlur={onBlur}
            open={open}
            disabled={disabled}
            fullWidth={fullWidth}
            loading={loading}
            readOnly
            TextInputProps={{
                shrink: true,
            }}
            {...DropInputProps}
        >
            <DropInputContent>
                <MenuList>
                    <MenuItem onClick={() => onSelect('PDL')} disabled={disabledIslands.includes('PDL')}>São
                        Miguel</MenuItem>
                    <MenuItem onClick={() => onSelect('TER')}
                              disabled={disabledIslands.includes('TER')}>Terceira</MenuItem>
                </MenuList>
            </DropInputContent>
        </DropInput>
    );
};

StopoverLegIslandSelect.propTypes = {
    label: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    onSelect: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    open: PropTypes.bool,
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    loading: PropTypes.bool,
    containerRef: PropTypes.any,
    disabledIslands: PropTypes.arrayOf(PropTypes.oneOf(['PDL', 'TER'])),
    DropInputProps: PropTypes.object,
};


export default StopoverLegIslandSelect;