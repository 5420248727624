import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: isMobile => isMobile ? 'wrap' : 'initial',
        width: '100%'
    },
    islandAndSwitchContainer: {
        display: 'flex',
        // width: isMobile => isMobile ? '100%' : 'auto',
        flexGrow: 1,
    },
    numberOfNightsPickerContainer: {
        width: isMobile => isMobile ? '100%' : 'auto',
    },
}));

export default useStyles;