import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(theme => ({
    img: {
        width: '100%',
    },
    origin: {
        maxWidth: '120px',
        fontWeight: 'bold',
        opacity: .7,
    },
    title: {
        position: 'absolute',
        width: '100%',
        top: '-5px',
        textAlign: 'center',
        fontWeight: 'bold',
    },
    destination: {
        maxWidth: '120px',
        fontWeight: 'bold',
        opacity: .7,
    },
    truncate: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    }
}));

export default useStyles;