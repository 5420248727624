import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    tabListItemRoot: {
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        '&:hover': {
            color: theme.palette.secondary.main,
        }
    },
    tabListItemRootSelected: {
        backgroundColor: theme.palette.primary.contrastText,
        borderBottom: `1px solid rgba(137, 137, 137, .4)`,
    },
    tabCollapseControl: {
        color: theme.palette.secondary.light
    },
    listItemText: {
        color: theme.palette.secondary.main,
        fontFamily: 'OpenSansMedium',
    }
}));

export default useStyles;