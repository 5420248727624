import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setSwBookState} from "../../../../../store/reducers/search-widget";
import {CrossSelling} from "../../../../index";

const CrossSellingConnected = () => {

    const crossSellingTab = useSelector(state => state.sw.book.crossSellingTab);
    const dispatch = useDispatch();

    return (
        <CrossSelling
            tab={crossSellingTab}
            onChange={crossSellingTab => dispatch(setSwBookState({crossSellingTab}))}
        />
    );
};

export default CrossSellingConnected;