import React from 'react';
import {FlightTypeSelector} from "../../../../index";
import {useDispatch, useSelector} from "react-redux";
import {
    retrieveStopoverAvailability,
    setSwBookState
} from "../../../../../store/reducers/search-widget";
import {SW_FLIGHT_TYPES} from "../../../../../store/types";

const FlightTypeSelectorConnected = () => {

    const flightType = useSelector(state => state.sw.book.flightType);
    const withMiles = useSelector(state => state.sw.book.withMiles);
    const promoCode = useSelector(state => state.sw.book.promoCode);
    const multiCityFlowDisabled = Boolean(withMiles || promoCode);
    const dispatch = useDispatch();

    return (
        <FlightTypeSelector
            type={flightType}
            onChange={type => {
                dispatch(setSwBookState({flightType: type}));
                dispatch(retrieveStopoverAvailability());
            }}
            disabledTypes={multiCityFlowDisabled ? [SW_FLIGHT_TYPES.MULTI] : []}
        />
    );
};

export default FlightTypeSelectorConnected;