import React from 'react';
import PropTypes from 'prop-types';
import styles from './Calendar.module.scss';
import moment from "moment";
import Day from "./Day/Day";
import {Box} from "@mui/material";

const isFunction = fn => {
    return fn && {}.toString.call(fn) === '[object Function]';
};

const Calendar = ({
                      children = null,
                      pivot = moment(),
                      start = null,
                      end = null,
                      disabled = false,
                      pastDisabled = false,
                      todayDisabled = false,
                      weekStartsOn = 1,
                      selectDayHandler = () => null,
                      inlineStyle = {},
                      availableRange = null,
                      selectingRange = null,
                      hoveredDayHandler = () => null,
                      filterDaysToDisableHandler = () => false,
                      keyAnchor = moment(),
                      overrideDayRender = null,
                      monthOverflowDaysVisible = true,
                      t = text => text
                  }) => {

    const calendarClasses = disabled ? ['calendarPickerFixedClass', styles.calendar, styles.disabled].join(' ') : styles.calendar;

    const unit = 'days';
    const today = moment();
    const weekDays = moment.weekdays();
    const weekSortedDays = [...weekDays].splice(weekStartsOn).concat([...weekDays].splice(0, weekStartsOn));
    const monthFirstDay = moment(pivot.clone().startOf('month').format('YYYY-MM-DD 12:00:00'));
    const monthLastDay = moment(pivot.clone().endOf('month').format('YYYY-MM-DD 12:00:01'));
    const monthFirstDayIdx = weekSortedDays.indexOf(monthFirstDay.clone().format('dddd'));
    const monthLastDayIdx = weekSortedDays.indexOf(monthLastDay.clone().format('dddd'));
    const calendarFirstDay = monthFirstDay.clone().subtract(monthFirstDayIdx, unit);
    const calendarLastDay = monthLastDay.clone().add(weekSortedDays.length - monthLastDayIdx - 1, unit);

    const weekDaysJSX = weekSortedDays.map(weekDay => (
        <Box className={styles.weekDay} key={weekDay}
             title={t(weekDay)}>{t(weekDay).substring(0, 3).toLowerCase()}</Box>)
    );

    const days = [];
    for (let d = calendarFirstDay.clone(); d.isBefore(calendarLastDay.clone()); d.add(1, unit)) {
        const current = d.clone();
        const isToday = current.isSame(today, unit);

        const isKeyAnchor = current.isSame(keyAnchor, unit);
        const isOutOfMonthRange = current.isBefore(monthFirstDay) || current.isAfter(monthLastDay);
        const isOutOfAvailableRange = availableRange
            ? current.isBefore(availableRange.start, unit) || current.isAfter(availableRange.end, unit)
            : false;
        const selectingMode = selectingRange
            ? current.isSameOrAfter(selectingRange.start, unit) && current.isSameOrBefore(selectingRange.end, unit)
            : false;
        const isSelected = current.isSameOrAfter(start, unit) && current.isSameOrBefore(end, unit);
        const isDisabled = disabled || isOutOfAvailableRange || (current.isBefore(today, unit) && pastDisabled) || (isToday && todayDisabled) || filterDaysToDisableHandler(current.clone());
        const isWeekend = current.weekday() === 6 || current.weekday() === 0;
        const isToRightSelection = selectingMode ? current.isSame(selectingRange.start, unit) : current.isSame(start, unit);
        const isToLeftSelection = selectingMode ? current.isSame(selectingRange.end, unit) : current.isSame(end, unit);

        days.push(
            <Day key={current.format('day-YYYY-MM-DD')}
                 isToday={isToday}
                 selected={isSelected}
                 disabled={isDisabled}
                 isWeekend={isWeekend}
                 isKeyAnchor={isKeyAnchor}
                 selectingMode={selectingMode}
                 isInvisible={!monthOverflowDaysVisible && isOutOfMonthRange}
                 clickHandler={() => selectDayHandler(current.clone())}
                 mouseEnterHandler={() => hoveredDayHandler(current.clone())}
                //mouseLeaveHandler={() => hoveredDayHandler(start.clone())}
                 toRightSelection={isToRightSelection}
                 toLeftSelection={isToLeftSelection}
                 shy={isOutOfMonthRange}>
                {isFunction(overrideDayRender) ? overrideDayRender(current) : current.format('D')}
            </Day>
        );
    }

    return (
        <Box className={calendarClasses} style={inlineStyle}>
            <Box className={styles.weekDaysContainer}>{weekDaysJSX}</Box>
            <Box className={styles.daysContainer}>{days}</Box>
            {children}
        </Box>
    );
};

Calendar.propTypes = {
    inlineStyle: PropTypes.object,
    monthOverflowDaysVisible: PropTypes.bool,
    selectDayHandler: PropTypes.func,
    hoveredDayHandler: PropTypes.func,
    filterDaysToDisableHandler: PropTypes.func,
    children: PropTypes.any,
    disabled: PropTypes.bool,
    pastDisabled: PropTypes.bool,
    todayDisabled: PropTypes.bool,
    pivot: PropTypes.instanceOf(moment),
    keyAnchor: PropTypes.instanceOf(moment),
    start: PropTypes.instanceOf(moment),
    end: PropTypes.instanceOf(moment),
    weekStartsOn: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6]),
    availableRange: PropTypes.shape({
        start: PropTypes.instanceOf(moment),
        end: PropTypes.instanceOf(moment)
    }),
    selectingRange: PropTypes.shape({
        start: PropTypes.instanceOf(moment),
        end: PropTypes.instanceOf(moment)
    }),
    t: PropTypes.func,
    overrideDayRender: PropTypes.func
};


export default Calendar;
