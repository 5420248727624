import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import './config/i18n/i18n';
import React from 'react';
import { createRoot } from "react-dom/client";
import { createTheme, ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import App from './App';
import * as serviceWorker from './serviceWorker';
import store from './store/store';
import { Provider } from 'react-redux';
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "./components/Common/ErrorFallback/ErrorFallback";
import themeData from './resources/theme/theme';
import DevToolsConnected from "./components/DevTools/DevToolsConnected";

const theme = createTheme(themeData);

const root = createRoot(document.getElementById('app-search-widget'));
root.render(
    <StyledEngineProvider injectFirst>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <ThemeProvider theme={theme}>
                <Provider store={store}>
                    <App />
                    <DevToolsConnected />
                </Provider>
            </ThemeProvider>
        </ErrorBoundary>
    </StyledEngineProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();