import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const SyncAltIcon = props => (
    <SvgIcon width="54.156" height="44" viewBox="0 0 54.156 44" {...props}>
        <path
            d="M1215.521,519.146a2.536,2.536,0,0,1,2.538-2.537h40.4l-3.221-2.935a2.539,2.539,0,0,1-.052-3.636l1.148-1.148a2.54,2.54,0,0,1,3.59,0l8.76,8.711a3.384,3.384,0,0,1,0,4.784l-8.76,8.711a2.54,2.54,0,0,1-3.59,0l-1.148-1.145a2.545,2.545,0,0,1,.052-3.641l3.221-2.932h-40.4a2.538,2.538,0,0,1-2.538-2.54Zm54.155,20.308v1.692a2.542,2.542,0,0,1-2.54,2.54h-40.4l3.224,2.932a2.545,2.545,0,0,1,.052,3.641l-1.15,1.145a2.537,2.537,0,0,1-3.587,0l-8.763-8.711a3.388,3.388,0,0,1,0-4.784l8.763-8.711a2.537,2.537,0,0,1,3.587,0l1.15,1.148a2.539,2.539,0,0,1-.052,3.636l-3.224,2.935h40.4A2.54,2.54,0,0,1,1269.677,539.454Z"
            transform="translate(-1215.521 -508.147)"
        />
    </SvgIcon>
);

export default SyncAltIcon;