import {makeStyles} from "@mui/styles";

const withStyles = makeStyles(theme => ({
    root: {
        maxHeight: '288px',
        '& td': {
            padding: '6px'
        }
    },
}));

export default withStyles;