import React from 'react';
import PropTypes from 'prop-types';
import {Grid2} from "@mui/material";
import {TabBarCrossSelling} from "../../index";
import {SW_CROSS_SELLING_TABS} from "../../../store/types";
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
// import DeckIcon from '@mui/icons-material/Deck';
import LocalHotelIcon from '@mui/icons-material/LocalHotel';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
// import LandscapeIcon from '@mui/icons-material/Landscape';
import {t} from 'ttag';

const CrossSelling = ({tab, onChange = () => null}) => {

    return (
        <Grid2 spacing={2} container>
            <Grid2 size={{xs: 12}} >
                <TabBarCrossSelling
                    selected={tab}
                    //onSelect={(e, v) => onChange(v)}
                    hideMenuWhenAllTabsVisible
                    tabs={[
                        {
                            label: t`FLIGHT`,
                            icon: <FlightTakeoffIcon color={'secondary'} style={{marginRight: '5px'}}/>,
                            value: SW_CROSS_SELLING_TABS.FLIGHT,
                        },
                        /*{
                            label: t`EXPERIENCES`,
                            icon: <DeckIcon color={'secondary'} style={{marginRight: '5px'}}/>,
                            value: SW_CROSS_SELLING_TABS.EXPERIENCES,
                            href: 'https://azoresgetaways.com/experiences?affiliateCode=azoresairlines',
                            openInNewTab: true,
                        },*/
                        {
                            label: t`HOTEL`,
                            icon: <LocalHotelIcon color={'secondary'} style={{marginRight: '5px'}}/>,
                            value: SW_CROSS_SELLING_TABS.HOTEL,
                            href: 'https://www.booking.com/index.html?aid=363553&label=sata-homepage-sb',
                            openInNewTab: true,
                        },
                        {
                            label: t`CAR`,
                            icon: <DirectionsCarIcon color={'secondary'} style={{marginRight: '5px'}}/>,
                            value: SW_CROSS_SELLING_TABS.CAR,
                            href: 'https://www.booking.com/cars/index.html?aid=363553&label=pagesbtab-ban-363553-click',
                            openInNewTab: true,
                        },
                        /*{
                            label: t`ACTIVITIES`,
                            icon: <LandscapeIcon color={'secondary'} style={{marginRight: '5px'}}/>,
                            value: SW_CROSS_SELLING_TABS.ACTIVITIES,
                            href: 'https://azoresgetaways.com/experiences?affiliateCode=azoresairlines',
                            openInNewTab: true,
                        },*/
                        {
                            label: t`PARKING`,
                            icon: <LocalParkingIcon color={'secondary'} style={{marginRight: '5px'}}/>,
                            value: SW_CROSS_SELLING_TABS.PARKING,
                            href: 'https://sataazores.parkvia.com',
                            openInNewTab: true,
                        }
                    ]}
                />
            </Grid2>
        </Grid2>
    );
};

CrossSelling.propTypes = {
    tab: PropTypes.oneOf(Object.values(SW_CROSS_SELLING_TABS)),
    onChange: PropTypes.func,
};



export default CrossSelling;
