import {makeStyles} from "@mui/styles";


const useStyles = makeStyles(theme => ({
    root: {
        background: 'white',
        borderStyle: 'solid',
        opacity: ({disabled}) => disabled ? '.4' : '1',
        borderColor: ({error}) => error ? theme.palette.error.main : theme.palette.secondary.main,
        borderWidth: ({infiniteTop, infiniteRight, infiniteBottom, infiniteLeft, borderWidth}) => {
            if (borderWidth != null) return borderWidth
            let top = '1px', right = '1px', bottom = '1px', left = '1px';
            if (infiniteTop) top = '0';
            if (infiniteRight) right = '0';
            if (infiniteBottom) bottom = '0';
            if (infiniteLeft) left = '0';
            return `${top} ${right} ${bottom} ${left}`;
        },
        borderRadius: ({infiniteTop, infiniteRight, infiniteBottom, infiniteLeft, borderRadius}) => {
            if (borderRadius != null) return borderRadius;
            let tl = '5px', tr = '5px', br = '5px', bl = '5px';
            if (infiniteTop) {
                tl = '0';
                tr = '0';
            }
            if (infiniteRight) {
                tr = '0';
                br = '0';
            }
            if (infiniteBottom) {
                br = '0';
                bl = '0';
            }
            if (infiniteLeft) {
                tl = '0';
                bl = '0';
            }
            return `${tl} ${tr} ${br} ${bl}`;
        },
    },
    labelRoot: {
        color: theme.palette.primary.main,
        textTransform: 'uppercase',
        fontSize: '.8rem',
        fontWeight: 'bold',
        letterSpacing: '0.1rem',
        top: theme.spacing(.5),
    },
    labelShrink: {
        top: theme.spacing(2.2),
    },
    input: {
        color: theme.palette.primary.main,
        padding: '30px 14px 12px 14px',
        fontSize: '17px',
        fontWeight: 'normal',
    },
    inputFlatted: {
        padding: '1rem!important'
    },
    inputNotchedOutline: {
        border: 'none',
    },
    helpText: {
        fontSize: theme.typography.caption.fontSize,
        marginTop: 0,
    },
    inputDisabled: {
        opacity: '.4',
        cursor: 'not-allowed'
    },
    labelDisabled: {
        opacity: '.4',
        cursor: 'not-allowed'
    },
    labelError: {
        color: theme.palette.error.main,
    },
}));

export default useStyles;