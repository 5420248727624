import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(theme => ({
    button: {
        borderRadius: '50px',
        width: '100%',
        maxHeight: '60px'
    },
    buttonLabel: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    title: {
        '@media (max-width: 425px)': {
            fontSize: '.9rem',
        },
        lineHeight: 1.2
    },
    subtitle: {
        display: 'flex',
        alignItems: 'center',
        lineHeight: 1.2
    }
}));

export default useStyles;