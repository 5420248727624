import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setSwBookStopoverState} from "../../../../../store/reducers/search-widget";
import {Grid2, useMediaQuery, useTheme} from "@mui/material";
import {StopoverOpenButton, useIsStopoverEligibleConnected} from "../../../../index";
import {t} from 'ttag';

const BookStopoverOpenButtonConnected = () => {

    const {breakpoints} = useTheme();
    const isMobile = useMediaQuery(breakpoints.down('sm'));
    const isStopoverEligible = useIsStopoverEligibleConnected();
    const open = useSelector(state => state.sw.book.stopover.open);
    const dispatch = useDispatch();

    if (open || !isStopoverEligible) return null;

    return (
        <Grid2 size={{xs: 12}}>
            <StopoverOpenButton
                title={t`Add an AZORES STOPOVER to your trip`}
                subtitle={t`Click here to add`}
                hideSubtitle={isMobile}
                onClick={() => dispatch(setSwBookStopoverState({open: true}))}
            />
        </Grid2>
    );
};

export default BookStopoverOpenButtonConnected;