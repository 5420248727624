import React from 'react';
import PropTypes from 'prop-types';
import {AzoButton} from "../../../index";
import {Typography, useMediaQuery, useTheme} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import useStyles from "./StopoverCloseButtonStyles";

const StopoverCloseButton = ({
                                 title = 'Skip Stopover',
                                 onClick = () => null,
                                 icon = <CloseIcon/>,
                                 disabled = false,
                                 ...props
                             }) => {

    const {breakpoints} = useTheme();
    const isMobile = useMediaQuery(breakpoints.down('sm'));
    const classes = useStyles(isMobile);

    return (
        <AzoButton
            classes={{
                root: classes.button,
                startIcon: classes.buttonStartIcon
            }}
            onClick={onClick}
            disabled={disabled}
            variant={'contained'}
            color={'secondary'}
            size={'small'}
            startIcon={icon}
            disableElevation
            {...props}
        >
            {!isMobile && <Typography variant={'subtitle1'}>{title}</Typography>}
        </AzoButton>
    );
};

StopoverCloseButton.propTypes = {
    title: PropTypes.string,
    onClick: PropTypes.func,
    icon: PropTypes.node,
    disabled: PropTypes.bool,
};


export default StopoverCloseButton;