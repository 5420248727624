import React from 'react';
import DevTools from './DevTools';
import {useDispatch, useSelector} from "react-redux";
import {setAppLanguage, setDevTools} from "../../store/reducers/app";

const DevToolsConnected = () => {

    const currentLanguage = useSelector(state => state.app.currentLanguage);
    const formData = useSelector(state => state.app.devTools);
    const dispatch = useDispatch();

    return (
        <DevTools
            formData={formData}
            onChange={devTools => dispatch(setDevTools(devTools))}
            currentLanguage={currentLanguage}
            changeLanguageHandler={lang => dispatch(setAppLanguage(lang))}
            showLanguage={true}
        />
    );
};

export default DevToolsConnected;