import React from 'react';
import PropTypes from 'prop-types';
import styles from './TabBar.module.scss'
import {Tab, Tabs} from '@mui/material';

const TabBar = ({tabs= [], tabProps = {}, selected, onSelect = null}) => {

    return (
        <Tabs value={selected}
              onChange={onSelect}

              aria-label='sw tab bar'
              classes={{
                  root: ['azo-sw-tabs-container', styles.tabsRoot].join(' '),
                  indicator: styles.tabsIndicator
              }}>
            {tabs.map((tab, idx) => (
                <Tab
                    key={idx}
                    label={tab.label}
                    value={tab.value}

                    style={{minWidth: 100/tabs.length + '%', maxWidth: 100/tabs.length + '%'}}
                    classes={{
                        root: [styles.tabRoot, selected === tab.value ? styles.tabRootSelected : ''].join(' '),
                        wrapper: [styles.tabWrapper, selected === tab.value ? styles.tabWrapperSelected : ''].join(' ')
                    }}
                    {...tabProps}
                />
            ))}
        </Tabs>

    );
};

TabBar.propTypes = {
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.any,
            value: PropTypes.string
        })
    ),
    tabProps: PropTypes.object,
    selected: PropTypes.string,
    onSelect: PropTypes.func,
};


export default TabBar;