import React from 'react';
import PropTypes from 'prop-types';
import {Box, Typography} from "@mui/material";


const PassengerTypeTypography = ({
                                     title = '',
                                     subtitle = '',
                                     boxProps = {},
                                     titleTypographyProps = {},
                                     subtitleTypographyProps = {},
                                     disabled = false,
                                 }) => {

        return (
            <Box style={disabled ? {opacity: '.4'} : {}} {...boxProps}>
                <Typography {...titleTypographyProps}>{title}</Typography>
                <Typography variant={'caption'} style={{color: 'rgb(118,118,118)'}}
                            gutterBottom {...subtitleTypographyProps}>{subtitle}</Typography>
            </Box>
        );
    }
;

PassengerTypeTypography.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    boxProps: PropTypes.object,
    titleTypographyProps: PropTypes.object,
    subtitleTypographyProps: PropTypes.object,
    disabled: PropTypes.bool,
};


export default PassengerTypeTypography;