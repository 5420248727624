import React from 'react';
import PropTypes from 'prop-types';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {Typography, useMediaQuery, useTheme} from "@mui/material";
import useStyles from "./Rail&FlyButtonStyles";
import {AzoButton} from "../../../index";


const RailFlyButton = ({
                           title = '',
                           onClick = null,
                           icon = <ArrowForwardIosIcon/>,
                           disabled = false,
                           ...props
                       }) => {

    const {breakpoints} = useTheme();
    const isMobile = useMediaQuery(breakpoints.down('sm'));
    const classes = useStyles();

    return (
        <AzoButton
            onClick={onClick}
            disabled={disabled}
            variant={'contained'}
            color={'secondary'}
            size={'large'}
            tall
            fullWidth
            {...props}
        >
            {isMobile
                ? <Typography className={classes.title} variant={'subtitle1'}>{title}</Typography>
                : <Typography className={classes.title} variant={'subtitle1'} component={'div'}>{title}</Typography>
            }
        </AzoButton>
    );
};

RailFlyButton.propTypes = {
    title: PropTypes.string,
    onClick: PropTypes.func,
    icon: PropTypes.node,
    disabled: PropTypes.bool,
};

export default RailFlyButton;