import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {setSwBookState, setSwState} from "../../../../../store/reducers/search-widget";
import {COMPONENTS, PASSENGERS_COUNT_REF} from "../../common/book-form-input-refs";
import {PassengersCountSelector, PassengerTypeSelector} from "../../../../index";
import Alert from "@mui/material/Alert";
import {t} from "ttag";

const PassengersCountSelectorConnected = () => {

    const adults = useSelector(state => state.sw.book.adults);
    const children = useSelector(state => state.sw.book.children);
    const infants = useSelector(state => state.sw.book.infants);
    const focusedComponent = useSelector(state => state.sw.focusedComponent);
    const passengerType = useSelector(state => state.sw.book.passengerType);
    const isStudent = passengerType === PassengerTypeSelector.PASSENGER_TYPES.STUDENT;
    const passengers = {adults, children, infants};
    const dispatch = useDispatch();

    return (
        <PassengersCountSelector
            formData={passengers}
            onChange={updatedPassengers => dispatch(setSwBookState(updatedPassengers))}
            onFocus={() => dispatch(setSwState({focusedComponent: COMPONENTS.PASSENGERS_COUNT_REF}))}
            onBlur={() => focusedComponent === COMPONENTS.PASSENGERS_COUNT_REF && dispatch(setSwState({focusedComponent: COMPONENTS.NONE}))}
            containerRef={PASSENGERS_COUNT_REF}
            disabledTypes={isStudent ? [PassengersCountSelector.TYPES.CHILDREN, PassengersCountSelector.TYPES.INFANTS] : []}
            innerHelperText={isStudent ? <Alert severity='warning'>{t`Infant or Child not eligible for the selected passenger type.`}</Alert> : null}
            open={focusedComponent === COMPONENTS.PASSENGERS_COUNT_REF}
        />
    );
};

export default PassengersCountSelectorConnected;