import React from 'react';
import {TabBarConnected, TabBarCollapsibleConnected, TabBarContentConnected} from '../index';
import {Box, useMediaQuery, useTheme} from '@mui/material';
import {useSelector} from "react-redux";

const SearchWidget = () => {

    const {breakpoints} = useTheme();
    const isMobile = useMediaQuery(breakpoints.down('sm'));
    const currentLanguage = useSelector(state => state.app.currentLanguage);

    if (isMobile) {
        return (
            <Box lang={currentLanguage}>
                <TabBarCollapsibleConnected>
                    <TabBarContentConnected/>
                </TabBarCollapsibleConnected>
            </Box>
        );
    }

    return (
        <Box bgcolor={'primary.translucent'} borderRadius={'7px'} lang={currentLanguage}>
            <TabBarConnected/>
            <TabBarContentConnected/>
        </Box>
    );
};

export default SearchWidget;