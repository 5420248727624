import React from 'react';
import PropTypes from 'prop-types';
import styles from './Day.module.scss';
import {debounce} from "lodash";
import {Box} from "@mui/material";

const Day = ({
                 isInvisible = false,
                 children = null,
                 disabled = false,
                 shy = false,
                 selected = false,
                 selectingMode = false,
                 isToday = false,
                 isWeekend = false,
                 clickHandler = () => null,
                 mouseEnterHandler = () => null,
                 mouseLeaveHandler = () => null,
                 isKeyAnchor = false,
                 toLeftSelection = false,
                 toRightSelection = false
             }) => {

    const dayClasses = [styles.day];
    if (shy || disabled) dayClasses.push(styles.shy);
    if (disabled) dayClasses.push(styles.disabled);
    if (selected) dayClasses.push(styles.selected);
    if (selectingMode) dayClasses.push(styles.selectingMode);
    if (isToday) dayClasses.push(styles.isToday);
    if (isWeekend) dayClasses.push(styles.isWeekend);
    if (isKeyAnchor) dayClasses.push(styles.isKeyAnchor);
    if (isInvisible) dayClasses.push(styles.invisible);
    if (toLeftSelection) dayClasses.push(styles.toLeftSelection);
    if (toRightSelection) dayClasses.push(styles.toRightSelection);

    const dayClickHandler = disabled ? () => null : clickHandler;
    const dayMouseEnterHandler = disabled ? () => null : mouseEnterHandler;
    const dayMouseLeaveHandler = disabled ? () => null : mouseLeaveHandler;

    return (
        <Box className={styles.dayContainer}>
            <Box className={styles.dummy}/>
            <Box className={dayClasses.join(' ')}
                 onClick={dayClickHandler}
                 onMouseEnter={debounce(dayMouseEnterHandler, 100)}
                 onMouseLeave={debounce(dayMouseLeaveHandler, 100)}>
                <Box className={styles.content}>
                    {children}
                </Box>
            </Box>
        </Box>

    );
};

Day.propTypes = {
    children: PropTypes.any,
    disabled: PropTypes.bool,
    isKeyAnchor: PropTypes.bool,
    selectingMode: PropTypes.bool,
    selected: PropTypes.bool,
    isToday: PropTypes.bool,
    isWeekend: PropTypes.bool,
    isInvisible: PropTypes.bool,
    shy: PropTypes.bool,
    toLeftSelection: PropTypes.bool,
    toRightSelection: PropTypes.bool,
    clickHandler: PropTypes.func,
    mouseEnterHandler: PropTypes.func,
    mouseLeaveHandler: PropTypes.func,
};

export default Day;
