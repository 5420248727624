import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    Box,
    FormControlLabel,
    Grid2,
    Link,
    Radio,
    RadioGroup,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import {t} from 'ttag';
import {StopoverCloseButton} from "../../../../index";
import {SW_FLIGHT_TYPES, SW_RAIL_FLY_LEG_TYPES} from "../../../../../store/types";
import {setSwBookRailFlyState} from "../../../../../store/reducers/search-widget";
import BookRailFlySubmitButtonConnected from "./BookRailFlySubmitButtonConnected";
import RailFlyAzoresImage from "../../../../Forms/Rail&Fly/Rail&FlyAzoresImage/Rail&FlyAzoresImage";
import RailFlyGridImages from "../../../../Forms/Rail&Fly/Rail&FlyGridImages/Rail&FlyGridImages";
import RailFlyPageTitle from "../../../../Forms/Rail&Fly/RailFlyPageTitle/RailFlyPageTitle";

const BookRailFlyConnected = () => {

    const {breakpoints} = useTheme();
    const isMobile = useMediaQuery(breakpoints.down('sm'));
    const isTablet = useMediaQuery(breakpoints.down('md'));

    const flightType = useSelector(state => state.sw.book.flightType);
    const origin = useSelector(state => state.sw.book.origin);
    const destination = useSelector(state => state.sw.book.destination);
    const dispatch = useDispatch();
    const disabledRailLegSelector = flightType === SW_FLIGHT_TYPES.ONE_WAY;


    return (
        <Box display={'flex'}
             position={'relative'}
             alignItems={'center'}
             justifyContent={'center'}
             bgcolor={'white'}
             color={'primary.main'}
             borderRadius={'5px'}
             marginTop={2}
             marginLeft={isMobile ? -3 : 0}
             marginRight={isMobile ? -3 : 0}
             p={4}
        >
            <StopoverCloseButton
                title={t`Skip Rail & Fly`}
                style={{position: 'absolute', top: '-19px', right: '30px'}}
                onClick={() => dispatch(setSwBookRailFlyState({open: false}))}
                aria-label={'Close Rail & Fly'}
            />
            <Grid2 container spacing={4}>
                <Grid2 size={{xs: 12}}>
                    <Grid2 container spacing={4}>
                        <Grid2 size={{xs: 12}}>
                            <RailFlyPageTitle title={t`Add an RAIL to your trip`}/>
                        </Grid2>
                        {/*{isTablet ? (*/}
                        {/*    <Grid2 size={{xs: 12}}>*/}
                        {/*        <RailFlyGridImages/>*/}
                        {/*    </Grid2>*/}
                        {/*) : (*/}
                            <Grid2 size={{xs: 12}}>
                                <RailFlyAzoresImage
                                    title={`Azores`}
                                    origin={origin?.name}
                                    destination={destination?.name}
                                />
                            </Grid2>
                        {/*)}*/}
                        <Grid2 size={{xs: 12}}>
                            <Typography color={'primary'}>
                                {t`Your Rail&Fly ticket allows you to depart from any train station within the 
Deutsche Bahn (DB) network in Germany to Frankfurt airport and return. There 
is no limitation for specific trains in the DB timetable. The Rail&Fly 
ticket is valid on the flight date as well as one day before departure or 
one day after arrival in Germany. More information about Rail&fly`} &nbsp;
                                <Link style={{cursor: 'pointer'}} underline={"always"} target={'_blank'}
                                      href={'https://www.azoresairlines.pt/node/1592'}>
                                    {t`here.`}
                                </Link>
                                &nbsp;
                                {/*`as estações e destinos da rede Deutsche Bahn.`*/}
                            </Typography>
                        </Grid2>
                        {!disabledRailLegSelector && (
                            <Grid2 size={{xs: 12}}>
                                <Box mx={3} display={'flex'} flexDirection={'column'}>
                                    <RadioGroup row defaultValue={SW_RAIL_FLY_LEG_TYPES.ROUND_TRIP}
                                                aria-label="position" name="position"
                                                onChange={(e) => dispatch(setSwBookRailFlyState({legType: e.target.value}))}
                                                style={{justifyContent: 'space-between'}}>
                                        <FormControlLabel
                                            value={SW_RAIL_FLY_LEG_TYPES.ONE_WAY}
                                            control={<Radio color={"primary"}/>}
                                            label={t`On going`}
                                        />
                                        <FormControlLabel
                                            value={SW_RAIL_FLY_LEG_TYPES.ROUND_TRIP}
                                            control={<Radio color={"primary"}/>}
                                            label={t`Round trip`}
                                        />
                                        <FormControlLabel
                                            value={SW_RAIL_FLY_LEG_TYPES.RETURN}
                                            control={<Radio color={"primary"}/>}
                                            label={t`On return`}
                                        />
                                    </RadioGroup>
                                </Box>
                            </Grid2>
                        )}


                        <Box display={'flex'} flexDirection={isMobile ? 'column' : 'row'} justifyContent={'center'}
                             alignItems={'center'} width={'100%'} p={2}>
                            <Box width={!isMobile ? 'calc(50% - 8px)' : '100%'}>
                                <BookRailFlySubmitButtonConnected/>
                            </Box>
                        </Box>
                    </Grid2>
                </Grid2>
                {/*{!isTablet && (*/}
                {/*    <Grid2 size={{xs: 12, lg: 6}}>*/}
                {/*        <RailFlyGridImages/>*/}
                {/*    </Grid2>*/}
                {/*)}*/}
            </Grid2>
        </Box>
    );
};

export default BookRailFlyConnected;