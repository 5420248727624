import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: '50px',
        width: '100%',
    },
    displayItems: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    sataImgContainer: {
        width: '100%',
        maxWidth: '290px',
        maxHeight: '75px',
        marginBottom: '30px',
    },
    experiencesImgContainer: {
        width: '100%',
        maxWidth: isMobile => isMobile ? '360px' : '540px',
        maxHeight: '155px',
        marginBottom: '30px',
    },
    experiencesImg: {
        maxWidth: isMobile => isMobile ? '360px' : '540px',
    },
    linearProgress: {
        width: '100%',
        padding: isMobile => isMobile ? '0' : '2px 0',
        marginTop: '1px',
    },
    containerTitle: {
        width: isMobile => isMobile ? '90%' : '100%',
        textAlign: isMobile => isMobile ? 'center' : 'left',
        marginBottom: '50px',
    },
    title: {
        fontFamily: 'OpenSansMedium',
    },
    subtitle: {
        color: '#757575',
    },
    azoresGetawaysContainer: {
        maxWidth: '155px',
        maxHeight: '55px',
    }
}));

export default useStyles;