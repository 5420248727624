import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Box, Grid2, useMediaQuery, useTheme} from "@mui/material";
import StopoverLeg from "../../../../Forms/Stopover/StopoverLeg/StopoverLeg";
import {t} from 'ttag';
import {
    BookStopoverSubmitButtonConnected,
    BookStopoverAlertConnected,
    PassengersCountSelectorConnected,
    StopoverAzoresImage,
    StopoverCloseButton,
    StopoverGridImages,
    StopoverPageTitle
} from "../../../../index";
import {SW_FLIGHT_TYPES} from "../../../../../store/types";
import {
    setSwBookStopoverInboundState,
    setSwBookStopoverOutboundState,
    setSwBookStopoverState,
    setSwState
} from "../../../../../store/reducers/search-widget";
import {COMPONENTS, STOPOVER_REFS} from "../../common/book-form-input-refs";

const BookStopoverConnected = () => {

    const {breakpoints} = useTheme();
    const isMobile = useMediaQuery(breakpoints.down('sm'));
    const isTablet = useMediaQuery(breakpoints.down('md'));

    const focusedComponent = useSelector(state => state.sw.focusedComponent);
    const flightType = useSelector(state => state.sw.book.flightType);
    const stopover = useSelector(state => state.sw.book.stopover);
    const origin = useSelector(state => state.sw.book.origin);
    const destination = useSelector(state => state.sw.book.destination);
    const dispatch = useDispatch();
    const {availability, outbound, inbound, loading} = stopover;

    const isRoundTrip = flightType === SW_FLIGHT_TYPES.RETURN;
    const {outbound: aOutbound = null, inbound: aInbound = null} = availability || {};

    const outboundLegDisabled = Boolean((!aOutbound?.PDL.length && !aOutbound?.TER.length) || loading);
    const inboundLegDisabled = Boolean((!isRoundTrip || (isRoundTrip && !aInbound?.PDL.length && !aInbound?.TER.length)) || loading);

    return (
        <Box display={'flex'}
             position={'relative'}
             alignItems={'center'}
             justifyContent={'center'}
             bgcolor={'white'}
             color={'primary.main'}
             borderRadius={'5px'}
             marginTop={2}
             marginLeft={isMobile ? -3 : 0}
             marginRight={isMobile ? -3 : 0}
             p={4}
        >
            <StopoverCloseButton
                title={t`Skip Stopover`}
                style={{position: 'absolute', top: '-19px', right: '30px'}}
                onClick={() => dispatch(setSwBookStopoverState({open: false}))}
                aria-label={'Close Stopover'}
            />
            <Grid2 container spacing={4}>
                <Grid2  size={{xs: 12}}>
                    <Grid2 container spacing={4}>
                        <Grid2  size={{xs: 12}}>
                            <StopoverPageTitle title={t`Add an AZORES STOPOVER to your trip`}/>
                        </Grid2>
                        {/*{isTablet ? (*/}
                        {/*    <Grid2  size={{xs: 12}}>*/}
                        {/*        <StopoverGridImages/>*/}
                        {/*    </Grid2>*/}
                        {/*) : (*/}
                            <Grid2 xs={12} item>
                                <StopoverAzoresImage
                                    title={`Azores`}
                                    origin={origin?.name}
                                    destination={destination?.name}
                                />
                            </Grid2>
                        {/*)}*/}
                        <Grid2  size={{xs: 12}}>
                            <StopoverLeg
                                IslandSelectProps={{
                                    label: t`ON OUTGOING`,
                                    placeholder: t`Select the island`,
                                    value: outbound?.selectedAirport || '',
                                    disabledIslands: aOutbound ? Object.entries(aOutbound).reduce((a, [k, v]) => !v?.length ? [...a, k] : a, []) : [],
                                    onFocus: () => dispatch(setSwState({focusedComponent: COMPONENTS.STOPOVER_OUTBOUND_LEG_ISLAND_SELECTOR_REF})),
                                    onBlur: () => focusedComponent === COMPONENTS.STOPOVER_OUTBOUND_LEG_ISLAND_SELECTOR_REF && dispatch(setSwState({focusedComponent: COMPONENTS.NONE})),
                                    onSelect: selectedAirport => {
                                        dispatch(setSwBookStopoverOutboundState({selectedAirport}));
                                        dispatch(setSwState({focusedComponent: COMPONENTS.STOPOVER_OUTBOUND_LEG_NUMBER_OF_NIGHTS_SELECTOR_REF}));
                                    },
                                    disabled: outboundLegDisabled || !outbound?.enabled,
                                    open: focusedComponent === COMPONENTS.STOPOVER_OUTBOUND_LEG_ISLAND_SELECTOR_REF,
                                    containerRef: STOPOVER_REFS.STOPOVER_OUTBOUND_LEG_ISLAND_SELECTOR_REF,
                                    loading: loading,
                                }}
                                SwitchProps={{
                                    checked: Boolean(outbound?.enabled),
                                    onChange: (e, enabled) => dispatch(setSwBookStopoverOutboundState({enabled})),
                                    disabledAppearance: !outbound?.enabled,
                                    disabled: outboundLegDisabled,
                                    ariaLabel: 'Select Outgoing Island',
                                }}
                                NightsSelectProps={{
                                    label: t`DURATION`,
                                    placeholder: t`Nights`,
                                    value: outbound?.numberOfNights ? (outbound.numberOfNights === 1 ? t`${outbound.numberOfNights} night` : t`${outbound.numberOfNights} nights`) : '',
                                    availableNights: outbound?.selectedAirport ? aOutbound[outbound.selectedAirport] : [],
                                    onFocus: () => dispatch(setSwState({focusedComponent: COMPONENTS.STOPOVER_OUTBOUND_LEG_NUMBER_OF_NIGHTS_SELECTOR_REF})),
                                    onBlur: () => focusedComponent === COMPONENTS.STOPOVER_OUTBOUND_LEG_NUMBER_OF_NIGHTS_SELECTOR_REF && dispatch(setSwState({focusedComponent: COMPONENTS.NONE})),
                                    onSelect: availableNight => {
                                        dispatch(setSwBookStopoverOutboundState({numberOfNights: availableNight?.numberOfNights, departureDate: availableNight?.date}));
                                        dispatch(setSwState({focusedComponent: (!inboundLegDisabled && inbound.enabled) ? COMPONENTS.STOPOVER_INBOUND_LEG_ISLAND_SELECTOR_REF : COMPONENTS.NONE}));
                                    },
                                    disabled: outboundLegDisabled || !outbound?.enabled,
                                    open: focusedComponent === COMPONENTS.STOPOVER_OUTBOUND_LEG_NUMBER_OF_NIGHTS_SELECTOR_REF,
                                    containerRef: STOPOVER_REFS.STOPOVER_OUTBOUND_LEG_NUMBER_OF_NIGHTS_SELECTOR_REF,
                                    loading: loading,
                                }}
                            />
                        </Grid2>
                        <Grid2  size={{xs: 12}}>
                            <StopoverLeg
                                IslandSelectProps={{
                                    label: t`ON RETURN`,
                                    placeholder: t`Select the island`,
                                    value: inbound.selectedAirport || '',
                                    disabledIslands: aInbound ? Object.entries(aInbound).reduce((a, [k, v]) => !v?.length ? [...a, k] : a, []) : [],
                                    onFocus: () => dispatch(setSwState({focusedComponent: COMPONENTS.STOPOVER_INBOUND_LEG_ISLAND_SELECTOR_REF})),
                                    onBlur: () => focusedComponent === COMPONENTS.STOPOVER_INBOUND_LEG_ISLAND_SELECTOR_REF && dispatch(setSwState({focusedComponent: COMPONENTS.NONE})),
                                    onSelect: selectedAirport => {
                                        dispatch(setSwBookStopoverInboundState({selectedAirport}));
                                        dispatch(setSwState({focusedComponent: COMPONENTS.STOPOVER_INBOUND_LEG_NUMBER_OF_NIGHTS_SELECTOR_REF}));
                                    },
                                    disabled: inboundLegDisabled || !inbound.enabled,
                                    open: focusedComponent === COMPONENTS.STOPOVER_INBOUND_LEG_ISLAND_SELECTOR_REF,
                                    containerRef: STOPOVER_REFS.STOPOVER_INBOUND_LEG_ISLAND_SELECTOR_REF,
                                    loading: loading,
                                }}
                                SwitchProps={{
                                    checked: Boolean(inbound.enabled),
                                    onChange: (e, enabled) => dispatch(setSwBookStopoverInboundState({enabled})),
                                    disabledAppearance: !inbound.enabled,
                                    disabled: inboundLegDisabled,
                                    ariaLabel: 'Select Departure Island',
                                }}
                                NightsSelectProps={{
                                    label: t`DURATION`,
                                    placeholder: t`Nights`,
                                    value: inbound?.numberOfNights ? (inbound.numberOfNights === 1 ? t`${inbound.numberOfNights} night` : t`${inbound.numberOfNights} nights`) : '',
                                    availableNights: inbound.selectedAirport ? aInbound[inbound.selectedAirport] : [],
                                    onFocus: () => dispatch(setSwState({focusedComponent: COMPONENTS.STOPOVER_INBOUND_LEG_NUMBER_OF_NIGHTS_SELECTOR_REF})),
                                    onBlur: () => focusedComponent === COMPONENTS.STOPOVER_INBOUND_LEG_NUMBER_OF_NIGHTS_SELECTOR_REF && dispatch(setSwState({focusedComponent: COMPONENTS.NONE})),
                                    onSelect: availableNight => {
                                        dispatch(setSwBookStopoverInboundState({numberOfNights: availableNight?.numberOfNights, departureDate: availableNight?.date}));
                                        dispatch(setSwState({focusedComponent: COMPONENTS.NONE}));
                                    },
                                    disabled: inboundLegDisabled || !inbound.enabled,
                                    open: focusedComponent === COMPONENTS.STOPOVER_INBOUND_LEG_NUMBER_OF_NIGHTS_SELECTOR_REF,
                                    containerRef: STOPOVER_REFS.STOPOVER_INBOUND_LEG_NUMBER_OF_NIGHTS_SELECTOR_REF,
                                    loading: loading,
                                }}
                            />
                        </Grid2>
                        <Grid2  size={{xs: 12}}>
                            <PassengersCountSelectorConnected/>
                        </Grid2>
                        <Box display={'flex'} flexDirection={isMobile ? 'column' : 'row'} justifyContent={'space-between'} alignItems={'center'} width={'100%'} >
                            <Box width={!isMobile ? 'calc(50% - 8px)' : '100%'}>
                                <BookStopoverSubmitButtonConnected/>
                            </Box>
                        </Box>
                    </Grid2>
                </Grid2>
                {/*{!isTablet && (*/}
                {/*    <Grid2  size={{xs: 12, lg:6}} >*/}
                {/*        <StopoverGridImages/>*/}
                {/*    </Grid2>*/}
                {/*)}*/}
                <BookStopoverAlertConnected/>
            </Grid2>
        </Box>
    );
};

export default BookStopoverConnected;